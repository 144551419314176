import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import Checkbox from '@material-ui/core/Checkbox';

import Button from '../../../../shared/components/Button/Button';
import TBTooltip from '../../../../shared/components/TBTooltip/TBTooltip';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import { setIsExerFiltersPerformanceShowing, setExerFiltersPerformance } from '../../ducks/sportCoachDashboardActions';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const FilterAndButtonContainer = styled('div')`
  position: relative;
  margin-top: 8px;
`;

const Badge = styled('span')`
  position: absolute;
  top: -7px;
  right: -7px;
  border-radius: 100px;
  background-color: #444444;
  color: #FFFFFF;
  width: 16px;
  display: flex;
  justify-content: center;
  font-family: 'Nunito Sans';
  font-size: 11px;
  font-weight: bold;
`;

const FilterContainer = styled('div')`
  display: flex;
  position: absolute;
  top: 38px;
  background-color: #FFFFFF;
  z-index: 1;
  flex-direction: column;
  width: 250px;
  max-height: 420px;
  border-radius: 4px;
  padding: 16px;
  box-shadow: ${BoxShadow};
`;

const FilterHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  font-family: 'Nunito Sans';
  font-size: 20px;
  font-weight: 700;
`;

const MaxText = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-weight: 100;
`;

const Filters = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: bold;
  overflow-y: auto;
`;

const FilterOption = styled('div')`
  display: flex;
  align-items: center;
  margin-left: -10px;

  h3 {
    font-weight: normal;
  }
`;

const OptionText = styled('div')`
  font-family: 'Nunito Sans';
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${(props) => (props.isDisabled ? 'rgba(0, 0, 0, 0.26)' : '#444444')};
`;

const FilterBottom = styled('div')`
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
`;

const ExerciseFilter = () => {
  const exerciseFiltersPerformance = useSelector((
    state,
  ) => state.sportCoachDashboard.data.exerciseFiltersPerformance);
  const exercises = useSelector((state) => state.sportCoachDashboard.data.exercises);
  const isExerciseFiltersPerformanceShowing = useSelector((
    state,
  ) => state.sportCoachDashboard.ui.isExerciseFiltersPerformanceShowing);

  const hasFilters = exerciseFiltersPerformance.length > 0;

  const dispatch = useDispatch();

  const handleFilter = () => {
    // KPI tracking
    dashboardTracker('Sport Coach Dashboard', 'Performance Overview - Filter', 'Filter clicked');
    dispatch(setIsExerFiltersPerformanceShowing(!isExerciseFiltersPerformanceShowing));
  };

  const handleClose = () => {
    // KPI tracking
    dashboardTracker('Sport Coach Dashboard', 'Performance Overview - Filter', 'Filter closed');
    dispatch(setIsExerFiltersPerformanceShowing(false));
  };

  // Can only show up to 3 exercises with the same unit measurements
  const handleChange = (exer) => {
    // KPI tracking
    dashboardTracker('Sport Coach Dashboard', 'Performance Overview - Filter', `Exercise filter clicked - ${exer.name}`);
    // If exercise is already in filters, remove it
    if (exerciseFiltersPerformance.includes(exer)) {
      const newExercises = [...exerciseFiltersPerformance];
      const index = newExercises.indexOf(exer);
      if (index > -1) {
        newExercises.splice(index, 1);
      }
      dispatch(setExerFiltersPerformance(newExercises));
    // Else add it to the filters array
    } else {
      const newExercises = [...exerciseFiltersPerformance];
      newExercises.push(exer);
      // If there are already 3 filters, remove the first one
      // Can only filter a max of 3 exercises
      if (newExercises.length > 3) {
        newExercises.splice(0, 1);
      }
      dispatch(setExerFiltersPerformance(newExercises));
    }
  };

  const handleClear = () => {
    // KPI tracking
    dashboardTracker('Sport Coach Dashboard', 'Performance Overview - Filter', 'Filters cleared');
    dispatch(setExerFiltersPerformance([]));
  };

  return (
    <FilterAndButtonContainer>
      <Button
        icon='filter'
        cta='Filter'
        rounded
        onClick={handleFilter}
      />
      {exerciseFiltersPerformance.length > 0 && (
        <Badge>{exerciseFiltersPerformance.length}</Badge>
      )}
      {isExerciseFiltersPerformanceShowing && (
      <FilterContainer>
        <FilterHeader>
          Exercises
          <Button
            icon='remove'
            iconOnly
            onClick={handleClose}
          />
        </FilterHeader>
        <MaxText>(Max. 3 Selections)</MaxText>
        <Filters>
          {exercises.map(
            (option) => (
              <FilterOption key={option.id}>
                <Checkbox
                  color='default'
                  checked={exerciseFiltersPerformance.includes(option)}
                  // Should only be able to select exercises that can be graphed together
                  disabled={
                    hasFilters && exerciseFiltersPerformance[0].unitAbbr !== option.unitAbbr
                  }
                  onChange={() => handleChange(option)}
                />
                {option.name.length > 20 ? (
                  <TBTooltip
                    textToDisplay={option.name}
                    textToHover={option.name}
                    fontSize='16px'
                    textContainerPadding='0px'
                    textContainerMaxWidth='170px'
                    fontColor={hasFilters && exerciseFiltersPerformance[0].unitAbbr !== option.unitAbbr ? 'rgba(0, 0, 0, 0.26)' : '#444444'}
                  />
                ) : (
                  <OptionText
                    isDisabled={
                      hasFilters && exerciseFiltersPerformance[0].unitAbbr !== option.unitAbbr
                    }
                  >
                    {option.name}
                  </OptionText>
                )}
              </FilterOption>
            ),
          )}
        </Filters>
        <FilterBottom>
          <Button
            cta='Clear'
            rounded
            onClick={handleClear}
          />
          <Button
            cta='Done'
            customColor='#444444'
            customTextColor='#FFFFFF'
            primary
            disabled={exerciseFiltersPerformance.length === 0}
            rounded
            onClick={handleClose}
          />
        </FilterBottom>
      </FilterContainer>
      )}
    </FilterAndButtonContainer>
  );
};

export default ExerciseFilter;
