import React from 'react';

import { useSelector } from 'react-redux';
import MaxReport from './MaxReport/MaxReport';
import ActivityReport from './ActivityReport/ActivityReport';
import RawDataReport from './RawDataReport/RawDataReport';
import QuestionnaireReport from './QuestionnaireReport/QuestionnaireReport';
import EvaluationReport from './EvaluationReport/EvaluationReport';
import Report from './BaseResultsReport/Report';

const ActiveReport = () => {
  const activeReport = useSelector((state) => state.reporting.ui.activeReport);

  return (
    <>
      {activeReport === 'max' && (
        <MaxReport />
      )}
      {activeReport === 'activity' && (
        <ActivityReport />
      )}
      {activeReport === 'raw_data' && (
        <RawDataReport />
      )}
      {activeReport === 'questionnaire' && (
        <QuestionnaireReport />
      )}
      {activeReport === 'evaluation' && (
        <EvaluationReport />
      )}
      {activeReport === 'workout_results' && (
        <Report resultsReportType='workoutResultsReport' />
      )}
      {activeReport === 'opt_out_notes' && (
        <Report resultsReportType='optOutNotesReport' />
      )}
    </>
  );
};

export default ActiveReport;
