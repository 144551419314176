/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import moment from 'moment';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';
import Helmet from 'react-helmet';
import Spinner from '../../shared/components/Spinner/Spinner';
import Text from '../../shared/components/Text/Text';
import HeaderText from '../../shared/components/HeaderText/HeaderText';
import DonutChart from './components/presentational/DonutChart';
import ConnectedAthletesCard from './components/presentational/ConnectedAthletesCard';
import AthletesToWatchComponent from './components/presentational/AthletesToWatchComponent';
import GroupPickerCard from './components/presentational/GroupPickerCard';
import AthleteDataTable from './components/presentational/AthleteDataTable';
import DownloadChartDataButton from './components/presentational/DownloadChartDataButton';
import WarningComponent from './components/presentational/WarningComponent';
import ModalHandler from './components/presentational/ModalHandler';
import {
  fetchReportableGroups,
  setActiveWearablesDashDate,
  fetchAthletesToWatchByGroupIdAndDate,
  fetchAthleteParticipationByGroupId,
  setActiveGroup,
  fetchAthleteAverages,
  setActiveAthlete,
  setActiveMetricType,
  clearActiveAthlete,
  clearAthleteAverages,
  fetchWearablesReportForDataChartByGroupOrAthleteId,
  clearWearablesDataChartReport,
  clearReportableGroups,
  setFilterInfoFromPieChart,
} from './ducks/wearablesDashboardActions';
import SharedPickerCard from './components/presentational/SharedPickerCard';
import TBDatePicker from './components/presentational/DatePicker';
import AthleteAveragesLineChart from './components/presentational/AthleteAveragesLineChart';
import { AthleteAveragesCard, AthleteAveragesCardHortizontal } from './components/presentational/AthleteAveragesCard';
import { dashboardTracker } from '../../shared/utils/amplitudeHelper';

const WearablesDashboard = () => {
  const theme = useTheme();

  const PageWrapper = styled('div')`
    display: flex;
    flex: 1;
    width: 100%;
    max-width: 1450px;
  `;

  const SpinnerWrapper = styled('div')`
    // padding-left: 350px;
    height: 100%;
    display: flex;
    flex: 1;
    width: 100%;
    max-width: 1450px;

  `;

  const PageContainer = styled('div')`
    display: flex;
    width: 100%;
    padding-top: 30px;
    // padding-bottom: 50px;
    padding-bottom: 100px;
    // background-color: ${theme.colors.background[0]};
    background-color: #F5F3F3;
    padding-left: ${(props) => props.containerWidth >= 767 && '20px'};
    padding-right: ${(props) => props.containerWidth >= 767 && '20px'};
    flex-direction: ${(props) => props.containerWidth <= 767 && 'column'};

  `;

  const WrapperColumn1 = styled('div')`
    display: flex;
    min-width: 253px;
    align-items: center;
    flex-direction: column;
  `;

  const WrapperColumn2 = styled('div')`
    display: flex;
    flex: 1;
    max-width: ${(props) => (props.containerWidth <= 767 ? '100%' : '70%')};
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    padding-bottom: 20px;
    margin-top: ${(props) => (props.containerWidth <= 767 ? '20px' : '55px')};
  `;

  const TabletWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    backgroundColor: '#F5F3F3';
    // background-color: ${theme.colors.background[0]};
  `;

  const Row = styled('div')`
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    width: 100%;
  `;

  const CenteredRow = styled('div')`
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
  `;

  const PickerCardRow = styled('div')`
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    width: 100%;

    // flex-wrap: ${(props) => (props.containerWidth <= 911 && 'wrap')};
    flex-wrap: wrap;
    justify-content: ${(props) => (props.containerWidth <= 767 && 'center')};
  `;

  const AthleteAveragesRow = styled('div')`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 15px;
    margin-top: 27px;
    justify-content: center;
    align-items: center;

    flex-wrap: ${(props) => (props.containerWidth <= 911 && 'wrap')};
    flex-direction: ${(props) => (props.containerWidth <= 911 && 'column')};
    align-items: ${(props) => (props.containerWidth <= 911 && 'start')};

    display: ${(props) => (props.containerWidth <= 481 && 'flex')};
    flex-direction: ${(props) => (props.containerWidth <= 481 && 'row')};
    width: ${(props) => (props.containerWidth <= 481 && '100%')};
    gap: ${(props) => (props.containerWidth <= 481 && '15px')};
    margin-top: ${(props) => (props.containerWidth <= 481 && '27px')};
    justify-content: ${(props) => (props.containerWidth <= 481 && 'center')};
    align-items: ${(props) => (props.containerWidth <= 481 && 'center')};


  `;

  const SeperatorLine = styled('div')`
    border-top-width: 1px;
    border-top-color: #E0E0E0;
    border-top-style: solid;
    width: 90%;
    height: 30px;
    display: flex;
    align-self: center;
  `;

  // eslint-disable-next-line no-underscore-dangle
  const didPersist = useSelector((state) => state.wearablesDashboard._persist?.rehydrated);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;
  const activeWearablesDashDate = useSelector((state) => state.wearablesDashboard.data.activeWearablesDashDate);
  const reportableGroups = useSelector((state) => state.wearablesDashboard.data.reportableGroups);
  const activeUserIds = useSelector((state) => state.wearablesDashboard.data.activeUserIds);
  const activeGroup = useSelector((state) => state.wearablesDashboard.data.activeGroup);
  const activeGroupId = useSelector((state) => state.wearablesDashboard.data.activeGroup?.id);
  const activeAthlete = useSelector((state) => state.wearablesDashboard.data.activeAthlete);
  const activeMetricType = useSelector((state) => state.wearablesDashboard.data.activeMetricType);
  const activeAthleteId = useSelector((state) => state.wearablesDashboard.data.activeAthlete?.id);
  const currentDate = moment().format('YYYY-MM-DD');
  const arrayOfAthletesForAthletePicker = useSelector((state) => state.wearablesDashboard.data.athleteParticipation?.active?.users);
  const athletesToWatch = useSelector((state) => state.wearablesDashboard.data.athletesToWatch?.peopleToKeepAnEyeOn);

  const dispatch = useDispatch();

  const [containerWidth, setContainerWidth] = useState({});
  const [localCurrentUser, setLocalCurrentUser] = useState(null);

  useEffect(() => {
    setLocalCurrentUser(currentUser);
  }, [currentUser]);

  useEffect(() => {
    if (accountCode && localCurrentUser) {
      dispatch(fetchReportableGroups(accountCode));
      dispatch(setActiveWearablesDashDate(currentDate));
    }
  }, [currentUser]);

  /** useEffect to make initial call for connected athletes by default group (via participation endpoint) on page load
   initial call for athletes to watch with default groupId and current date to populate donut chart and athletes to watch list on page load */
  useEffect(() => {
    if (!didPersist && accountCode && reportableGroups?.length && localCurrentUser) {
      const groupId = reportableGroups[0].id;
      dispatch(setActiveGroup(reportableGroups[0]));
      // dispatch(setActiveWearablesDashDate(currentDate));
      dispatch(fetchAthleteParticipationByGroupId(accountCode, groupId));
      dispatch(fetchAthletesToWatchByGroupIdAndDate(accountCode, groupId, currentDate));
    }
    if (reportableGroups?.length) {
      return () => { dispatch(clearReportableGroups()); };
    }
  }, [reportableGroups]);

  /** on page load or when the selected group changes we need make a call for athlete or group averages, to make this call we need to wait
   * for athlete participation call to finish and then we can make the initial call to fetch athlete averages for the entire group
   * The arrayOfAthletesForAthletePicker represents the connected athletes who are allowing data sharing with coach and this gets refilled
   * everytime we select a new group in the group picker we make a call to fetch athlete participation for that group which triggers an update on
   * arrayOfAthletesForAthletePicker
   * For the same reasons we also need to make a call to get the raw data report for the first group
   * on page load and when the group selection changes. This will happen on new group selection and
   * initial page load, the raw data chart report will also reflect individual althlete data
   * for a selected athlete and date, but we will handle those updates in the date picker component
   * and in the athlete selector, and when the user clicks a tile in the athlete's to watch section
   */
  useEffect(() => {
    const defaultMetricType = {
      id: 1,
      name: 'Exertion Score',
      rawValue: 1,
    };

    if (accountCode && arrayOfAthletesForAthletePicker?.length && localCurrentUser) {
      const userId = activeGroup.id;
      const date = activeWearablesDashDate || currentDate;
      /** 1 is the filterType for group on the averages endpoint */
      const filterType = 1;
      dispatch(setActiveAthlete(activeGroup));
      dispatch(setActiveMetricType(defaultMetricType));
      const metricTypeRawValue = defaultMetricType.rawValue;
      dispatch(fetchAthleteAverages(accountCode, userId, date, filterType, metricTypeRawValue));
      // const filterIds = [userId];
      const filterIds = [activeUserIds];
      /** 2 is the filterType for Groups for the wearable raw report endpoint */
      // const wearablesReportFilterType = 2;
      const wearablesReportFilterType = 1;
      const csv = false;
      dispatch(fetchWearablesReportForDataChartByGroupOrAthleteId(accountCode, filterIds, date, wearablesReportFilterType, csv));
    }
    if (accountCode && !arrayOfAthletesForAthletePicker?.length) {
      dispatch(clearActiveAthlete());
      dispatch(clearAthleteAverages());
      dispatch(clearWearablesDataChartReport());
    }
  }, [arrayOfAthletesForAthletePicker]);

  const setActiveDate = (date) => {
    // KPI tracking
    dashboardTracker('Wearables Dashboard', 'Date Picker', 'Active date changed');
    dispatch(setFilterInfoFromPieChart());
    const newDate = moment(date).format('YYYY-MM-DD');
    if (newDate) {
      dispatch(setActiveWearablesDashDate(newDate));
      /** dispatch function call to athletes to watch and donught chart */
      if (activeGroupId) {
        dispatch(fetchAthletesToWatchByGroupIdAndDate(accountCode, activeGroupId, newDate));
      }
      if (activeAthleteId) {
        /** if there is a name property in the active athlete object we know the "active athlete" is
         * the group, if there is a first and last property then we know the active athlete is an athlete
         */
        const filterType = activeAthlete?.name ? 1 : 3;
        const metricTypeRawValue = activeMetricType.rawValue;
        dispatch(fetchAthleteAverages(accountCode, activeAthleteId, newDate, filterType, metricTypeRawValue));
        // const filterTypeForWearablesRawReport = activeAthlete?.name ? 2 : 1;
        const filterTypeForWearablesRawReport = 1;
        const filterIds = activeAthlete?.name ? [activeUserIds] : [activeAthleteId];
        const csv = false;
        dispatch(fetchWearablesReportForDataChartByGroupOrAthleteId(accountCode, filterIds, newDate, filterTypeForWearablesRawReport, csv));
      }
    }
  };

  return (
    <>
      <div style={{
        width: '100%',
      }}
      >
        <Helmet>
          <title>Wearables Dashboard | TeamBuildr</title>
        </Helmet>
        {!localCurrentUser ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        )
          : (
            <PageWrapper>
              {localCurrentUser && reportableGroups ? (
                <>
                  <ResizeObserver
                    onResize={(rect) => {
                      setContainerWidth(rect?.width);
                    }}
                  />
                  {containerWidth <= 481 ? (
                    <PageContainer containerWidth={containerWidth}>
                      <WrapperColumn1 containerWidth={containerWidth}>
                        {/* <Text fontSize={20} fontWeight={400}>Wearables Dashboard</Text> */}
                        <HeaderText
                          fontWeight='300'
                          letterSpacing='normal'
                        >
                          Wearables Dashboard
                        </HeaderText>
                        <GroupPickerCard />
                        <TBDatePicker setActiveDate={setActiveDate} activeDate={activeWearablesDashDate} />
                        <WarningComponent />
                        <DonutChart />
                      </WrapperColumn1>
                      <WrapperColumn2 containerWidth={containerWidth}>
                        {athletesToWatch?.length ? (
                          <>
                            <Row>
                              <AthletesToWatchComponent containerWidth={containerWidth} />
                            </Row>
                            <SeperatorLine />
                          </>
                        ) : null }
                        <PickerCardRow containerWidth={containerWidth}>
                          <SharedPickerCard pickerType='athletePicker' textToDisplay='Currently Viewing:' containerWidth={containerWidth} />
                          <SharedPickerCard pickerType='metricPicker' textToDisplay='Metric Type:' containerWidth={containerWidth} />
                        </PickerCardRow>
                        <AthleteAveragesRow containerWidth={containerWidth}>
                          <AthleteAveragesLineChart containerWidth={containerWidth} />
                          <AthleteAveragesCard containerWidth={containerWidth} />
                        </AthleteAveragesRow>
                        <CenteredRow>
                          <ConnectedAthletesCard />
                        </CenteredRow>
                        <CenteredRow>
                          <DownloadChartDataButton />
                        </CenteredRow>
                        <Row>
                          <AthleteDataTable containerWidth={containerWidth} />
                        </Row>
                      </WrapperColumn2>
                    </PageContainer>
                  ) : null}

                  {(containerWidth > 481 && containerWidth <= 767) ? (
                    <PageContainer containerWidth={containerWidth}>
                      <WrapperColumn1 containerWidth={containerWidth}>
                        {/* <Text fontSize={20} fontWeight={400}>Wearables Dashboard</Text> */}
                        <HeaderText
                          fontWeight='300'
                          letterSpacing='normal'
                        >
                          Wearables Dashboard
                        </HeaderText>
                        <GroupPickerCard />
                        <TBDatePicker setActiveDate={setActiveDate} activeDate={activeWearablesDashDate} />
                        <WarningComponent />
                        <DonutChart />
                      </WrapperColumn1>
                      <WrapperColumn2 containerWidth={containerWidth}>
                        {athletesToWatch?.length ? (
                          <>
                            <Row>
                              <AthletesToWatchComponent containerWidth={containerWidth} />
                            </Row>
                            <SeperatorLine />
                          </>
                        ) : null }
                        <PickerCardRow containerWidth={containerWidth}>
                          <SharedPickerCard pickerType='athletePicker' textToDisplay='Currently Viewing:' containerWidth={containerWidth} />
                          <SharedPickerCard pickerType='metricPicker' textToDisplay='Metric Type:' containerWidth={containerWidth} />
                        </PickerCardRow>
                        <AthleteAveragesRow containerWidth={containerWidth}>
                          <AthleteAveragesLineChart containerWidth={containerWidth} />
                          <AthleteAveragesCardHortizontal containerWidth={containerWidth} />
                        </AthleteAveragesRow>
                        <CenteredRow>
                          <ConnectedAthletesCard />
                        </CenteredRow>
                        <CenteredRow>
                          <DownloadChartDataButton />
                        </CenteredRow>
                        <Row>
                          <AthleteDataTable containerWidth={containerWidth} />
                        </Row>
                      </WrapperColumn2>
                    </PageContainer>
                  ) : null}

                  {(containerWidth > 767 && containerWidth <= 911) ? (
                    <>
                      <TabletWrapper>
                        <PageContainer style={{ paddingBottom: '30px' }} containerWidth={containerWidth}>
                          <WrapperColumn1 containerWidth={containerWidth}>
                            {/* <Text fontSize={20} fontWeight={400}>Wearables Dashboard</Text> */}
                            <HeaderText
                              fontWeight='300'
                              letterSpacing='normal'
                            >
                              Wearables Dashboard
                            </HeaderText>
                            <GroupPickerCard />
                            <TBDatePicker setActiveDate={setActiveDate} activeDate={activeWearablesDashDate} />
                            <WarningComponent />
                            <DonutChart />
                            <ConnectedAthletesCard />
                          </WrapperColumn1>
                          {/* <WrapperColumn2 style={{ width: '67vw' }} containerWidth={containerWidth}> */}
                          <WrapperColumn2 containerWidth={containerWidth}>
                            {athletesToWatch?.length ? (
                              <>
                                <Row>
                                  <AthletesToWatchComponent containerWidth={containerWidth} />
                                </Row>
                                <SeperatorLine />
                              </>
                            ) : null }
                            <PickerCardRow containerWidth={containerWidth}>
                              <SharedPickerCard pickerType='athletePicker' textToDisplay='Currently Viewing:' containerWidth={containerWidth} />
                              <SharedPickerCard pickerType='metricPicker' textToDisplay='Metric Type:' containerWidth={containerWidth} />
                            </PickerCardRow>
                            <AthleteAveragesRow containerWidth={containerWidth}>
                              <AthleteAveragesLineChart containerWidth={containerWidth} />
                              <AthleteAveragesCardHortizontal containerWidth={containerWidth} />
                            </AthleteAveragesRow>
                          </WrapperColumn2>
                        </PageContainer>
                        <AthleteDataTable includeDownLoadChartButton containerWidth={containerWidth} />
                      </TabletWrapper>
                    </>
                  ) : null}

                  {containerWidth >= 911 ? (
                    <PageContainer containerWidth={containerWidth}>
                      <WrapperColumn1 containerWidth={containerWidth}>
                        {/* <Text fontSize={20} fontWeight={400}>Wearables Dashboard</Text> */}
                        <HeaderText
                          fontWeight='300'
                          letterSpacing='normal'
                        >
                          Wearables Dashboard
                        </HeaderText>
                        <GroupPickerCard />
                        <TBDatePicker setActiveDate={setActiveDate} activeDate={activeWearablesDashDate} />
                        <WarningComponent />
                        <DonutChart />
                        <ConnectedAthletesCard />
                        <DownloadChartDataButton />
                      </WrapperColumn1>
                      <WrapperColumn2 containerWidth={containerWidth}>
                        {athletesToWatch?.length ? (
                          <>
                            <Row>
                              <AthletesToWatchComponent containerWidth={containerWidth} />
                            </Row>
                            <SeperatorLine />
                          </>
                        ) : null }
                        <PickerCardRow containerWidth={containerWidth}>
                          <SharedPickerCard pickerType='athletePicker' textToDisplay='Currently Viewing:' containerWidth={containerWidth} />
                          <SharedPickerCard pickerType='metricPicker' textToDisplay='Metric Type:' containerWidth={containerWidth} />
                        </PickerCardRow>
                        <AthleteAveragesRow containerWidth={containerWidth}>
                          <AthleteAveragesLineChart containerWidth={containerWidth} />
                          <AthleteAveragesCard containerWidth={containerWidth} />
                        </AthleteAveragesRow>
                        <Row>
                          <AthleteDataTable containerWidth={containerWidth} />
                        </Row>
                      </WrapperColumn2>
                    </PageContainer>
                  ) : null}

                </>
              ) : null }
            </PageWrapper>
          )}
      </div>
      <ModalHandler containerWidth={containerWidth} />
    </>
  );
};

export default WearablesDashboard;
