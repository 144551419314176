import {
  SET_RECOVERY_RADAR_VIEW,
} from './actionTypes';

const initialState = {
  recoveryRadarView: 'settings',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_RECOVERY_RADAR_VIEW: {
      return {
        ...state,
        recoveryRadarView: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
