import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import * as Sentry from '@sentry/react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import Logger from 'js-logger';
import { PersistGate } from 'redux-persist/integration/react';
import AppWrapper from './shared/components/AppWrapper';
import { store, persistor } from './redux_setup/store';

Logger.useDefaults();
const app = document.getElementById('app');

if (process.env.ENVIRONMENT === 'PRODUCTION') {
  Logger.debug('Teambuildr Webapp v1.2.3');
}

if (process.env.ENVIRONMENT === 'PRODUCTION' || process.env.ENVIRONMENT === 'STAGING') {
  Sentry.init({
    dsn: 'https://a6b4a55b492aae2b161f6e7c5418e48c@o718074.ingest.us.sentry.io/4506977955217408',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: process.env.ENVIRONMENT === 'PRODUCTION' ? 1.0 : 1.0,
    // replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: process.env.ENVIRONMENT === 'PRODUCTION' ? 1.0 : 1.0,
  });
}

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.ENVIRONMENT === 'PRODUCTION' ? '627baf9f17366b15558587ec' : '627baf9f17366b15558587eb',
  });
  if (process.env.ENVIRONMENT !== 'PRODUCTION') {
    // Setup react-axe accesibility testing tool to
    // run only in non production environments.
    import('react-axe').then((axe) => {
      // axe.default(React, ReactDOM, 1000);
      if (app.hasChildNodes()) {
        hydrate(
          <LDProvider>
            <CookiesProvider>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <AppWrapper />
                </PersistGate>
              </Provider>
            </CookiesProvider>
          </LDProvider>,
          app,
        );
      } else {
        render(
          <LDProvider>
            <CookiesProvider>
              <Provider store={store}>
                <AppWrapper />
              </Provider>
            </CookiesProvider>
          </LDProvider>,
          app,
        );
      }
    });
  } else {
    // eslint-disable-next-line no-lonely-if
    if (app.hasChildNodes()) {
      hydrate(
        <LDProvider>
          <CookiesProvider>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <AppWrapper />
              </PersistGate>
            </Provider>
          </CookiesProvider>
        </LDProvider>,
        app,
      );
    } else {
      render(
        <LDProvider>
          <CookiesProvider>
            <Provider store={store}>
              <AppWrapper />
            </Provider>
          </CookiesProvider>
        </LDProvider>,
        app,
      );
    }
  }
})();
