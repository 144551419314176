/* eslint-disable react/prop-types */
import React from 'react';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

const ModalContainer = styled('div')`
  position: absolute;
  bottom: ${(props) => (props.isPerformance ? '90px' : '75px')};
  right: ${(props) => (props.isPerformance ? '20px' : '-8px')};
  background-color: #FC6076;
  width: 425px;
  height: 45px;
  padding: 8px 16px;
  border-radius: 4px;
  border: solid 1px #FC6076;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: normal;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transform: translateX(${(props) => (props.isActive ? '0' : '150%')});
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  opacity: ${(props) => (props.isActive ? '1' : '0')};

  .reviewBtn {
    font-weight: bold;
    margin-right: 15px
  }
`;

const IconAndDescription = styled('div')`
  display: flex;
`;

const Description = styled('div')`
`;

const OptionsContainer = styled('div')`
`;

const OptionBtn = styled('button')`
  border: none;
`;

const FlaggedAthletesModal = ({
  active,
  handleDismiss,
  handleReview,
  isPerformance,
  numOfAthletes,
}) => {
  const placeholder = '';

  return (
    <ModalContainer isActive={active} isPerformance={isPerformance}>
      <IconAndDescription>
        <IcomoonReact
          iconSet={iconSet}
          size={20}
          color='#FFFFFF'
          icon='warning'
          style={{ marginRight: '10px' }}
        />
        <Description>
          {numOfAthletes === 1 ? '1 athlete to review' : `${numOfAthletes} athletes to review`}
        </Description>
      </IconAndDescription>
      <OptionsContainer>
        <OptionBtn className='reviewBtn' onClick={handleReview}>
          Review
        </OptionBtn>
        <OptionBtn onClick={handleDismiss}>
          Dismiss
        </OptionBtn>
      </OptionsContainer>
    </ModalContainer>
  );
};

export default FlaggedAthletesModal;
