import React, {
  Fragment, useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Logger from 'js-logger';
import { toast } from 'react-toastify';
import ResizeObserver from 'react-resize-observer';
import { useSelector, useDispatch } from 'react-redux';
import { animated, useTransition, useSpring } from 'react-spring';
import * as Yup from 'yup';
import IcomoonReact from 'icomoon-react';
import { useTheme } from 'emotion-theming';

import { Form } from 'formik';
import moment from 'moment';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

import Text from '../../../../shared/components/Text/Text';
import Button from '../../../../shared/components/Button/Button';
import FormHandler from '../../../../shared/components/FormHandler/FormHandler';
import { downloadRawDataReport } from '../../ducks/reportingActions';
import Spinner from '../../../../shared/components/Spinner/Spinner';

import RawDataReportModalSlideOne from './RawDataReportModalSlideOne';
import { TransitionGlobal } from '../../../../shared/GlobalStyles';

import { reportInteractionTracker } from '../../../../shared/utils/amplitudeHelper';

const SlideContainer = styled('div')`
  display: block;
  height: auto;
  height: ${(props) => `${props.height}px`};
  position: relative;
  width: 100%;
  min-height: 184px;
  transition: ${TransitionGlobal};
  z-index: 1000;
`;

const SpinnerContainer = styled('div')`
  display: flex;
  height: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  justify-content: center;
  width: 100%;
  z-index: 0;
  left: 0;
  margin-top: 40px;
`;

const FieldError = styled('div')`
  margin: 10px 0px;
  font-size: 14px;
  color: #ff6600;
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

Yup.addMethod(Yup.object, 'validDateRange', function (errorMessage) {
  return this.test('test-range-dates', errorMessage, function (value) {
    const { path, createError } = this;

    return (
      (value.startDate && value.endDate)
        || createError({ path, message: errorMessage })
    );
  });
});

const RawDataReportSchema = Yup.object().shape({
  rangeDates: Yup.object()
    .validDateRange('Both dates are required')
    .required('Required'),
});

const RawDataReportForm = ({
  closeModal,
}) => {
  const [slideDimensions, setSlideDimensions] = useState({
    height: 0,
    width: 0,
  });
  const [filterError, setFilterError] = useState(false);
  const theme = useTheme();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const sidebarFilter = useSelector((state) => state.reporting.data.sidebarFilter);
  const rawDataReportForm = useSelector((state) => state.reporting.data.rawDataReportForm);
  const rawDataReportError = useSelector((state) => state.reporting.data.rawDataReportError);
  const isLoadingRawDataReport = useSelector((state) => state.reporting.ui.isLoadingRawDataReport);

  const { accountCode } = currentUser;

  const dispatch = useDispatch();

  useEffect(() => {
    if (sidebarFilter.filterIds.length > 0) {
      setFilterError(false);
    }
  }, [sidebarFilter]);

  const fetchReport = (values) => {
    if (sidebarFilter.filterIds.length) {
      dispatch(downloadRawDataReport(sidebarFilter, values, accountCode));

      // Amplitude tracker.
      reportInteractionTracker(
        'Report Submitted',
        'Raw Data',
        '',
        sidebarFilter.filterIds.length,
        sidebarFilter.filterType.indicator,
      );
    }
  };

  const formAnimation = useSpring({
    opacity: isLoadingRawDataReport ? 0 : 1,
    zIndex: 1,
  });

  const spinnerAnimation = useSpring({ opacity: isLoadingRawDataReport ? 1 : 0, zIndex: 0 });

  return (
    <>
      <NavigationWrapper>
        <Text
          className='close arrow-div'
          onClick={() => {
            closeModal();
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon='remove'
          />
        </Text>
      </NavigationWrapper>
      <FormHandler
        initialValues={
          Object.keys(rawDataReportForm).length !== 0 ? (
            rawDataReportForm
          ) : (
            {
              dataSetType: 1,
              showExternalIds: false,
              showAdvancedWorkoutData: false,
              includeGeneratedPRs: true,
              maxExerciseType: 1,
              showTags: false,
              rangeDates: {
                startDate: moment(),
                endDate: moment(),
              },
            }
          )
        }
        validationSchema={RawDataReportSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (sidebarFilter.filterIds.length === 0) {
            setFilterError('Please select athletes, groups, or calendars from the filter.');
            toast.error('You must select filters before continuing');

            // Amplitude tracker.
            reportInteractionTracker('Report Error', 'Raw Data', 'Reporting Filter has not been selected.');
          } else {
            setSubmitting(false);
            fetchReport(values);
          }
        }}
      >
        {(formProps) => (
          <Form
            style={{ width: '100%' }}
          >
            <animated.div style={formAnimation}>
              {rawDataReportError && (
              <FieldError className='text-center'>
                {rawDataReportError.message}
              </FieldError>
              )}
              {filterError && (
              <FieldError className='text-center'>
                {filterError}
              </FieldError>
              )}
              {formProps.errors.dataSetType && formProps.touched.dataSetType ? (
                <FieldError className='text-center'>{formProps.errors.dataSetType}</FieldError>
              ) : null}
              <SlideContainer
                height={slideDimensions.height}
              >
                <ResizeObserver
                  onResize={(rect) => {
                    setSlideDimensions(rect);
                  }}
                />
                <RawDataReportModalSlideOne
                  formProps={formProps}
                />
              </SlideContainer>
            </animated.div>
            <animated.div style={spinnerAnimation}>
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            </animated.div>
            <Button
              cta='Download Report'
              className='modal-button'
              type='submit'
              fullWidth
              noBorder
              large
              square
              primary
              bottom
              customColor={theme.colors.green}
            />
          </Form>
        )}
      </FormHandler>
    </>
  );
};

export default RawDataReportForm;
