import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/core';
import IcomoonReact from 'icomoon-react';

import Spinner from '../../../../../shared/components/Spinner/Spinner';
import { SpinnerContainer } from '../../../../weightroom-view-new/components/presentational/WorkoutEntriesTag';
import Text from '../../../../../shared/components/Text/Text';

import { fetchUser } from '../../../../login/ducks/loginActions';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import { TransitionGlobal } from '../../../../../shared/GlobalStyles';

const CancelWrapper = styled('div')`
  background-color: #1C1E28;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: #1C1E28;
  width: 700px;
  height: auto;
  text-align: center;
  align-items: center;

  @media screen and (max-width: 630px) {
    padding-left: 15px;
    padding-right: 15px;
  
    p {
      font-size: 22px;
      line-height: 28px;
    }
  }
`;

const HeaderText = styled('h1')`
  font-family: Nunito Sans;
  font-size: 64px;
  color: white;
  font-weight: 600;
  letter-spacing: -1px;
  margin-bottom: 24px;

  @media screen and (max-width: 630px) {
    font-size: 36px
  }
`;

const CancelButton = styled('button')`
  border: 0px;
  background-color: white;
  padding: 10px 15px;
  border-radius: 6px;
  font-family: Nunito Sans;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  color: ${(props) => (props.textColor ? props.textColor : props.theme.colors.text)};
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : props.theme.colors.background[0])};
  transition: ${TransitionGlobal};
  
  span {
    margin-right: 5px;
  }

  :hover {
    opacity: 0.7;
  }
`;

const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  width: auto;

  button:first-of-type {
    margin-right: 15px;
  }

  @media screen and (max-width: 630px) {
    flex-direction: column;
    align-items: center;

    button:first-of-type {
      margin-bottom: 15px;
    }
  }

`;

const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border: 5px solid white;
  border-radius: 50%;
  margin-bottom: 30px;

  svg {
    path {
      fill: white!important;
    }
  }
`;

const CancelPage = () => {
  const [isFormComplete, setIsFormComplete] = useState(false);

  const buttonRef = useRef(null);

  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.auth.data.currentUser);

  useEffect(() => {
    dispatch(fetchUser());
  }, []);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length) {
      // Define the function and bind it to the window object so the script below has access.
      window.updateFormAfterCompletion = () => {
        setIsFormComplete(true);
      };

      // Define the script content with the function embedded
      const scriptContent = `
        !(function () {
          if (window.barecancel && window.barecancel.created) {
            console.error('Barecancel snippet included twice.');
          } else {
            window.barecancel = { created: true };
            const customer_oid = '${currentUser.organizationStripeCustomerId}';
            const a = document.createElement('script');
            a.src = 'https://baremetrics-barecancel.baremetrics.com/js/application.js';
            a.async = true;
            const b = document.getElementsByTagName('script')[0];
            b.parentNode.insertBefore(a, b);
            window.barecancel.params = {
              access_token_id: '8399ea6b-1b3d-4438-b5c9-82bfd9e8b851',
              customer_oid: customer_oid,
              callback_send() {
                // Window bound function from above.
                window.updateFormAfterCompletion();
              },
              callback_error(error) {
                window.updateFormAfterCompletion();
                console.error(error);
              },
              test_mode: ${process.env.NODE_ENV !== 'PRODUCTION'},
            };
          }
        }());
      `;

      // Create and inject the script so it's available after the component mounts.
      const script = document.createElement('script');
      script.innerHTML = scriptContent;
      document.body.appendChild(script);
    }
  }, [currentUser]);

  const getSubscriptionLevel = (value) => {
    switch (value) {
      case 1:
        return 'TeamBuildr Bronze';
      case 2:
      case 3:
        return 'TeamBuildr Silver';
      case 4:
      case 5:
        return 'TeamBuildr Gold';
      case 6:
        return 'TeamBuildr Platinum';
      case 7:
        return 'TeamBuildr Platinum Pro';
      default:
        return 'TeamBuildr';
    }
  };

  return (
    <CancelWrapper>
      <Global
        styles={css`
          .bm-cancel-modal .bm-modal-action .bm-nevermind {
            display: none!important;
          }
        `}
      />

      {Object.keys(currentUser).length ? (
        <>
          <Helmet>
            <meta charSet='utf-8' />
            <title>Cancellation | TeamBuildr</title>
            <link rel='canonical' href='http://example.com/example' />
          </Helmet>
          <ContentWrapper>
            {!isFormComplete ? (
              <>
                <HeaderText>Thank you for being a part of TeamBuildr.</HeaderText>
                <Text color='white' fontSize='34px' lineHeight='44px'>Please click the button below to start the cancellation process.</Text>
                <ButtonWrapper>
                  <CancelButton type='button' textColor='#757575' backgroundColor='#E0E0E0'>
                    <span>
                      <IcomoonReact
                        iconSet={iconSet}
                        size={25}
                        icon='ribbon'
                        color='#444444'
                      />
                    </span>
                    {`Keep ${getSubscriptionLevel(currentUser.organizationAccessLevel)}`}
                  </CancelButton>
                  <CancelButton id='barecancel-trigger' type='button' ref={buttonRef} textColor='white' backgroundColor='#FF6600'>Continue Cancellation</CancelButton>
                </ButtonWrapper>
              </>
            ) : (
              <>
                <IconContainer>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={150}
                    icon='fist-bump-full-together'
                    color='#444444'
                  />
                </IconContainer>
                <Text color='white' fontSize='34px' lineHeight='44px'>Thank you for your feedback. Your cancellation request has been received and will be processed shortly. You will be notified once the cancellation is complete.</Text>
              </>
            )}
          </ContentWrapper>
        </>
      ) : (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
    </CancelWrapper>
  );
};

export default CancelPage;
