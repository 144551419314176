import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import LayoutAppHeader from './components/container/LayoutAppHeader';
import useLayout from './hooks/useLayout';

const LayoutContainer = styled('div')`
  background-color: #f5f5f5;
  overflow-y: ${(props) => (props.fullWidth ? 'hidden' : 'scroll')};
`;

const MainContent = styled('main')`
  position: relative;
  height: auto;
  height: 100%;
  min-height: 100%;
  padding: ${(props) => (props.fullWidth ? '0px' : '64px 15px 15px 15px')};
  ${(props) => (props.fullWidth ? (
    'overflow: hidden;'
  ) : '')};
  
`;

const Layout = ({
  children,
  fullWidth,
  hideHeader,
}) => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (isAuthenticated === false) {
  //     dispatch(push('/login'));
  //   }
  // }, [isAuthenticated]);

  return (
    <LayoutContainer
      fullWidth={fullWidth}
    >
      {/* <AppHeader
          data={Data}
          location='/feed'
        /> */}
      <LayoutAppHeader hideHeader={hideHeader} />
      <MainContent
        className={fullWidth ? '' : 'container'}
        fullWidth={fullWidth}
      >
        {children}
      </MainContent>
    </LayoutContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  fullWidth: PropTypes.bool,
  hideHeader: PropTypes.bool,
};

Layout.defaultProps = {
  fullWidth: false,
  hideHeader: undefined,
};

export default Layout;
