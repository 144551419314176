/* eslint-disable no-nested-ternary */
import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import Button from '../../../../shared/components/Button/Button';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { setIsLegendInfoModalShowing, setIsMetricInfoModalShowing } from '../../ducks/forcePlateDashboardActions';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const MetricsContainer = styled('div')`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 0px 5px;
  filter: ${(props) => (props.isBlurred ? 'blur(1.2px)' : null)};
  padding-bottom: 10px;
`;

const MetricContainer = styled('div')`
    min-width: 155px;
    height: 150px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px 5px 5px 15px;
    box-shadow: ${BoxShadow};
    flex: 1;
    margin: 10px;
  `;

const TitleAndIcon = styled('div')`
    display: flex;
    justify-content: space-between;
    white-space: nowrap;

    svg {
      border: 1px solid #808080;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      padding: 3px;
    }
  `;

const MetricTitle = styled('div')`
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: 'Nunito Sans';
    font-size: 19px;
    font-weight: 400;
  `;

const MetricData = styled('div')`
    height: 75px;
    display: flex;
    align-items: center;
    font-size: 28px;
    font-family: 'Nunito Sans';
    font-weight: 700;
  `;

const Measurement = styled('div')`
    font-size: 25px;
    font-family: 'Nunito Sans';
    font-weight: 300;
    margin-left: 10px;
    margin-top: 20px;
  `;

const PercentageContainer = styled('div')`
    display: flex;
    align-items: center;
    svg {
      fill: ${(props) => (props.color ? `${props.color}` : '#9E9E9E')};
    }
  `;

const Percentage = styled('div')`
    font-family: 'Nunito Sans';
    font-size: 19px;
    font-weight: 400;
    margin-left: 5px;
  `;

const MetricsContainers = () => {
  const isAthleteSelectedPerformance = useSelector(
    (state) => state.forcePlateDashboard.ui.isAthleteSelectedPerformance,
  );
  const metricCardData = useSelector(
    (state) => state.forcePlateDashboard.data.metricCardData,
  );
  const selectedAthletePerformance = useSelector(
    (state) => state.forcePlateDashboard.data.selectedAthletePerformance,
  );

  const hasData = metricCardData.length > 0;
  const data = hasData && [...metricCardData];

  const orderedData = hasData
    && data.sort((a, b) => new Date(a.date) - new Date(b.date));

  const endDateData = orderedData && orderedData.pop();
  const athleteData = (isAthleteSelectedPerformance && endDateData)
    && endDateData.athleteData.find((obj) => obj.fullName === selectedAthletePerformance.fullName);

  const jumpGroupData = hasData && endDateData.groupData[0].metrics.find((obj) => obj.metricName === 'Jump Height');
  const takeoffGroupData = hasData && endDateData.groupData[0].metrics.find((obj) => obj.metricName === 'Takeoff Velocity');
  const peakPowerGroupData = hasData && endDateData.groupData[0].metrics.find((obj) => obj.metricName === 'Peak Power');
  const concentricGroupData = hasData && endDateData.groupData[0].metrics.find((obj) => obj.metricName === 'L|R Concentric Impulse');
  const mRSIGroupData = hasData && endDateData.groupData[0].metrics.find((obj) => obj.metricName === 'mRSI');

  const jumpAthleteData = (isAthleteSelectedPerformance && athleteData) && athleteData.testResults.find((obj) => obj?.metricName === 'Jump Height');
  const takeoffAthleteData = (isAthleteSelectedPerformance && athleteData) && athleteData.testResults.find((obj) => obj?.metricName === 'Takeoff Velocity');
  const peakPowerAthleteData = (isAthleteSelectedPerformance && athleteData) && athleteData.testResults.find((obj) => obj?.metricName === 'Peak Power');
  const concentricAthleteData = (isAthleteSelectedPerformance && athleteData) && athleteData.testResults.find((obj) => obj?.metricName === 'L|R Concentric Impulse');
  const mRSIAthleteData = (isAthleteSelectedPerformance && athleteData) && athleteData.testResults.find((obj) => obj?.metricName === 'mRSI');

  const jumpData = isAthleteSelectedPerformance
    ? jumpAthleteData : jumpGroupData;
  const takeoffData = isAthleteSelectedPerformance
    ? takeoffAthleteData : takeoffGroupData;
  const peakPowerData = isAthleteSelectedPerformance
    ? peakPowerAthleteData : peakPowerGroupData;
  const concentricData = isAthleteSelectedPerformance
    ? concentricAthleteData : concentricGroupData;
  const mRSIData = isAthleteSelectedPerformance
    ? mRSIAthleteData : mRSIGroupData;

  const dispatch = useDispatch();

  const handleInfoClick = (metric) => {
    // KPI tracking
    dashboardTracker('Force Plate Dashboard', 'Performance Overview - Metric Legend', `Legend info button clicked for ${metric} card`);
    batch(() => {
      dispatch(setIsLegendInfoModalShowing(false));
      dispatch(setIsMetricInfoModalShowing(true, metric));
    });
  };

  return (
    <MetricsContainer isBlurred={!hasData}>
      <MetricContainer>
        <TitleAndIcon>
          <MetricTitle>
            Jump Height
          </MetricTitle>
          <Button
            iconOnly
            icon='info'
            noBorder
            onClick={() => handleInfoClick('Jump Height')}
            rounded
            disabled={!hasData}
          />
        </TitleAndIcon>
        <MetricData>
          {jumpData?.value || jumpData?.averageValue || '00.00'}
          <Measurement>
            cm
          </Measurement>
        </MetricData>
        <PercentageContainer color={jumpData?.alertColor || null}>
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon={
                  jumpData?.percentChange > 0 ? 'alt-up-arrow'
                    : jumpData?.percentChange < 0 ? 'alt-down-arrow'
                      : 'alt-right-arrow'
                }
          />
          <Percentage>
            {jumpData?.percentChange || '0.00'}
            %
          </Percentage>
        </PercentageContainer>
      </MetricContainer>
      <MetricContainer>
        <TitleAndIcon>
          <MetricTitle>
            Takeoff Velocity
          </MetricTitle>
          <Button
            iconOnly
            icon='info'
            noBorder
            onClick={() => handleInfoClick('Takeoff Velocity')}
            rounded
            disabled={!hasData}
          />
        </TitleAndIcon>
        <MetricData>
          {takeoffData?.value || takeoffData?.averageValue || '0.00'}
          <Measurement>
            m/s
          </Measurement>
        </MetricData>
        <PercentageContainer color={takeoffData?.alertColor || null}>
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon={
                  takeoffData?.percentChange > 0 ? 'alt-up-arrow'
                    : takeoffData?.percentChange < 0 ? 'alt-down-arrow'
                      : 'alt-right-arrow'
                }
          />
          <Percentage>
            {takeoffData?.percentChange || '0.00'}
            %
          </Percentage>
        </PercentageContainer>
      </MetricContainer>
      <MetricContainer>
        <TitleAndIcon>
          <MetricTitle>
            Peak Power
          </MetricTitle>
          <Button
            iconOnly
            icon='info'
            noBorder
            onClick={() => handleInfoClick('Peak Power')}
            rounded
            disabled={!hasData}
          />
        </TitleAndIcon>
        <MetricData>
          {peakPowerData?.value || peakPowerData?.averageValue || '0000'}
          <Measurement>
            W
          </Measurement>
        </MetricData>
        <PercentageContainer color={peakPowerData?.alertColor || null}>
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon={
                  peakPowerData?.percentChange > 0 ? 'alt-up-arrow'
                    : peakPowerData?.percentChange < 0 ? 'alt-down-arrow'
                      : 'alt-right-arrow'
                }
          />
          <Percentage>
            {peakPowerData?.percentChange || '0.00'}
            %
          </Percentage>
        </PercentageContainer>
      </MetricContainer>
      <MetricContainer>
        <TitleAndIcon>
          <MetricTitle>
            Concentric Impulse (L/R)
          </MetricTitle>
          <Button
            iconOnly
            icon='info'
            noBorder
            onClick={() => handleInfoClick('Concentric Impulse (L/R)')}
            rounded
            disabled={!hasData}
          />
        </TitleAndIcon>
        <MetricData>
          {concentricData?.value || concentricData?.averageValue || '00.00'}
        </MetricData>
        <PercentageContainer color={concentricData?.alertColor || null}>
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon={
                  concentricData?.percentChange > 0 ? 'alt-up-arrow'
                    : concentricData?.percentChange < 0 ? 'alt-down-arrow'
                      : 'alt-right-arrow'
                }
          />
          <Percentage>
            {concentricData?.percentChange || '0.00'}
            %
          </Percentage>
        </PercentageContainer>
      </MetricContainer>
      <MetricContainer>
        <TitleAndIcon>
          <MetricTitle>
            mRSI
          </MetricTitle>
          <Button
            iconOnly
            icon='info'
            noBorder
            onClick={() => handleInfoClick('mRSI')}
            rounded
            disabled={!hasData}
          />
        </TitleAndIcon>
        <MetricData>
          {mRSIData?.value || mRSIData?.averageValue || '0.00'}
          <Measurement>
            m/s
          </Measurement>
        </MetricData>
        <PercentageContainer color={mRSIData?.alertColor || null}>
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon={
                  mRSIData?.percentChange > 0 ? 'alt-up-arrow'
                    : mRSIData?.percentChange < 0 ? 'alt-down-arrow'
                      : 'alt-right-arrow'
                }
          />
          <Percentage>
            {mRSIData?.percentChange || '0.00'}
            %
          </Percentage>
        </PercentageContainer>
      </MetricContainer>
    </MetricsContainer>
  );
};

export default MetricsContainers;
