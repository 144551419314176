/* eslint-disable max-len */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IcomoonReact from 'icomoon-react';
import styled from '@emotion/styled';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import Text from '../../../../shared/components/Text/Text';
import {
  downloadWearablesReport,
} from '../../ducks/wearablesDashboardActions';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const DownloadChartDataButton = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;
  const activeAthlete = useSelector((state) => state.wearablesDashboard.data.activeAthlete);
  const activeAthleteId = useSelector((state) => state.wearablesDashboard.data.activeAthlete?.id);
  const activeWearablesDashDate = useSelector((state) => state.wearablesDashboard.data.activeWearablesDashDate);
  const dataChartRows = useSelector((state) => state.wearablesDashboard.data.wearablesReportDataForDataChart?.rows);
  const isWearablesReportcsvLoading = useSelector((state) => state.wearablesDashboard.ui.isWearablesReportcsvLoading);

  const Wrapper = styled('div')`
    display: ${dataChartRows?.length ? 'flex' : 'none'};
    cursor: pointer;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px 9px 12px;
    height: 28px;
    width: 160px;
    background: #444444;
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1);
    border-radius: 12px;
  `;

  const downloadChartDataReport = () => {
    if (activeAthleteId) {
    /** if there is a name property in the active athlete object we know
     * if a group, if there is a first and last property then we know it's an athlete
     */
      // KPI tracking
      dashboardTracker('Wearables Dashboard', 'Download Chart Data', 'Download chart data clicked');
      const filterTypeForWearablesRawReport = activeAthlete?.name ? 2 : 1;
      const filterIds = [activeAthleteId];
      const csv = true;
      dispatch(downloadWearablesReport(accountCode, filterIds, activeWearablesDashDate, filterTypeForWearablesRawReport, csv));
    }
  };

  return (
    <>
      {isWearablesReportcsvLoading ? (
        <Wrapper>
          <Spinner saving />
        </Wrapper>
      ) : (
        <Wrapper onClick={downloadChartDataReport}>
          <IcomoonReact
            iconSet={iconSet}
            size={12}
            icon='download'
            color='white'
          />
          <Text color='white' fontSize={11} fontWeight={700}>Download Chart Data</Text>
        </Wrapper>

      )}
    </>
  );
};

export default DownloadChartDataButton;
