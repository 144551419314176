// a relatively complex component that displays a different modal depending on which
// document type the user intends to create

import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Form, Field } from 'formik';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useTheme } from 'emotion-theming';
import { typography, color } from 'styled-system';

import Select from 'react-select';
import Text from '../../../../../shared/components/Text/Text';
import useReseller from '../../hooks/useReseller';
import useResellerPrograms from '../../hooks/useResellerPrograms';
import 'react-datepicker/dist/react-datepicker.css';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  .modal-button {
    display: flex;
    align-items: center;
  }
`;

const SubText = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-weight: 900;
  color: #444444;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  ${typography}
  ${color}
`;

const CheckboxWrapper = styled('div')`
  padding-top: 12px;
`;

const FieldError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  margin-top: 5px;
`;

const FieldErrorForOffsetDate = styled('div')`
  font-size: 14px;
  color: #ff6600;
  margin-bottom: 4px;
  margin-left: 2px;
`;

const TitlePlusErrorWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InnerFolderFormWrapper = styled('div')`
  display: flex;
  width: 80%;
  justify-content: left;
  align-items: center;
  margin-top: 5px;

  .formik-form {
    width: 100%;
  }
`;

const OuterFolderFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232,232,232);
    margin-top: 5px;
    margin-bottom: 10px;
  }
`;

const FormGroup = styled('div')`
  width: 100%;
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    margin-bottom: 15px;
  }

  .formgroup-text {
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .caveat-text {
    font-size: 11px;
    margin-top: 5px;
  }

  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
  .form-control {
    min-height: 30px;
    width: 100%;
  }
  .alt-form-control {
    display: block;
    height: calc(1.5em + 0.75rem + 2px);
    font-size: 1rem;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    min-height: 30px;
    width: 100%;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .btow {
    font-size: 12px;
    font-weight: 800;
    width: 100%;
    height: 100%;
    min-width: 210px;
    color: white;
    background: black;
    padding: 9px;
    padding-left: 15px;
    border-radius: 0.25rem;
    border: 1px solid black;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .checkbox-label {
    display: flex;
    margin-top: 10px;
  }

  .checkbox {
    margin-right: 10px;
    margin-top: 3px;
  }

`;

const TextBoxOuterWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const TextBoxContainer = styled('div')`
  border-color: ${(props) => props.borderColor};
  border-radius: 4px;
  border-width: ${(props) => props.borderWidth};
  border-style: solid;
  height: 165px;
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  cursor: pointer;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
`;

const TextBoxTitleContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row;
`;

const TextBoxContentContainer = styled('div')`
  display: flex;
  align-items: center;
  text-align: center;
`;

const TextBoxContent = styled('div')`
  font-size: 16px;
  font-weight: 300;
  margin-left: 6px;
  margin-right: 6px;
`;

const TextBoxTitle = styled('div')`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 12px;
  margin-top: 15px;
`;

const FirstWorkoutContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const UpdateProgramSlide2 = ({
  formProps,
  program,
  programType,
  setProgramType,
  page2Errors,
  selectedDate,
  setSelectedDate,
  clickedOffsetDate,
  setClickedOffsetDate,
  selectedCalendar,
  setSelectedCalendar,
  clickedCalendar,
  setClickedCalendar,
  flattenedCalendars,
  calendarOptions,
}) => {
  const {
    currentUser,
    userGroups,
    calendars,
  } = useReseller();

  const {
    firstWorkout,
    firstWorkoutError,
    isFirstWorkoutFetching,
    handleFetchFirstWorkout,
  } = useResellerPrograms();

  const theme = useTheme();
  const [firstTouchDown, setFirstTouchDown] = useState(true);
  useEffect(() => {
    if (firstWorkout && !firstWorkoutError && !clickedOffsetDate) {
      const date = moment(firstWorkout.date);
      setSelectedDate(date);
      formProps.setFieldValue('calendarOffsetDate', date.format('YYYY-MM-DD'));
    }
  }, [firstWorkout, firstWorkoutError]);

  useEffect(() => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    if (!firstTouchDown) {
      if (
        accountCode !== undefined
        && Object.keys(selectedCalendar).length
        && !isFirstWorkoutFetching
      ) {
        handleFetchFirstWorkout({ accountCode, calendarId: selectedCalendar.id });
      }
    }
  }, [selectedCalendar]);

  useEffect(() => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    if (program.calendarId && calendars && calendars.length && !clickedCalendar) {
      if (!clickedOffsetDate) {
        if (!program.calendarOffsetDate) {
          if (!firstTouchDown) {
            /*
            Only autopopulate the calendar assignment if the user has
            actually clicked and change the calendar dropdown. I.e.
            if there is no calendarOffsetDate and the user first loads the modal,
            we don't want to autopopulate the calendar assignment until they
            actually change the calendar.
            */
            handleFetchFirstWorkout({ accountCode, calendarId: cal.id });
          }
        } else {
          const date = moment(program.calendarOffsetDate);
          setSelectedDate(date);
        }
      }
    }
  }, [flattenedCalendars]);

  const onClickTextBox = (selectedProgramType) => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    formProps.setFieldValue('programType', selectedProgramType);
    formProps.setFieldTouched('programType', true);
    setProgramType(selectedProgramType);
    if (selectedProgramType === 'ONGOING') {
      // Wipe out calendarOffsetDate and the Monday start checkbox
      formProps.setFieldValue('calendarOffsetDate', '');
      formProps.setFieldValue('mondayStart', null);
    } else if (!selectedDate) {
      /* If the user clicks the PRE-BUILT program option,
       and they haven't selected an offset date yet,
       then let's try fetching the first workout from the calendar.
      */
      handleFetchFirstWorkout({ accountCode, calendarId: selectedCalendar.id });
    } else {
      formProps.setFieldValue('calendarOffsetDate', moment(selectedDate).format('YYYY-MM-DD'));
    }
  };

  const isOriginalProgramPreBuilt = () => !formProps.touched.programType && program.calendarOffsetDate && !formProps.touched.calendarId;
  const isOriginalProgramOnGoing = () => !formProps.touched.programType && !program.calendarOffsetDate && !formProps.touched.calendarId;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      paddingLeft: state.data.parentId ? 35 : 15,
    }),
  };

  return (
    <ModalWrapper>
      <AnotherWrapper>
        <OuterFolderFormWrapper>
          <InnerFolderFormWrapper>
            <Form className='formik-form'>
              <FormGroup>
                <TitlePlusErrorWrapper>
                  <Text className='formgroup-text'><strong>Calendar Assignment</strong></Text>
                </TitlePlusErrorWrapper>
                <Select
                  defaultValue={selectedCalendar}
                  className='first-stage-select'
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  styles={customStyles}
                  options={flattenedCalendars}
                  onChange={(options) => {
                    setClickedCalendar(true);
                    if (options) {
                      setFirstTouchDown(false);
                      formProps.setFieldTouched('calendarId', true);
                      formProps.setFieldValue('calendarId', options.id);
                      setSelectedCalendar(options);
                    } else {
                      setFirstTouchDown(false);
                      formProps.setFieldValue('calendarId', 0);
                      formProps.setFieldValue('calendarOffsetDate', '');
                      formProps.setFieldValue('programType', null);
                      formProps.setFieldValue('mondayStart', null);
                      formProps.setFieldTouched('calendarId', true);
                      setProgramType(null);
                      setSelectedDate(null);
                      setSelectedCalendar({});
                    }
                  }}
                  isOptionSelected={
                    (pricingOption) => formProps.values.pricingOption === pricingOption
                  }
                  isClearable={selectedCalendar ? Object.keys(selectedCalendar).length : false}
                  isSearchable
                />
              </FormGroup>
              {selectedCalendar && Object.keys(selectedCalendar).length ? (
                <FormGroup>
                  <TitlePlusErrorWrapper>
                    <Text className='formgroup-text'><strong>Program Type</strong></Text>
                    {formProps.errors.programType && page2Errors ? (
                      <FieldError className='error-text'>{formProps.errors.programType}</FieldError>
                    ) : null}
                  </TitlePlusErrorWrapper>
                  <TextBoxOuterWrapper>
                    <TextBoxContainer
                      onClick={() => onClickTextBox('PRE-BUILT')}
                      borderColor={programType === 'PRE-BUILT' || isOriginalProgramPreBuilt() ? theme.colors.green : 'hsl(0,0%,80%)'}
                      borderWidth={programType === 'PRE-BUILT' || isOriginalProgramPreBuilt() ? '2px' : '1px'}
                      marginRight='10px'
                    >
                      <TextBoxTitleContainer>
                        <TextBoxTitle>
                          Pre-Built Program
                        </TextBoxTitle>
                      </TextBoxTitleContainer>
                      <TextBoxContentContainer>
                        <TextBoxContent>
                          I have built workouts on this calendar already.
                        </TextBoxContent>
                      </TextBoxContentContainer>
                    </TextBoxContainer>
                    <TextBoxContainer
                      onClick={() => onClickTextBox('ONGOING')}
                      borderColor={programType === 'ONGOING' || isOriginalProgramOnGoing() ? theme.colors.green : 'hsl(0,0%,80%)'}
                      borderWidth={programType === 'ONGOING' || isOriginalProgramOnGoing() ? '2px' : '1px'}
                      marginLeft='10px'
                    >
                      <TextBoxTitleContainer>
                        <TextBoxTitle>
                          Ongoing Program
                        </TextBoxTitle>
                      </TextBoxTitleContainer>
                      <TextBoxContentContainer>
                        <TextBoxContent>
                          I will be adding workouts to this calendar on an ongoing basis.
                        </TextBoxContent>
                      </TextBoxContentContainer>

                    </TextBoxContainer>
                  </TextBoxOuterWrapper>
                </FormGroup>
              ) : null }
              {selectedCalendar && Object.keys(selectedCalendar).length && (programType === 'ONGOING' || isOriginalProgramOnGoing()) ? (
                <FormGroup>
                  <Text className='url-string' fontSize={16} style={{ marginTop: '15px' }}>
                    Subscriber will be placed in calendar on day of registration.
                  </Text>
                </FormGroup>
              ) : null }
              <FormGroup>
                {selectedCalendar && Object.keys(selectedCalendar).length && (programType === 'PRE-BUILT' || isOriginalProgramPreBuilt()) ? (
                  <FormGroup>
                    <FirstWorkoutContainer>
                      <TitlePlusErrorWrapper>
                        <SubText fontSize={10} className='toggle-text'>First workout is on: </SubText>
                        {formProps.errors.calendarOffsetDate && page2Errors ? (
                          <FieldErrorForOffsetDate className='error-text'>{formProps.errors.calendarOffsetDate}</FieldErrorForOffsetDate>
                        ) : null}
                      </TitlePlusErrorWrapper>
                      {' '}
                      <DatePicker
                        className='form-control'
                        selected={
                      (selectedDate && selectedCalendar && Object.keys(selectedCalendar).length)
                        ? Date.parse(selectedDate)
                        : null
                    }
                        onChange={(date) => {
                          setClickedOffsetDate(true);
                          if (date) {
                            const newDate = moment(date);
                            const finalDateString = (newDate.format('YYYY-MM-DD'));
                            formProps.setFieldValue('calendarOffsetDate', finalDateString);
                          } else {
                            formProps.setFieldValue('calendarOffsetDate', '');
                          }
                          setSelectedDate(date);
                        }}
                      />
                    </FirstWorkoutContainer>
                  </FormGroup>
                ) : null }

                {selectedCalendar && Object.keys(selectedCalendar).length && (programType === 'PRE-BUILT' || isOriginalProgramPreBuilt()) ? (

                  <FormGroup className='d-flex'>
                    <CheckboxWrapper>
                      <label
                        className='checkbox-label'
                      >
                        <Field
                          className='checkbox'
                          id='monday-start-field'
                          name='monday-start'
                          type='checkbox'
                          defaultValue
                          onChange={() => {
                            formProps.setFieldTouched('mondayStart', true);
                            formProps.setFieldValue('mondayStart', !formProps.values.mondayStart);
                          }}
                          checked={formProps.values.mondayStart}
                        />
                        <Text className='url-string' fontSize={14}>
                          Subscriber should start workouts on a Monday
                        </Text>

                      </label>
                    </CheckboxWrapper>
                  </FormGroup>
                ) : null }

                {Object.keys(selectedCalendar).length && (programType === 'PRE-BUILT' || isOriginalProgramPreBuilt())
                && (formProps.values.mondayStart
                 || (!formProps.touched.mondayStart && program.startOnDayOfWeek !== null)) ? (
                   <FormGroup className='d-flex'>
                     <Text className='url-string' fontSize={14}>
                       *We recommend scheduling 7 days of "lead-in" workouts prior to the first workout date so the customer can see workouts immediately after purchase
                     </Text>
                   </FormGroup>
                  ) : null }
                <Text className='formgroup-text'><strong>Group Assignment</strong></Text>
                <Select
                  defaultValue={formProps.values.groups}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  options={userGroups}
                  onChange={(options) => {
                    formProps.setFieldValue('groups', options);
                  }}
                  isOptionSelected={(group) => formProps.values.group === group}
                  isMulti
                  isClearable
                />
              </FormGroup>
            </Form>
          </InnerFolderFormWrapper>
        </OuterFolderFormWrapper>
      </AnotherWrapper>
    </ModalWrapper>
  );
};

UpdateProgramSlide2.propTypes = {
  formProps: PropTypes.instanceOf(Object).isRequired,
  program: PropTypes.instanceOf(Object).isRequired,
  programType: PropTypes.func.isRequired,
  setProgramType: PropTypes.func.isRequired,
  page2Errors: PropTypes.bool.isRequired,
  selectedDate: PropTypes.instanceOf(Object),
  setSelectedDate: PropTypes.func.isRequired,
  clickedOffsetDate: PropTypes.bool.isRequired,
  setClickedOffsetDate: PropTypes.func.isRequired,
  selectedCalendar: PropTypes.instanceOf(Object),
  setSelectedCalendar: PropTypes.func.isRequired,
  clickedCalendar: PropTypes.bool.isRequired,
  setClickedCalendar: PropTypes.func.isRequired,
};

export default UpdateProgramSlide2;
