/* eslint-disable react/prop-types */
import React, {
  useEffect, useState, useRef,
} from 'react';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import Logger from 'js-logger';
import ResizeObserver from 'react-resize-observer';
import { useSelector, useDispatch } from 'react-redux';
import { animated, useTransition, useSpring } from 'react-spring';
import IcomoonReact from 'icomoon-react';
import { useTheme } from 'emotion-theming';
import { Form } from 'formik';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';
import Button from '../../../../shared/components/Button/Button';
import FormHandler from '../../../../shared/components/FormHandler/FormHandler';
import {
  setIsReportOpen, fetchGroups, fetchAthletes, fetchOptOutReport,
} from '../../ducks/reportingActions';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import { TransitionGlobal } from '../../../../shared/GlobalStyles';
import OptOutNotesReportFormSlideTwo from './OptOutNotesReportFormSlideTwo';
import { reportInteractionTracker } from '../../../../shared/utils/amplitudeHelper';

const SlideContainer = styled('div')`
  display: block;
  height: auto;
  height: ${(props) => `${props.height}px`};
  position: relative;
  width: 100%;
  min-height: 135px;
  transition: ${TransitionGlobal};
  z-index: 1000;
`;

const SpinnerContainer = styled('div')`
  display: flex;
  height: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  justify-content: center;
  width: 100%;
  z-index: 0;
  left: 0;
  margin-top: 40px;
`;

const FieldError = styled('div')`
  margin: 10px 0px;
  font-size: 14px;
  color: #ff6600;
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const defaultSlideStyle = { position: 'absolute', width: '100%' };

const OptOutNotesReportForm = ({
  closeModal,
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [slideDimensions, setSlideDimensions] = useState({
    height: 0,
    width: 0,
  });

  const slideRef = useRef();
  const theme = useTheme();

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const evaluationReportGAError = useSelector(
    (state) => state.reporting.data.evaluationReportGAError,
  );

  const isLoadingEvaluationReport = useSelector(
    (state) => state.reporting.ui.isLoadingEvaluationReport,
  );
  const sidebarFilter = useSelector((state) => state.reporting.data.sidebarFilter);

  const { accountCode } = currentUser;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGroups(accountCode));
    dispatch(fetchAthletes(currentUser.accountCode));
  }, []);

  const fetchReport = (values) => {
    Logger.debug(sidebarFilter);
    if (sidebarFilter.filterIds.length) {
      if (values.rangeType) {
        dispatch(setIsReportOpen(true, 'Opt Out Notes Report'));
        dispatch(fetchOptOutReport(sidebarFilter, currentUser.accountCode, values));

        // Amplitude tracker.
        reportInteractionTracker(
          'Report Submitted',
          'Opt Out',
          '',
          sidebarFilter.filterIds.length,
          sidebarFilter.filterType.indicator,
        );
      } else {
        toast.error('You must select a date type before continuing');

        // Amplitude tracker.
        reportInteractionTracker('Report Error', 'Opt Out', 'Date has not been selected');
      }
    } else {
      toast.error('You must select filters before continuing');

      // Amplitude tracker.
      reportInteractionTracker('Report Error', 'Opt Out', 'Reporting Filter has not been selected.');
    }
  };

  const formAnimation = useSpring({
    opacity: isLoadingEvaluationReport ? 0 : 1,
    zIndex: 1,
  });

  const spinnerAnimation = useSpring({ opacity: isLoadingEvaluationReport ? 1 : 0, zIndex: 0 });

  const slideTransition = useTransition(activeSlide, null, {
    from: { opacity: 0, transform: activeSlide === 1 ? 'translate3d(25%, 0, 0)' : 'translate3d(-25%, 0, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: activeSlide === 1 ? 'translate3d(-25%, 0, 0)' : 'translate3d(25%, 0, 0)' },
  });

  return (
    <>
      <NavigationWrapper>
        {activeSlide === 1 && (
          <Text
            className='back arrow-div'
            onClick={() => {
              setActiveSlide(0);
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon='left-arrow'
            />
          </Text>
        )}
        <Text
          className='close arrow-div'
          onClick={() => {
            closeModal();
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon='remove'
          />
        </Text>
      </NavigationWrapper>
      <FormHandler
        initialValues={
            {
              startDate: {
                date: null,
                queryFormat: '',
              },
              endDate: {
                date: null,
                queryFormat: '',
              },
            }
        }
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            Logger.debug('Form Values:', values);
            fetchReport(values);
          });
        }}
      >
        {(formProps) => (
          <Form
            style={{ width: '100%' }}
          >
            <animated.div style={formAnimation}>
              {evaluationReportGAError && (
              <FieldError className='text-center'>
                {evaluationReportGAError === '\'evaluationId\' should be integer' ? 'Please select an evaluation' : evaluationReportGAError}
              </FieldError>
              )}
              {formProps.errors.exercise && formProps.touched.exercise ? (
                <FieldError className='text-center'>{formProps.errors.exercise}</FieldError>
              ) : null}
              <SlideContainer
                height={slideDimensions.height}
              >
                {/* Slider transition that mounts the active slide
                      based on the state object, activeSlide. */}
                {slideTransition.map(({ item, props }) => (
                  item === 0 ? (
                    <animated.div ref={slideRef} style={{ ...defaultSlideStyle, ...props }}>
                      <ResizeObserver
                        onResize={(rect) => {
                          setSlideDimensions(rect);
                        }}
                      />
                      <OptOutNotesReportFormSlideTwo
                        formProps={formProps}
                      />
                    </animated.div>
                  ) : (
                    <animated.div ref={slideRef} style={{ ...defaultSlideStyle, ...props }}>
                      <ResizeObserver
                        onResize={(rect) => {
                          setSlideDimensions(rect);
                        }}
                      />
                    </animated.div>
                  )))}
              </SlideContainer>
            </animated.div>
            <animated.div style={spinnerAnimation}>
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            </animated.div>
            {activeSlide === 0 && (
            <Button
              cta='Run Report'
              className='modal-button'
              type='submit'
              customColor={theme.colors.green}
              fullWidth
              noBorder
              large
              square
              primary
              bottom
            />
            )}
          </Form>
        )}
      </FormHandler>
    </>
  );
};

export default OptOutNotesReportForm;
