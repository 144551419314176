/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import Select from 'react-select';
import { createPortal } from 'react-dom';
import moment from 'moment-timezone';
import { animated, useSpring } from 'react-spring';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { setShareDashboardModal, fetchShareDashLink, clearShareDashLink } from '../../ducks/sportCoachDashboardActions';
import Button from '../../../../shared/components/Button/Button';
import ShareDatePicker from './ShareDatePicker';
import Text from '../../../../shared/components/Text/Text';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const ModalContainer = styled('div')`
  z-index: 1003;
  min-height: 270px;
  width: 50vw;
  overflow: auto;
  @media only screen and (max-width : 768px) {
    width: 90vw;
  }
  min-width: 430px;
  max-width: 600px;
  max-height: calc(100vh - 20px);
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .print-button {
    background: #424242;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    min-height: 48px;
  }
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  user-select: none;
`;

const RemoveDiv = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1001;
  color: ${(props) => (props.isDarkMode ? 'white' : '#444444')};
	background: white;
  .remove-div-icon {
    cursor: pointer;
  }
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  max-height: ${(props) => props.customHeight};
  .print-button {
    position: absolute;
    bottom: 0;
    background: #10cd8c;
  }
  transition: max-height 0.5s linear;
`;

const ModalHeader = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 40px;
`;

const HeaderIconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  height: 52px;
  width: 52px;
  border-radius: 50%;
`;

const ModalHeaderText = styled('div')`
  font-size: 24px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  margin-top: 10px;
`;

const DashTitle = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
`;

const DashLink = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
`;

const DashTitleContainer = styled('div')`
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
`;

const DashLinkContainer = styled('div')`
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
`;

const DashTitleBox = styled('input')`
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding-left: 10px;
`;

const DashLinkBox = styled('div')`
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding-left: 10px;
  user-select: none;
  cursor: pointer;
  background: lightgray;
  overflow: visible;
  white-space: wrap;
  height: auto;
  padding: 10px;
`;

const DashDescription = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
`;

const DashDateContainer = styled('div')`
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
`;

const DashDescriptionBox = styled('input')`
  height: 60px;
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding-left: 10px;
`;

const SlidesContainer = styled('div')`
  overflow: hidden;
  width: 100%;
  max-height: ${(props) => props.customHeight};
  transition: max-height 1s linear;
`;

const Slides = styled('div')`
  white-space: nowrap;
  transition: transform 0.5s ease;
  transform: ${(props) => `translateX(-${props.activeSlide * 100}%)`};
  height: 100%;
`;

const Slide = styled('div')`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  height: 100%;
`;

const DownloadLinkDescText = styled('div')`
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #616161;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const DashDateWrapper = styled('div')`
  display: flex;
`;

const SlideIndicators = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  background: white;
`;

const SlideIndicator = styled('div')`
  width: 7px;
  height: 7px;
  border: 1px solid grey;
  background: ${(props) => (props.isActive ? 'grey' : 'none')};
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  transition: background-color 0.5s ease;
`;

const Slide2Block2 = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const BlockText = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  font-weight: 500;
`;

const PortalContainer = styled('div')`
  width: 100%;
  background: white;
  box-shadow: ${BoxShadow};
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  overflow: auto;
`;

const SlideCenterDiv = styled('div')`
  
`;

const ErrorBox = styled('div')`
  display: flex;
  flex-direction: column;
`;

const MappedError = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 14px;
  color: #c0392b;
`;

const ButtonContainer = styled('div')`
  border-radius: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
`;

const RelativePortalContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DatePickerDiv = styled('div')`
  display: flex;
  border: 1px solid #EEEEEE;
  border-radius: 8px;
  width: 90%;
  height: 40px;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  padding-left: 12px;
  cursor: pointer;
`;

const DatePickerDiv2 = styled('div')`
  display: flex;
  border: 1px solid #EEEEEE;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  padding-left: 12px;
  cursor: pointer;
`;

const DatePickerText = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  font-weight: 400;
`;

const TextAndCopy = styled('div')`
  display: flex;
  align-items: center;
`;

const DashDescriptionContainer = styled('div')`
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
`;

const ShareDashboardModal = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const dateType = useSelector((state) => state.sportCoachDashboard.data.dateType);
  const endDateCal = useSelector((state) => state.sportCoachDashboard.data.endDateCal);
  const exercises = useSelector((state) => state.sportCoachDashboard.data.exercises);
  const group = useSelector((state) => state.sportCoachDashboard.data.group);
  const groups = useSelector((state) => state.sportCoachDashboard.data.groups);
  const isShareDashLinkLoading = useSelector(
    (state) => state.sportCoachDashboard.ui.isShareDashLinkLoading,
  );
  const isShareDashModalShowing = useSelector(
    (state) => state.sportCoachDashboard.ui.isShareDashModalShowing,
  );
  const maxType = useSelector((state) => state.sportCoachDashboard.data.maxType);
  const shareDashLink = useSelector((state) => state.sportCoachDashboard.data.shareDashLink);
  const shareDashLinkError = useSelector(
    (state) => state.sportCoachDashboard.data.shareDashLinkError,
  );
  const shareTemplate = useSelector((state) => state.sportCoachDashboard.data.shareTemplate);
  const startDateCal = useSelector((state) => state.sportCoachDashboard.data.startDateCal);
  const template = useSelector((state) => state.sportCoachDashboard.data.template);

  const [selectedStartDate, setSelectedStartDate] = useState(moment());
  const [selectedEndDate, setSelectedEndDate] = useState(moment());
  const [activeSlide, setActiveSlide] = useState(0);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [pickerOpen2, setPickerOpen2] = useState(false);
  const [dscElement, setDscElement] = useState(null);
  const [dscElement2, setDscElement2] = useState(null);
  const [dashDescription, setDashDescription] = useState('');
  const [dashTitle, setDashTitle] = useState('');
  const [localGroup, setLocalGroup] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [errors, setErrors] = useState({
    errorsExist: false,
    errors: [{
      error: 'name error',
      msg: 'You must add a shared link title',
      isShowing: false,
    },
    {
      error: 'select group error',
      msg: 'You must select a group',
      isShowing: false,
    },
    {
      error: 'API error',
      msg: 'Unable to create shareable link, please check the form and try again',
      isShowing: false,
    }],
  });

  const dispatch = useDispatch();

  const handleClose = () => {
    batch(() => {
      dispatch(setShareDashboardModal(false));
      dispatch(clearShareDashLink());
    });
    setActiveSlide(0);
    setDashTitle(template.title || '');
    setDashDescription(template.description || '');
    setLocalGroup(group || null);
  };

  useEffect(() => {
    if (isShareDashModalShowing === false) {
      setErrors({
        errorsExist: false,
        errors: [{
          error: 'name error',
          msg: 'You must add a shared link title',
          isShowing: false,
        },
        {
          error: 'select group error',
          msg: 'You must select a group',
          isShowing: false,
        },
        {
          error: 'API error',
          msg: 'Unable to create shareable link, please check the form and try again',
          isShowing: false,
        }],
      });
    }
  }, [isShareDashModalShowing]);

  const copyAnimation = useSpring({ opacity: copySuccess ? 1 : 0 });

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareDashLink);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  const exerIds = exercises.length > 0 ? exercises.map((item) => item.id)
    : shareTemplate?.exercises?.map((item) => item.id);

  // Properly set group, title, description, start date, and end date for select drop-down
  useEffect(() => {
    if (group) {
      setLocalGroup(group);
    }
  }, [group]);

  useEffect(() => {
    if (shareTemplate) {
      setDashTitle(shareTemplate?.title);
      setDashDescription(shareTemplate?.description);
    }
  }, [shareTemplate]);

  useEffect(() => {
    if (template) {
      setDashTitle(template?.title);
      setDashDescription(template?.description);
    }
  }, [template]);

  useEffect(() => {
    setSelectedStartDate(startDateCal);
  }, [startDateCal]);

  useEffect(() => {
    setSelectedEndDate(endDateCal);
  }, [endDateCal]);

  // Update setErrors if the share link fetch failed
  useEffect(() => {
    if (shareDashLinkError) {
      const newErrors = { ...errors };
      newErrors.errors[2].isShowing = true;
      newErrors.errorsExist = true;
      setErrors(newErrors);
    } else {
      const newErrors = { ...errors };
      newErrors.errors[2].isShowing = false;
      newErrors.errorsExist = false;
      setErrors(newErrors);
    }
  }, [shareDashLinkError]);

  useEffect(() => {
    const dsc = document.getElementById('date-selector-container-1');
    if (dsc) {
      setDscElement(dsc);
    }
    const dsc2 = document.getElementById('date-selector-container-2');
    if (dsc2) {
      setDscElement2(dsc2);
    }
  }, []);

  const handleSubmit = () => {
    if (activeSlide === 0) {
      const submissionObj = {
        startDate: selectedStartDate.format('YYYY-MM-DD'),
        endDate: selectedEndDate.format('YYYY-MM-DD'),
        groupId: localGroup.id,
        exerciseIds: exerIds,
        dateType,
        maxType,
        dashboardTitle: dashTitle,
        dashboardDescription: dashDescription,
      };
      dispatch(fetchShareDashLink(currentUser?.accountCode, submissionObj));
      // KPI tracking
      dashboardTracker('Sport Coach Dashboard', 'Share Saved Dashboard', 'Shareable dashboard link created');
      setTimeout(() => {
        setActiveSlide(1);
      }, 500);
      // On submit, reset/clear the modal and clear the share link in redux
    } else {
      handleClose();
    }
  };

  return (
    <ModalContainer
      onClick={(e) => {
        if (e.target !== 'floating-datepicker-selector' && (pickerOpen || pickerOpen2)) {
          setPickerOpen(false);
          setPickerOpen2(false);
        }
      }}
      isActive={isShareDashModalShowing}
    >
      <RelativePortalContainer>
        <div id='date-selector-container-1' />
        <div id='date-selector-container-2' />
        <ButtonContainer>
          <div />
          <PortalContainer>
            <RemoveDiv>
              <div
                className='remove-div-icon'
                onClick={() => {
                  if (activeSlide === 1) {
                    setActiveSlide(0);
                  }
                }}
              >
                <IcomoonReact
                  iconSet={iconSet}
                  size={18}
                  icon='left-arrow'
                  color={activeSlide === 1 ? 'black' : 'white'}
                />
              </div>
              <ModalHeader>
                <HeaderIconContainer>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={20}
                    icon='share'
                  />
                </HeaderIconContainer>
                <ModalHeaderText>Share Dashboard</ModalHeaderText>
                <ErrorBox>
                  {errors.errors[0].isShowing
                    ? <MappedError>{errors.errors[0].msg}</MappedError> : null}
                  {errors.errors[1].isShowing
                    ? <MappedError>{errors.errors[1].msg}</MappedError> : null}
                  {errors.errors[2].isShowing
                    ? <MappedError>{errors.errors[2].msg}</MappedError> : null}
                </ErrorBox>
              </ModalHeader>
              <div
                className='remove-div-icon'
                onClick={handleClose}
              >
                <IcomoonReact
                  iconSet={iconSet}
                  size={15}
                  icon='remove'
                  color='black'
                />
              </div>
            </RemoveDiv>
            <ContentContainer customHeight={activeSlide === 1 ? '200px' : '500px'}>
              <SlidesContainer customHeight='auto'>
                <Slides activeSlide={activeSlide}>
                  <Slide>
                    <DashTitleContainer>
                      <DashTitle>Title</DashTitle>
                      <DashTitleBox
                        onBlur={() => {
                          if (!dashTitle) {
                            const newErrors = { ...errors };
                            newErrors.errors[0].isShowing = true;
                            newErrors.errorsExist = true;
                            setErrors(newErrors);
                          }
                        }}
                        value={dashTitle}
                        onChange={(e) => {
                          if (errors.errors[0].isShowing && e.target.value.length) {
                            const newErrors = { ...errors };
                            newErrors.errors[0].isShowing = false;
                            newErrors.errorsExist = false;
                            setErrors(newErrors);
                          }
                          setDashTitle(e.target.value);
                        }}
                        placeholder='Title'
                      />
                    </DashTitleContainer>
                    <DashDateWrapper>
                      <DashDateContainer>
                        <DashDescription>Start Date</DashDescription>
                        <DatePickerDiv onClick={() => setPickerOpen(!pickerOpen)}>
                          <DatePickerText>{selectedStartDate ? selectedStartDate.format('YYYY-MM-DD') : 'Choose Date'}</DatePickerText>
                          {dscElement ? createPortal(
                            <ShareDatePicker
                              id='floating-datepicker-selector'
                              isDayPickerOpen={pickerOpen}
                              setIsDayPickerOpen={setPickerOpen}
                              activeDate={selectedStartDate}
                              selectDate={setSelectedStartDate}
                              left={100}
                            />, dscElement,
                          ) : null}
                        </DatePickerDiv>
                      </DashDateContainer>
                      <DashDateContainer>
                        <DashDescription>End Date</DashDescription>
                        <DatePickerDiv2 onClick={() => setPickerOpen2(!pickerOpen2)}>
                          <DatePickerText>{selectedEndDate ? selectedEndDate.format('YYYY-MM-DD') : 'Choose Date'}</DatePickerText>
                          {dscElement2 ? createPortal(
                            <ShareDatePicker
                              id='floating-datepicker-selector2'
                              isDayPickerOpen={pickerOpen2}
                              setIsDayPickerOpen={setPickerOpen2}
                              activeDate={selectedEndDate}
                              selectDate={setSelectedEndDate}
                              right={100}
                            />, dscElement2,
                          ) : null}
                        </DatePickerDiv2>
                      </DashDateContainer>
                    </DashDateWrapper>
                    <Slide2Block2>
                      <BlockText>
                        Group
                      </BlockText>
                      <Select
                        className='item-select'
                        value={localGroup || null}
                        options={groups}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        onChange={(option) => {
                          const newErrors = { ...errors };
                          newErrors.errors[1].isShowing = false;
                          setErrors(newErrors);
                          setLocalGroup(option);
                        }}
                        placeholder='Select a group'
                      />
                    </Slide2Block2>
                    <DashDescriptionContainer>
                      <DashDescription>Description</DashDescription>
                      <DashDescriptionBox
                        value={dashDescription}
                        onChange={(e) => {
                          setDashDescription(e.target.value);
                        }}
                        placeholder='Description (optional)'
                      />
                    </DashDescriptionContainer>
                  </Slide>
                  <Slide>
                    <SlideCenterDiv>
                      <DownloadLinkDescText>
                        This link is specific to this dashboard and will expire in 30 days
                      </DownloadLinkDescText>
                      <DashLinkContainer>
                        <TextAndCopy>
                          <DashLink>Download Link</DashLink>
                          <animated.div style={copyAnimation}>
                            <Text fontSize={18} style={{ margin: '0px 0px 0px 10px' }}>Copied Dashboard URL!</Text>
                          </animated.div>
                        </TextAndCopy>
                        <DashLinkBox onClick={copyToClipboard}>
                          {shareDashLink}
                        </DashLinkBox>
                      </DashLinkContainer>
                    </SlideCenterDiv>
                  </Slide>
                </Slides>
              </SlidesContainer>
            </ContentContainer>
          </PortalContainer>
          <SlideIndicators>
            <SlideIndicator isActive={activeSlide === 0} />
            <SlideIndicator isActive={activeSlide === 1} />
          </SlideIndicators>
          <Button
            bottom
            fullWidth
            cta={activeSlide === 0 ? 'Create Link' : 'Done'}
            className='print-button'
            large
            square
            customTextColor='#FFFFFF'
            disabled={errors.errorsExist === true}
            noBorder
            primary
            isLoading={isShareDashLinkLoading}
            onClick={() => {
              if (activeSlide === 0) {
                if (dashTitle.length) {
                  if (localGroup && Object.keys(localGroup).length) {
                    handleSubmit();
                  } else {
                    const newErrors = { ...errors };
                    newErrors.errors[1].isShowing = true;
                    setErrors(newErrors);
                  }
                } else {
                  const newErrors = { ...errors };
                  newErrors.errors[0].isShowing = true;
                  setErrors(newErrors);
                }
              } else {
                handleClose();
              }
            }}
          />
        </ButtonContainer>
      </RelativePortalContainer>
    </ModalContainer>
  );
};

ShareDashboardModal.defaultProps = {
  modalTitle: '',
  useEffectFunction: undefined,
  mediaDispatchFunction: undefined,
  isDarkMode: false,
};

ShareDashboardModal.propTypes = {

};

export default ShareDashboardModal;
