/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';

import {
  fetchGroups,
  fetchMetricCardData,
  fetchPerformanceOverview,
  setDayRange,
  setEndDateCal,
  setEndDateData,
  setFlaggedAthletes,
  setFlaggedAthletesPerformance,
  setFlaggedAthletesView,
  setForcePlateView,
  setGroupFilterRadio,
  setGroup,
  setIsLegendInfoModalShowing,
  setIsMetricInfoModalShowing,
  setMetricFiltersGroup,
  setSelectedAthletePerformance,
  setShowFlaggedAthletesModalPerformance,
  setStartDateCal,
  setStartDateData,
  setSelectedAthleteGroup,
} from '../../ducks/forcePlateDashboardActions';
import Button from '../../../../shared/components/Button/Button';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import ForcePlateDatePicker from './ForcePlateDatePicker';
import PerformanceOverviewChart from './PerformanceOverviewChart';
import MetricsContainers from './MetricsContainers';
import PerformanceFilter from './PerformanceFilter';
import MetricInfoModal from './MetricInfoModal';
import LegendInfoModal from './LegendInfoModal';
import ForcePlateDropDown from '../ForcePlateDropDown';
import FlaggedAthletesModal from './FlaggedAthletesModal';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const DashboardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 15px 15px 7px 15px;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 16px;
  box-shadow: ${BoxShadow};
  height: 100%;
  position: relative;
`;

const OptionsHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const MenuOptionsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

const DateOptionsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  .printBtn {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    padding: 3px;
    margin-left: 10px;
  }
`;

const NoDataModal = styled('div')`
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  margin: -45px 0px 0px -182px;
  height: 119px;
  width: 400px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: ${BoxShadow};
  font-family: 'Nunito Sans';
  font-size: 19px;
  color: #424242;
  padding: 15px;
`;

const ModalTitle = styled('div')`
  font-weight: bold;
`;

const ModalMessage = styled('div')`
  font-weight: 300;
`;

const LegendAndIcon = styled('div')`
  display: flex;
  width: 215px;
  margin-left: 17px;
  filter: ${(props) => (props.isBlurred ? 'blur(1.2px)' : null)};
  align-items: center;

  button {
    background: transparent!important;
  }

  svg {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 3px;
  }
`;

const LegendTitle = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: 400;
  margin-right: 5px;
`;

const PerformanceOverview = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const endDateCal = useSelector(
    (state) => state.forcePlateDashboard.data.endDateCal,
  );
  const endDateData = useSelector(
    (state) => state.forcePlateDashboard.data.endDateData,
  );
  const flaggedAthletesDataPerformance = useSelector(
    (state) => state.forcePlateDashboard.data.flaggedAthletesDataPerformance,
  );
  const group = useSelector(
    (state) => state.forcePlateDashboard.data.group,
  );
  const groups = useSelector((state) => state.forcePlateDashboard.data.groups);
  const isFlaggedAthletesModalShowingPerformance = useSelector(
    (state) => state.forcePlateDashboard.ui.isFlaggedAthletesModalShowingPerformance,
  );
  const isFlaggedAthletesView = useSelector(
    (state) => state.forcePlateDashboard.ui.isFlaggedAthletesView,
  );
  const isLegendInfoModalShowing = useSelector(
    (state) => state.forcePlateDashboard.ui.isLegendInfoModalShowing,
  );
  const isMetricInfoModalShowing = useSelector(
    (state) => state.forcePlateDashboard.ui.isMetricInfoModalShowing,
  );
  const isPerformanceLoading = useSelector(
    (state) => state.forcePlateDashboard.ui.isPerformanceLoading,
  );
  const metricCardData = useSelector((state) => state.forcePlateDashboard.data.metricCardData);
  const metricFiltersPerformance = useSelector(
    (state) => state.forcePlateDashboard.data.metricFiltersPerformance,
  );
  const performanceOverviewData = useSelector(
    (state) => state.forcePlateDashboard.data.performanceOverviewData,
  );
  const selectedAthletePerformance = useSelector(
    (state) => state.forcePlateDashboard.data.selectedAthletePerformance,
  );
  const startDateCal = useSelector(
    (state) => state.forcePlateDashboard.data.startDateCal,
  );
  const startDateData = useSelector(
    (state) => state.forcePlateDashboard.data.startDateData,
  );

  const dispatch = useDispatch();

  const hasMetricData = metricCardData.length > 0;
  const hasPerformanceData = performanceOverviewData.length > 0;
  const data = hasPerformanceData && [...performanceOverviewData];
  const orderedData = hasPerformanceData
    && performanceOverviewData.sort((a, b) => new Date(a.date) - new Date(b.date));

  const endDateObject = orderedData && Object.values(orderedData).pop();

  const lastObject = hasPerformanceData && Object.values(data).pop();
  const athletes = hasPerformanceData && lastObject.athleteData.map((obj) => ({
    fullName: obj.fullName,
    userId: obj.userId,
  }));
  athletes && athletes.unshift({ fullName: 'View Group', userId: 0 });

  const printRef = useRef();

  useEffect(() => {
    if (endDateObject?.flaggedAthletes?.length > 0) {
      dispatch(setFlaggedAthletesPerformance(endDateObject.flaggedAthletes));
    }
  }, [endDateObject]);

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchGroups(currentUser.accountCode));
    }
  }, [currentUser]);

  const setStartDate = (date) => {
    // KPI tracking
    dashboardTracker('Force Plate Dashboard', 'Performance Overview - Date Picker', 'Start date selected');
    const dateDiff = moment(endDateData).diff(moment(date), 'days');
    batch(() => {
      dispatch(setDayRange(dateDiff));
      dispatch(setStartDateData(moment(date).format('YYYY-MM-DD')));
      dispatch(setStartDateCal(moment(date)));
      dispatch(
        fetchPerformanceOverview(
          currentUser.accountCode,
          moment(date).format('YYYY-MM-DD'),
          endDateData,
          group.id,
          metricFiltersPerformance,
        ),
      );
      dispatch(
        fetchMetricCardData(
          currentUser.accountCode,
          moment(date).format('YYYY-MM-DD'),
          endDateData,
          group.id,
        ),
      );
    });
  };

  const setEndDate = (date) => {
    // KPI tracking
    dashboardTracker('Force Plate Dashboard', 'Performance Overview - Date Picker', 'End date selected');
    const dateDiff = moment(moment(date)).diff(startDateData, 'days');
    batch(() => {
      dispatch(setDayRange(dateDiff));
      dispatch(setEndDateData(moment(date).format('YYYY-MM-DD')));
      dispatch(setEndDateCal(moment(date)));
      dispatch(
        fetchPerformanceOverview(
          currentUser.accountCode,
          startDateData,
          moment(date).format('YYYY-MM-DD'),
          group.id,
          metricFiltersPerformance,
        ),
      );
      dispatch(
        fetchMetricCardData(
          currentUser.accountCode,
          startDateData,
          moment(date).format('YYYY-MM-DD'),
          group.id,
        ),
      );
    });
  };

  const setSelectedGroup = (option) => {
    // KPI tracking
    dashboardTracker('Force Plate Dashboard', 'Performance Overview - Group Picker', 'Group selected');
    batch(() => {
      dispatch(setGroup(option));
      dispatch(setSelectedAthleteGroup(null, false));
      dispatch(setSelectedAthletePerformance(null, false));
      dispatch(
        fetchPerformanceOverview(
          currentUser.accountCode,
          startDateData,
          endDateData,
          option.id,
          metricFiltersPerformance,
        ),
      );
      dispatch(
        fetchMetricCardData(
          currentUser.accountCode,
          startDateData,
          endDateData,
          option.id,
        ),
      );
    });
  };

  const setAthlete = (option) => {
    if (option.fullName === 'View Group') {
      // KPI tracking
      dashboardTracker('Force Plate Dashboard', 'Performance Overview - Athlete Picker', 'Athlete selection reset');
      dispatch(setSelectedAthletePerformance(null, false));
    } else {
      // KPI tracking
      dashboardTracker('Force Plate Dashboard', 'Performance Overview - Athlete Picker', 'Athlete selected');
      dispatch(setSelectedAthletePerformance(option, true));
    }
  };

  const handleLegendInfoClick = () => {
    // KPI tracking
    dashboardTracker('Force Plate Dashboard', 'Performance Overview - Key Performance Metrics Legend', 'Legend info button clicked');
    batch(() => {
      dispatch(setIsMetricInfoModalShowing(false, ''));
      dispatch(setIsLegendInfoModalShowing(true));
    });
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    if (flaggedAthletesDataPerformance?.length > 0) {
      dispatch(setShowFlaggedAthletesModalPerformance(true));
    } else {
      dispatch(setShowFlaggedAthletesModalPerformance(false));
    }
  }, [flaggedAthletesDataPerformance]);

  const handleDismiss = () => {
    // KPI tracking
    dashboardTracker('Force Plate Dashboard', 'Performance Overview - Flagged Athletes', 'Flagged athletes dismissed');
    batch(() => {
      dispatch(setShowFlaggedAthletesModalPerformance(false));
      dispatch(setFlaggedAthletesPerformance([]));
    });
  };

  // Pass along the flagged athletes array and any necessary data to display to group analysis view
  const handleReview = () => {
    // KPI tracking
    dashboardTracker('Force Plate Dashboard', 'Performance Overview - Flagged Athletes', 'Review flagged athletes clicked');
    batch(() => {
      dispatch(setFlaggedAthletes(flaggedAthletesDataPerformance));
      dispatch(setFlaggedAthletesView(true));
      dispatch(setGroupFilterRadio('alerts'));
      dispatch(setMetricFiltersGroup(metricFiltersPerformance));
      dispatch(setShowFlaggedAthletesModalPerformance(false));
      dispatch(setForcePlateView('group'));
    });
  };

  return (
    <>
      <DashboardContainer ref={printRef}>
        <OptionsHeader>
          <MenuOptionsContainer>
            <ForcePlateDropDown
              list={groups}
              label='name'
              selectItemFunction={setSelectedGroup}
              headerWidthPx={175}
              placeholder='Select a Group'
              defaultOption={group || {}}
              readOnly
            />
            {athletes && (
            <ForcePlateDropDown
              list={athletes}
              label='fullName'
              selectItemFunction={setAthlete}
              headerWidthPx={175}
              placeholder='Select an Athlete'
              defaultOption={selectedAthletePerformance || {}}
            />
            )}
            <PerformanceFilter />
          </MenuOptionsContainer>
          <DateOptionsContainer>
            <ForcePlateDatePicker
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDateCal.toDate()}
              endDate={endDateCal.toDate()}
            />
            <Button
              className='printBtn'
              disabled={!hasPerformanceData}
              iconOnly
              icon='printer'
              onClick={() => {
                handlePrint();
                // KPI tracking
                dashboardTracker('Force Plate Dashboard', 'Performance Overview - Print', 'Print button clicked');
              }}
            />
          </DateOptionsContainer>
        </OptionsHeader>
        <PerformanceOverviewChart />
        {!hasPerformanceData && !isPerformanceLoading && (
        <NoDataModal>
          <ModalTitle>
            {!group && !hasPerformanceData ? 'Select a Group' : 'No Data Available'}
          </ModalTitle>
          <ModalMessage>
            {!group && !hasPerformanceData
              ? 'Select a group using the dropdown above to get started'
              : 'There is no data available, please adjust your selection above'}
          </ModalMessage>
        </NoDataModal>
        )}
        {isMetricInfoModalShowing && (
        <MetricInfoModal />
        )}
        {isLegendInfoModalShowing && (
        <LegendInfoModal />
        )}
      </DashboardContainer>
      {!isFlaggedAthletesView && (
      <FlaggedAthletesModal
        active={isFlaggedAthletesModalShowingPerformance}
        handleDismiss={handleDismiss}
        handleReview={handleReview}
        isPerformance
        numOfAthletes={flaggedAthletesDataPerformance?.length}
      />
      )}
      <LegendAndIcon isBlurred={!hasMetricData}>
        <LegendTitle>
          Key Performance Metrics
        </LegendTitle>
        <Button
          iconOnly
          icon='info'
          customColor='#00000000'
          noBorder
          onClick={handleLegendInfoClick}
          rounded
          disabled={!hasMetricData}
        />
      </LegendAndIcon>
      <MetricsContainers />
    </>
  );
};

export default PerformanceOverview;
