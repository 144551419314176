/* eslint-disable react/prop-types */
import React from 'react';
import styled from '@emotion/styled';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import HeaderText from '../../shared/components/HeaderText/HeaderText';
import Settings from './components/Settings';
import { setRecoveryRadarView } from './ducks/RecoveryRadarActions';
import { Nunito } from '../../shared/GlobalStyles';
import NewDashBanner from '../../shared/_TBComponents/NewDashBanner';

const Container = styled('div')`
  width: 100%;
`;

const PageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1450px;
`;

const DashboardHeader = styled('div')`
  display: flex;
  width: 100%;
  background-color: #ffffff;
  padding-top: 30px;
  padding-left: 20px;
  justify-content: space-between;

  h1 {
    padding-bottom: 8px;
  }
`;

const MenuContainer = styled('div')`
  display: flex;
`;

const MenuItem = styled('button')`
  border: none;
  margin-left: 10px;
  margin-right: 12px;
  font-family: ${Nunito};
  font-weight: 400;
  font-size: 16px;
  border-bottom: ${(props) => (props.isActive ? '3px solid #1787FB!important' : '3px solid white')};
  color: ${(props) => (!props.isActive ? '#9E9E9E' : '#444444')};
`;

const RecoveryRadar = () => {
  const recoveryRadarView = useSelector((state) => state.recoveryRadar.ui.recoveryRadarView);

  const dispatch = useDispatch();

  const handleMenuClick = (view) => {
    if (view !== recoveryRadarView) {
      dispatch(setRecoveryRadarView(view));
    }
  };

  return (
    <Container>
      <Helmet>
        <title>Recovery Radar | TeamBuildr</title>
      </Helmet>
      <PageWrapper>
        <DashboardHeader>
          <HeaderText
            fontWeight='300'
            letterSpacing='normal'
          >
            Recovery Radar
          </HeaderText>
          <MenuContainer>
            <MenuItem
              isActive={recoveryRadarView === 'settings'}
              onClick={() => handleMenuClick('settings')}
            >
              Settings
            </MenuItem>
          </MenuContainer>
        </DashboardHeader>
        <NewDashBanner />
        {recoveryRadarView === 'settings' && (
          <Settings />
        )}
      </PageWrapper>
    </Container>
  );
};

export default RecoveryRadar;
