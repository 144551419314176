/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import Select from 'react-select';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { setEditDashboardModal, editDashboard } from '../../ducks/sportCoachDashboardActions';
import Button from '../../../../shared/components/Button/Button';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const ModalContainer = styled('div')`
  z-index: 1003;
  min-height: 270px;
  width: 50vw;
  overflow: auto;
  @media only screen and (max-width : 768px) {
    width: 90vw;
  }
  max-height: calc(100vh - 20px);
  min-width: 430px;
  max-width: 600px;
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .print-button {
    background: #424242;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    min-height: 48px;
  }
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  user-select: none;
`;

const RemoveDiv = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1001;
  color: ${(props) => (props.isDarkMode ? 'white' : '#444444')};
	background: white;
  .remove-div-icon {
    cursor: pointer;
  }
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  flex-direction: column;
  max-height: ${(props) => props.customHeight};
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  .print-button {
    position: absolute;
    bottom: 0;
    background: #10cd8c;
  }
  transition: max-height 0.5s linear;
`;

const ModalHeader = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 40px;
`;

const HeaderIconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  height: 52px;
  width: 52px;
  border-radius: 50%;
`;

const ModalHeaderText = styled('div')`
  font-size: 24px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  margin-top: 10px;
`;

const DashTitle = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
`;

const DashTitleContainer = styled('div')`
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
`;

const DashTitleBox = styled('input')`
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding-left: 10px;
`;

const DashDescription = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
`;

const DashDescriptionContainer = styled('div')`
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
`;

const DashDescriptionBox = styled('input')`
  height: 60px;
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding-left: 10px;
`;

const SlidesContainer = styled('div')`
  overflow: hidden;
  width: 100%;
  height: auto;
`;

const Slides = styled('div')`
  white-space: nowrap;
  transition: transform 0.5s ease;
  transform: ${(props) => `translateX(-${props.activeSlide * 100}%)`};
`;

const Slide = styled('div')`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  height: 100%;
`;

const SlideIndicators = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  background: white;
`;

const SlideIndicator = styled('div')`
  width: 7px;
  height: 7px;
  border: 1px solid grey;
  background: ${(props) => (props.isActive ? 'grey' : 'none')};
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  transition: background-color 0.5s ease;
`;

const Slide2Block2 = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const BlockText = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  font-weight: 500;
`;

const PortalContainer = styled('div')`
  width: 100%;
  background: white;
  box-shadow: ${BoxShadow};
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  overflow: auto;
`;

const ErrorBox = styled('div')`
  display: flex;
  flex-direction: column;
`;

const MappedError = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 14px;
  color: #c0392b;
`;

const ButtonContainer = styled('div')`
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background: white;
`;

const groupAccessOptions = [
  { name: 'All Groups', id: 1 },
  { name: 'Selected Groups', id: 2 },
  { name: 'Private', id: 3 },
];

const EditDashboardModal = () => {
  const editTemplate = useSelector(
    (state) => state.sportCoachDashboard.data.editTemplate,
  );
  const currentUser = useSelector(
    (state) => state.auth.data.currentUser,
  );
  const groups = useSelector(
    (state) => state.sportCoachDashboard.data.groups,
  );
  const [activeSlide, setActiveSlide] = useState(0);
  const [dashDescription, setDashDescription] = useState('');
  const [dashTitle, setDashTitle] = useState('');
  const [groupType, setGroupType] = useState({});
  const [groupAccessBoxActive, setGroupAccessBoxActive] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const templates = useSelector((state) => state.sportCoachDashboard.data.templates);
  const [errors, setErrors] = useState({
    errorsExist: true,
    errors: [{
      error: 'name error',
      msg: 'You must add an evaluation name',
      isShowing: false,
    },
    {
      error: 'incomplete item error',
      msg: 'Some evaluation items are incomplete',
      isShowing: false,
    },
    {
      error: 'need groups error',
      msg: 'You must select at least one group',
      isShowing: false,
    }],
  });

  const dispatch = useDispatch();

  /**
   * This is a complicated-looking function that actually is quite simple
   * What we're doing here is two-fold:
   * 1. If the editing template has existing information associated with
   * it (e.g. name, description, groups, etc.) we want to pre-fill that
   * information.
   * 2. When the modal is closed, we want to reset the modal's active
   * slide to 1
   */
  useEffect(() => {
    if (editTemplate.description) {
      setDashDescription(editTemplate.description);
    } else {
      setDashDescription('');
    }
    if (editTemplate.title) {
      setDashTitle(editTemplate.title);
    } else {
      setDashTitle('');
    }
    if (Object.keys(editTemplate).length) {
      if (editTemplate.groups.length > 0 && editTemplate.groups.length < groups.length) {
        setGroupType(groupAccessOptions[1]);
        setSelectedGroups(editTemplate.groups);
      } else if (editTemplate.groups.length === 0) {
        setGroupType(groupAccessOptions[2]);
        setSelectedGroups([]);
      } else if (editTemplate.groups.length === groups.length) {
        setGroupType(groupAccessOptions[0]);
        setSelectedGroups(groups);
      }
    }
    if (!Object.keys(editTemplate).length) {
      setActiveSlide(0);
    }
  }, [editTemplate]);

  useEffect(() => {
    if (Object.keys(editTemplate).length === 0) {
      setErrors({
        errorsExist: true,
        errors: [{
          error: 'name error',
          msg: 'You must add an evaluation name',
          isShowing: false,
        },
        {
          error: 'incomplete item error',
          msg: 'Some evaluation items are incomplete',
          isShowing: false,
        },
        {
          error: 'need groups error',
          msg: 'You must select at least one group',
          isShowing: false,
        }],
      });
    }
  }, [editTemplate]);

  const submitEdit = () => {
    if (groupType.id === 2 && !selectedGroups.length) {
      const newErrors = { ...errors };
      newErrors.errors[2].isShowing = true;
      setErrors(newErrors);
    } else {
      const submissionObject = {
        title: dashTitle,
        description: dashDescription,
        groups: selectedGroups.map((groupOb) => groupOb.id),
        exercises: editTemplate.exerciseIds,
      };
      const templateIndex = templates.findIndex(
        (template) => template.templateId === editTemplate.templateId,
      );
      dispatch(editDashboard(
        currentUser.accountCode,
        submissionObject,
        editTemplate.templateId,
        templateIndex,
      ));
      // KPI tracking
      dashboardTracker('Sport Coach Dashboard', 'Edit Saved Dashboard', 'Dashboard edited');
    }
  };

  return (
    <ModalContainer
      onClick={(e) => {
        if (e.target !== 'floating-access-selector' && groupAccessBoxActive) {
          setGroupAccessBoxActive(false);
        }
      }}
      isActive={Object.keys(editTemplate).length}
      customWidth={activeSlide === 1 ? '550px' : '1000px'}
      customHeight={activeSlide === 1 ? '400px' : '1000px'}
    >
      <ButtonContainer>
        <PortalContainer>
          <RemoveDiv>
            <div
              className='remove-div-icon'
              onClick={() => {
                if (activeSlide === 1) {
                  setActiveSlide(0);
                }
              }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={18}
                icon='left-arrow'
                color={activeSlide === 1 ? 'black' : 'white'}
              />
            </div>
            <ModalHeader>
              <HeaderIconContainer>
                <IcomoonReact
                  iconSet={iconSet}
                  size={20}
                  icon='pencil'
                />
              </HeaderIconContainer>
              <ModalHeaderText>Edit Dashboard</ModalHeaderText>
              <ErrorBox>
                {errors.errors[0].isShowing
                  ? <MappedError>{errors.errors[0].msg}</MappedError> : null}
                {errors.errors[1].isShowing
                  ? <MappedError>{errors.errors[1].msg}</MappedError> : null}
                {errors.errors[2].isShowing
                  ? <MappedError>{errors.errors[2].msg}</MappedError> : null}
              </ErrorBox>
            </ModalHeader>
            <div
              className='remove-div-icon'
              onClick={() => {
                dispatch(setEditDashboardModal({}));
              }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={15}
                icon='remove'
                color='black'
              />
            </div>
          </RemoveDiv>
          <ContentContainer customHeight={activeSlide === 1 ? '200px' : '500px'}>
            <SlidesContainer>
              <Slides activeSlide={activeSlide}>
                <Slide>
                  <DashTitleContainer>
                    <DashTitle>Title</DashTitle>
                    <DashTitleBox
                      onBlur={() => {
                        if (!dashTitle.length) {
                          const newErrors = { ...errors };
                          newErrors.errors[0].isShowing = true;
                          setErrors(newErrors);
                        }
                      }}
                      value={dashTitle}
                      onChange={(e) => {
                        if (errors.errors[0].isShowing && e.target.value.length) {
                          const newErrors = { ...errors };
                          newErrors.errors[0].isShowing = false;
                          setErrors(newErrors);
                        }
                        setDashTitle(e.target.value);
                      }}
                      placeholder='Title'
                    />
                  </DashTitleContainer>
                  <DashDescriptionContainer>
                    <DashDescription>Description</DashDescription>
                    <DashDescriptionBox
                      value={dashDescription}
                      onChange={(e) => {
                        setDashDescription(e.target.value);
                      }}
                      placeholder='Description (optional)'
                    />
                  </DashDescriptionContainer>
                </Slide>
                <Slide>
                  <Slide2Block2>
                    <BlockText>
                      Group Access
                    </BlockText>
                    <Select
                      className='item-select'
                      defaultValue={groupType}
                      options={groupAccessOptions}
                      value={groupType}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      onChange={(option) => {
                        setGroupType(option);
                        if (option.id === 1) {
                          setSelectedGroups(groups);
                        }
                        if (option.id === 3) {
                          setSelectedGroups([]);
                        }
                      }}
                    />
                  </Slide2Block2>
                  {groupType.id === 2 ? (
                    <>
                      <Slide2Block2>
                        <BlockText>
                          Select Groups
                        </BlockText>
                        <Select
                          className='item-select'
                          options={groups}
                          isMulti
                          defaultValue={selectedGroups}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          onChange={(selectGroups) => {
                            if (errors.errors[2].isShowing && selectGroups.length) {
                              const newErrors = { ...errors };
                              newErrors.errors[2].isShowing = false;
                              setErrors(newErrors);
                            }
                            setSelectedGroups(selectGroups);
                          }}
                        />
                      </Slide2Block2>
                    </>
                  ) : null}
                </Slide>
              </Slides>
            </SlidesContainer>
          </ContentContainer>
        </PortalContainer>
        <SlideIndicators>
          <SlideIndicator isActive={activeSlide === 0} />
          <SlideIndicator isActive={activeSlide === 1} />
        </SlideIndicators>
        <Button
          bottom
          fullWidth
          cta={activeSlide === 0 ? 'Next' : 'Submit'}
          className='print-button'
          large
          square
          disable={errors.errorsExist}
          noBorder
          primary
          customTextColor='#FFFFFF'
          onClick={() => {
            if (activeSlide === 0 && dashTitle.length) {
              setActiveSlide(1);
            } else if (activeSlide === 0 && !dashTitle.length) {
              const newErrors = { ...errors };
              newErrors.errors[0].isShowing = true;
              setErrors(newErrors);
            } else if (activeSlide === 1) {
              submitEdit();
            }
          }}
        />
      </ButtonContainer>
    </ModalContainer>
  );
};

EditDashboardModal.defaultProps = {
};

EditDashboardModal.propTypes = {

};

export default EditDashboardModal;
