/* eslint-disable max-len */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import moment from 'moment';
import styled from '@emotion/styled';
import Text from '../../../../shared/components/Text/Text';
import SharedPickerComponent from './SharedPickerComponent';
import {
  setActiveGroup, fetchAthleteParticipationByGroupId, fetchAthletesToWatchByGroupIdAndDate, setFilterInfoFromPieChart,
} from '../../ducks/wearablesDashboardActions';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const GroupPickerCard = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;
  const reportableGroups = useSelector((state) => state.wearablesDashboard.data.reportableGroups);
  const activeGroup = useSelector((state) => state.wearablesDashboard.data.activeGroup);
  const activeWearablesDashDate = useSelector((state) => state.wearablesDashboard.data.activeWearablesDashDate);

  const reduxFunction = (group) => {
    // KPI tracking
    dashboardTracker('Wearables Dashboard', 'Group Picker', 'Group selected');
    dispatch(setFilterInfoFromPieChart());
    dispatch(setActiveGroup(group));
    const groupId = group.id;
    const date = activeWearablesDashDate || moment().toDate();
    dispatch(fetchAthleteParticipationByGroupId(accountCode, groupId));
    dispatch(fetchAthletesToWatchByGroupIdAndDate(accountCode, groupId, date));
  };

  const Container = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 19px 30px 30px;
    gap: 15px;
    width: 253px;
    height: 137px;
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1);
    border-radius: 24px;
    background-color: ${theme.colors.background[0]};
    
  `;
  const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 253px;
  height: 137px;
  margin-top: 32px;
 
  
  `;

  return (
    <Wrapper>
      <Container>
        <Text fontSize={19} fontWeight={700}>Group</Text>
        {reportableGroups && reportableGroups.length
          ? (<SharedPickerComponent list={reportableGroups} reduxFunction={reduxFunction} visibleContainerItem={Object.keys(activeGroup).length ? activeGroup : reportableGroups[0]} icon={{ name: 'swap', size: 16, color: '#FFFFFF' }} pickerButtonColor='#444444' addSearchBar isGroupPicker />)
          : (<Text>no reportable groups</Text>)}
      </Container>

    </Wrapper>
  );
};

export default GroupPickerCard;
