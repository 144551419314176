import {
  FETCH_REGION_STATUS_FULFILLED,
  FETCH_REGION_STATUS_REJECTED,
  SET_REGION_STATUS_FULFILLED,
  SET_REGION_STATUS_REJECTED,
} from './actionTypes';

const initialState = {
  regionStatusError: null,
  regionStatusToggle: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REGION_STATUS_FULFILLED: {
      return {
        ...state,
        regionStatusError: null,
        regionStatusToggle: action.payload,
      };
    }
    case FETCH_REGION_STATUS_REJECTED: {
      return {
        ...state,
        regionStatusError: action.payload,
        regionStatusToggle: null,
      };
    }
    case SET_REGION_STATUS_FULFILLED: {
      return {
        ...state,
        regionStatusError: null,
        regionStatusToggle: action.payload,
      };
    }
    case SET_REGION_STATUS_REJECTED: {
      return {
        ...state,
        regionStatusError: action.payload,
        regionStatusToggle: null,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
