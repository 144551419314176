// a relatively complex component that displays a different modal depending on which
// document type the user intends to create

import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Form, Field } from 'formik';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useTheme } from 'emotion-theming';
import { typography, color } from 'styled-system';

import Select from 'react-select';
import Text from '../../../../../shared/components/Text/Text';
import useReseller from '../../hooks/useReseller';
import useResellerPrograms from '../../hooks/useResellerPrograms';

import 'react-datepicker/dist/react-datepicker.css';

const SubText = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-weight: 900;
  color: #444444;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  ${typography}
  ${color}
`;

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  .modal-button {
    display: flex;
    align-items: center;
  }
`;

const CheckboxWrapper = styled('div')`
  padding-top: 12px;
`;

const TitlePlusErrorWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InnerFolderFormWrapper = styled('div')`
  display: flex;
  width: 80%;
  justify-content: left;
  align-items: center;
  margin-top: 5px;

  .formik-form {
    width: 100%;
  }
`;

const OuterFolderFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232,232,232);
    margin-top: 5px;
    margin-bottom: 10px;
  }
`;

const FormGroup = styled('div')`
  width: 100%;
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    margin-bottom: 15px;
  }

  .formgroup-text {
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .caveat-text {
    font-size: 11px;
    margin-top: 5px;
  }
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
  .form-control {
    width: 100%;
  }
  .first-stage-select {
    width: 100%;
  }

  .checkbox-label {
    display: flex;
    margin-top: 10px;
  }

  .checkbox {
    margin-right: 10px;
    margin-top: 3px;
  }
`;

const FieldError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  margin-top: 5px;
`;

const FieldErrorForOffsetDate = styled('div')`
  font-size: 14px;
  color: #ff6600;
  margin-bottom: 4px;
  margin-left: 2px;`;

const TextBoxOuterWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const TextBoxContainer = styled('div')`
  border-color: ${(props) => props.borderColor};
  border-radius: 4px;
  border-width: ${(props) => props.borderWidth};
  border-style: solid;
  height: 165px;
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  cursor: pointer;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
`;

const TextBoxTitleContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row;
`;

const TextBoxContentContainer = styled('div')`
  display: flex;
  align-items: center;
  text-align: center;
`;

const TextBoxContent = styled('div')`
  font-size: 16px;
  font-weight: 300;
  margin-left: 6px;
  margin-right: 6px;
`;

const TextBoxTitle = styled('div')`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 12px;
  margin-top: 15px;
`;

const FirstWorkoutContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const CreateProgramSlide2 = ({
  programType,
  setProgramType,
  formProps,
  selectedDate,
  setSelectedDate,
  setSelectedGroups,
  selectedCalendar,
  setSelectedCalendar,
  page2Errors,
}) => {
  const {
    userGroups,
    calendars,
    currentUser,
  } = useReseller();

  const [flattenedCalendars, setFlattenedCalendars] = useState([]);

  const {
    firstWorkout,
    firstWorkoutError,
    handleFetchFirstWorkout,
  } = useResellerPrograms();

  useEffect(() => {
    let newArray = [];
    calendars.forEach((calendar) => {
      newArray.push(calendar);
      if (calendar.childCalendars.length) {
        newArray = newArray.concat(calendar.childCalendars);
      }
    });
    setFlattenedCalendars(newArray);
  }, [calendars]);

  useEffect(() => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    if (accountCode !== undefined && Object.keys(selectedCalendar).length) {
      handleFetchFirstWorkout({ accountCode, calendarId: selectedCalendar.id });
    }
  }, [selectedCalendar]);

  useEffect(() => {
    // Don't overwrite the date if the user has selected one from the date picker
    if (firstWorkout && !firstWorkoutError && !selectedDate) {
      const date = moment(firstWorkout.date);
      setSelectedDate(date);
      formProps.setFieldValue('calendarOffsetDate', firstWorkout.date);
    }
  }, [firstWorkout, firstWorkoutError]);
  const theme = useTheme();

  const onClickTextBox = (selectedProgramType) => {
    formProps.setFieldValue('programType', selectedProgramType);
    setProgramType(selectedProgramType);
    if (selectedProgramType === 'ONGOING') {
      // Wipe out calendarOffsetDate
      formProps.setFieldValue('calendarOffsetDate', '');
      formProps.setFieldValue('mondayStart', null);
    } else if (selectedDate) {
      formProps.setFieldValue('calendarOffsetDate', moment(selectedDate).format('YYYY-MM-DD'));
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      paddingLeft: state.data.parentId ? 35 : 15,
    }),
  };

  return (
    <ModalWrapper>
      <AnotherWrapper>
        <OuterFolderFormWrapper>
          <InnerFolderFormWrapper>
            <Form className='formik-form'>
              <FormGroup>
                <TitlePlusErrorWrapper>
                  <Text className='formgroup-text'><strong>Calendar Assignment</strong></Text>
                </TitlePlusErrorWrapper>
                <Select
                  defaultValue={selectedCalendar}
                  className='first-stage-select'
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  options={flattenedCalendars}
                  styles={customStyles}
                  onChange={(options) => {
                    if (options) {
                      formProps.setFieldTouched('calendarId', true);
                      formProps.setFieldValue('calendarId', options.id);
                      setSelectedCalendar(options);
                    } else {
                      formProps.setFieldValue('calendarId', 0);
                      formProps.setFieldValue('calendarOffsetDate', '');
                      formProps.setFieldValue('programType', null);
                      formProps.setFieldTouched('calendarId', true);
                      setProgramType(null);
                      setSelectedDate(null);
                      setSelectedCalendar({});
                      formProps.setFieldValue('mondayStart', null);
                    }
                  }}
                  isOptionSelected={
                    (pricingOption) => formProps.values.pricingOption === pricingOption
                  }
                  isClearable={Object.keys(selectedCalendar).length}
                  isSearchable
                />
              </FormGroup>
              {Object.keys(selectedCalendar).length ? (
                <FormGroup>
                  <TitlePlusErrorWrapper>
                    <Text className='formgroup-text'><strong>Program Type</strong></Text>
                    {formProps.errors.programType && page2Errors ? (
                      <FieldError className='error-text'>{formProps.errors.programType}</FieldError>
                    ) : null}
                  </TitlePlusErrorWrapper>
                  <TextBoxOuterWrapper>
                    <TextBoxContainer
                      onClick={() => onClickTextBox('PRE-BUILT')}
                      borderColor={programType === 'PRE-BUILT' ? theme.colors.green : 'hsl(0,0%,80%)'}
                      borderWidth={programType === 'PRE-BUILT' ? '2px' : '1px'}
                      marginRight='10px'
                    >
                      <TextBoxTitleContainer>
                        <TextBoxTitle>
                          Pre-Built Program
                        </TextBoxTitle>
                      </TextBoxTitleContainer>
                      <TextBoxContentContainer>
                        <TextBoxContent>
                          I have built workouts on this calendar already.
                        </TextBoxContent>
                      </TextBoxContentContainer>
                    </TextBoxContainer>
                    <TextBoxContainer
                      onClick={() => onClickTextBox('ONGOING')}
                      borderColor={programType === 'ONGOING' ? theme.colors.green : 'hsl(0,0%,80%)'}
                      borderWidth={programType === 'ONGOING' ? '2px' : '1px'}
                      marginLeft='10px'
                    >
                      <TextBoxTitleContainer>
                        <TextBoxTitle>
                          Ongoing Program
                        </TextBoxTitle>
                      </TextBoxTitleContainer>
                      <TextBoxContentContainer>
                        <TextBoxContent>
                          I will be adding workouts to this calendar on an ongoing basis.
                        </TextBoxContent>
                      </TextBoxContentContainer>

                    </TextBoxContainer>
                  </TextBoxOuterWrapper>
                </FormGroup>
              ) : null }
              {Object.keys(selectedCalendar).length && programType === 'ONGOING' ? (
                <FormGroup>
                  <Text className='url-string' fontSize={16} style={{ marginTop: '15px' }}>
                    Subscriber will be placed in calendar on day of registration.
                  </Text>
                </FormGroup>
              ) : null }
              <FormGroup>
                {Object.keys(selectedCalendar).length && programType === 'PRE-BUILT' ? (
                  <FormGroup>
                    <FirstWorkoutContainer>
                      <TitlePlusErrorWrapper>
                        <SubText fontSize={10} className='toggle-text'>First workout is on: </SubText>
                        {formProps.errors.calendarOffsetDate && page2Errors ? (
                          <FieldErrorForOffsetDate className='error-text'>{formProps.errors.calendarOffsetDate}</FieldErrorForOffsetDate>
                        ) : null}
                      </TitlePlusErrorWrapper>
                      <DatePicker
                        className='form-control'
                        selected={
                      (selectedDate && Object.keys(selectedCalendar).length)
                        ? Date.parse(selectedDate)
                        : null
                    }
                        onChange={(date) => {
                          if (date) {
                            const newDate = moment(date);
                            const finalDateString = (newDate.format('YYYY-MM-DD'));
                            formProps.setFieldValue('calendarOffsetDate', finalDateString);
                          } else {
                            formProps.setFieldValue('calendarOffsetDate', '');
                          }
                          setSelectedDate(date);
                        }}
                      />
                    </FirstWorkoutContainer>

                  </FormGroup>
                ) : null }

                {Object.keys(selectedCalendar).length && programType === 'PRE-BUILT' ? (

                  <FormGroup className='d-flex'>
                    <CheckboxWrapper>
                      <label
                        className='checkbox-label'
                      >
                        <Field
                          className='checkbox'
                          id='monday-start-field'
                          name='monday-start'
                          type='checkbox'
                          onChange={() => {
                            formProps.setFieldValue('mondayStart', !formProps.values.mondayStart);
                          }}
                          checked={formProps.values.mondayStart}
                        />
                        <Text className='url-string' fontSize={14}>
                          Subscriber should start workouts on a Monday
                        </Text>

                      </label>
                    </CheckboxWrapper>
                  </FormGroup>
                ) : null }

                {Object.keys(selectedCalendar).length && programType === 'PRE-BUILT' && formProps.values.mondayStart ? (
                  <FormGroup className='d-flex'>
                    <Text className='url-string' fontSize={14}>
                      *We recommend scheduling 7 days of "lead-in" workouts prior to the first workout date so the customer can see workouts immediately after purchase
                    </Text>
                  </FormGroup>
                ) : null }
                <Text className='formgroup-text'><strong>Group Assignment</strong></Text>
                <Select
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  options={userGroups}
                  onChange={(options) => {
                    const groupIds = options.map((group) => group.id);
                    setSelectedGroups(groupIds);
                    formProps.setFieldValue('groups', groupIds);
                  }}
                  isOptionSelected={(group) => formProps.values.group === group}
                  isMulti
                  isClearable
                />
              </FormGroup>
            </Form>
          </InnerFolderFormWrapper>
        </OuterFolderFormWrapper>
      </AnotherWrapper>
    </ModalWrapper>
  );
};

CreateProgramSlide2.propTypes = {
  programType: PropTypes.string.isRequired,
  setProgramType: PropTypes.func.isRequired,
  formProps: PropTypes.instanceOf(Object).isRequired,
  selectedDate: PropTypes.instanceOf(Object),
  selectedCalendar: PropTypes.instanceOf(Object).isRequired,
  setSelectedCalendar: PropTypes.func.isRequired,
  setSelectedGroups: PropTypes.func.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  page2Errors: PropTypes.bool.isRequired,
};

CreateProgramSlide2.defaultProps = {
  selectedDate: undefined,
};

export default CreateProgramSlide2;
