/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';
import FadeIn from 'react-fade-in';
import * as Yup from 'yup';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';
import { Form } from 'formik';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

import Text from '../../../../shared/components/Text/Text';
import FormHandler from '../../../../shared/components/FormHandler/FormHandler';
import {
  fetchQuestionnaireReportSingleDay,
  fetchLoadMonitoringLineChartData,
  setCurrentGroupInGroupPicker,
  setCurrentAthleteInAthletePicker,
  setCurrentDateInDashDatePicker,
} from '../../ducks/loadMonitoringDashboardActions';

import FormPage1 from './FormPage1';
import FormPage2 from './FormPage2';
import GeneratedDataPage from './GeneratedDataPage';
import HeaderText from '../../../../shared/components/HeaderText/HeaderText';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const PageContainer = styled('div')`
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
max-width: 1500px;
}
`;

const HeaderWrapper = styled('div')`
display: flex;
flex-direction: row;
margin-top: 30px;
gap: 25px;
// margin-top: 95px;
// gap: 50px;
// justify-content: center;
// align-self: flex-start;
// align-items: center;
// margin-left: 46px;
// //margin-bottom: 30px;
`;

const NavigationWrapper = styled('div')`
  display: flex;
  align-self: center;
  position: absolute;
  left:${(props) => (props.containerWidth < 450 ? '-25px' : '-40px')};
  top: 33px;
  .arrow-div {
    cursor: pointer;
  }
`;

const NavigationWrapperForDash = styled('div')`
  display: flex;
  align-self: center;
  // position: absolute;
  // left: -40px;
  // top: 33px;
  .arrow-div {
    cursor: pointer;
  }
`;

const ButtonContainer = styled('div')`
display: flex;
gap: 25px;
align-self: ${(props) => (props.containerWidth && props.containerWidth < 620 ? 'flex-start' : 'flex-end')};
border: none;
flex-wrap: wrap;
margin-bottom: 50px;
`;

const Button = styled('button')`
border: none;
border-radius: 10px;
min-width: 175px;
padding: 10px;
`;

const QuestionnaireReportSchema = Yup.object().shape({
  // arrayOfQuestions: Yup.array().min(1, 'Please select at least one question').required('Required'),
  // reportName: Yup.string().when('selectionType', {
  //   is: 'selectIndividualQuestions',
  //   then: Yup.string().required('Please Name Your Report'),
  // }),
  arrayOfQuestions: Yup.string().when('selectionType', {
    is: 'selectIndividualQuestions',
    then: Yup.string().required('Please select at least one question'),
  }),
});

const LoadMonitoringForm = ({
  containerWidth,
  activePage,
  setActivePage,
  isLoadingQuestionnairesForAccount,
  isLoadingQuestionsForAccount,
}) => {
  const reportableGroups = useSelector((state) => state.loadMonitoringDashboard.data.reportableGroups);
  const defaultReportableGroupId = reportableGroups?.length ? reportableGroups[0].id : null;
  const questions = useSelector((state) => state.loadMonitoringDashboard.data.questionsForAccount);
  const questionnaires = useSelector((state) => state.loadMonitoringDashboard.data.questionnairesForAccount);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const { accountCode } = currentUser;

  const dispatch = useDispatch();

  const fetchReport = (values) => {
    /** fetch questionnaire report for single day on default load */
    if (defaultReportableGroupId) {
      /** we are always going to default to fetch the first group in the users reportable groups array */
      const filterType = '2';
      const filterIds = [defaultReportableGroupId];
      const firstGroupInreportableGroupArray = reportableGroups.length && reportableGroups[0];
      dispatch(setCurrentGroupInGroupPicker({ ...firstGroupInreportableGroupArray, displayText: firstGroupInreportableGroupArray.name }));
      dispatch(setCurrentAthleteInAthletePicker({ ...firstGroupInreportableGroupArray, displayText: firstGroupInreportableGroupArray.name, isAthlete: false }));
      dispatch(setCurrentDateInDashDatePicker(values.date));
      dispatch(fetchQuestionnaireReportSingleDay(accountCode, filterType, filterIds, values));

      const lineChartValues = values;
      dispatch(fetchLoadMonitoringLineChartData(accountCode, filterType, filterIds, lineChartValues));
    }
  };

  // const saveFile = (e, formProps) => {
  //   // console.log(formProps);
  //   formProps.setTouched({
  //     arrayOfQuestions: true,
  //     reportName: true,
  //   });
  //   e.preventDefault();
  //   formProps.validateForm().then(() => {
  //     if (formProps.isValid) {
  //       // console.log('hit api to save file');
  //       // console.log('current values saved:', formProps.values);
  //     }
  //   });
  // };

  const currentDate = new Date();

  return (
    <>
      <FormHandler
        initialValues={
          // Object.keys(populateFormWithRecentlyUsedReport).length !== 0 ? (
            // recentleyUsedReport
          // ) : (
            {
              filterType: '',
              filterIds: [],
              reportName: '',
              reportType: 'singleDay',
              selectionType: '',
              date: {
                date: currentDate,
                queryFormat: moment(currentDate).format('YYYY-MM-DD'),
                // queryFormat: currentDate.toISOString().slice(0, 10),
              },
              arrayOfQuestions: [],
              /** pre load with 5 question containers */
              questionIds: [{ title: '', id: '' }, { title: '', id: '' }, { title: '', id: '' }, { title: '', id: '' }, { title: '', id: '' }],
              questionnaire: null,
              questionnaireIds: [],
              showRangeAverages: true,
              showTotal: true,

            }
          // )
        }
        validationSchema={QuestionnaireReportSchema}
        onSubmit={(values, { setSubmitting }) => {
          // Amplitude feature tracker
          dashboardTracker('Load Monitoring', 'Run Individual Questions Report', 'Report submission');
          /** clear out old picker values */
          dispatch(setCurrentGroupInGroupPicker({}));
          dispatch(setCurrentAthleteInAthletePicker({}));
          dispatch(setCurrentDateInDashDatePicker({}));
          /** ----  */
          setTimeout(() => {
            setSubmitting(false);
            fetchReport(values);
            setActivePage(3);
          }, 200);
        }}
      >
        {(formProps) => (
          <>
            <Form style={{ width: activePage === 3 ? '100%' : '80%', height: '100%', position: 'relative' }}>
              { activePage === 1 && (
              <FadeIn>
                <div style={{
                  display: 'flex', flexDirection: 'column', height: '100vh',
                }}
                >
                  <NavigationWrapper containerWidth={containerWidth}>
                    {(activePage === 2 || activePage === 3) && (
                      <Text
                        className='back arrow-div'
                        onClick={() => {
                          setActivePage(activePage - 1);
                          formProps.setErrors({});
                          formProps.setTouched({});
                          if (activePage === 2) {
                            formProps.resetForm();
                          }
                        }}
                      >
                        <IcomoonReact
                          iconSet={iconSet}
                          size={15}
                          icon='left-arrow'
                        />
                      </Text>
                    )}
                  </NavigationWrapper>
                  <HeaderText
                    fontWeight='300'
                    letterSpacing='normal'
                    style={{ marginTop: 30 }}
                  >
                    Self Reported Load Monitoring
                  </HeaderText>
                  <PageContainer style={{ paddingBottom: '30px' }}>
                    <FormPage1
                      activePage={activePage}
                      setActivePage={setActivePage}
                      formProps={formProps}
                      containerWidth={containerWidth}
                      isLoadingQuestionnairesForAccount={isLoadingQuestionnairesForAccount}
                      isLoadingQuestionsForAccount={isLoadingQuestionsForAccount}
                    />
                  </PageContainer>
                </div>
              </FadeIn>
              )}
              {activePage === 2 && (
              <FadeIn>
                <div style={{
                  display: 'flex', flexDirection: 'column', height: '100vh',
                }}
                >
                  <HeaderWrapper>
                    <NavigationWrapper containerWidth={containerWidth}>
                      {(activePage === 2 || activePage === 3) && (
                      <Text
                        className='back arrow-div'
                        onClick={() => {
                          setActivePage(activePage - 1);
                          formProps.setErrors({});
                          formProps.setTouched({});
                          if (activePage === 2) {
                            formProps.resetForm();
                          }
                        }}
                      >
                        <IcomoonReact
                          iconSet={iconSet}
                          size={15}
                          icon='left-arrow'
                        />
                      </Text>
                      )}
                    </NavigationWrapper>
                    <HeaderText
                      fontWeight='300'
                      letterSpacing='normal'
                    >
                      Self Reported Load Monitoring
                    </HeaderText>
                  </HeaderWrapper>
                  <PageContainer style={{ paddingBottom: '30px' }}>
                    <>
                      <FormPage2
                        activePage={activePage}
                        setActivePage={setActivePage}
                        formProps={formProps}
                        questions={questions}
                        questionnaires={questionnaires}
                        containerWidth={containerWidth}
                      />
                      {formProps.values.selectionType === 'selectIndividualQuestions' && (
                        <ButtonContainer containerWidth={containerWidth}>
                          {/* <Button
                          type='button'
                          key='notsubmit'
                          style={{
                            display: formProps.values.selectionType === 'selectAllQuestions' && 'none',
                            background: '#424242',
                          }}
                          onClick={(e) => saveFile(e, formProps)}
                        >
                          <Text fontSize='14px' fontWeight={900} style={{ color: 'white' }}>SAVE FILE</Text>
                        </Button> */}
                          <Button type='submit' key='submit' style={{ background: '#5EC98E' }}>
                            <Text fontSize='14px' fontWeight={900} style={{ color: 'white' }}>RUN REPORT</Text>
                          </Button>
                        </ButtonContainer>
                      )}
                    </>
                  </PageContainer>
                </div>
              </FadeIn>
              )}
              {activePage === 3 && (
              <>
                <FadeIn>
                  <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                    <HeaderWrapper style={{ marginBottom: 20, marginLeft: 15 }}>
                      <NavigationWrapperForDash>
                        {(activePage === 2 || activePage === 3) && (
                        <Text
                          className='back arrow-div'
                          onClick={() => {
                            setActivePage(activePage - 1);
                            formProps.setErrors({});
                            formProps.setTouched({});
                            if (activePage === 2) {
                              formProps.resetForm();
                            }
                          }}
                        >
                          <IcomoonReact
                            iconSet={iconSet}
                            size={15}
                            icon='left-arrow'
                          />
                        </Text>
                        )}
                      </NavigationWrapperForDash>
                      <HeaderText
                        fontWeight='300'
                        letterSpacing='normal'
                      >
                        Self Reported Load Monitoring
                      </HeaderText>
                    </HeaderWrapper>
                    <PageContainer>
                      <GeneratedDataPage
                        containerWidth={containerWidth}
                        setActivePage={setActivePage}
                        formProps={formProps}
                      />
                    </PageContainer>
                  </div>
                </FadeIn>
              </>
              )}
            </Form>
          </>
        )}
      </FormHandler>
    </>
  );
};

export default LoadMonitoringForm;
