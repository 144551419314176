import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { animated, useSpring } from 'react-spring';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import HeaderText from '../../../../shared/components/HeaderText/HeaderText';
import Text from '../../../../shared/components/Text/Text';
import Button from '../../../../shared/components/Button/Button';
import optimizeImage from '../../../../shared/utils/imageOptimizer';

import { fetchMorePrograms } from '../../ducks/programsActions';
import {
  Nunito, BorderRadius, BoxShadow, TransitionGlobal,
} from '../../../../shared/GlobalStyles';

const MoreProgramsContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
  width: 100%;
  background: ${(props) => props.theme.colors.background[0]};
  position: relative;

  h1 {
    margin-bottom: 15px;
  }
`;

const PreviewSlider = styled('div')`
  display: flex;
  padding: 30px 0px;
  align-items: center;
  overflow: hidden;
  
  .info-card {
    background-color: white;
    margin-right: 30px;
  }
`;

const SliderNav = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  padding: 0px 60px;
  z-index: 1;
  .next-button {
    align-self: flex-end;
    margin-left: auto;
  }

  .prev-button {}
  
  @media screen and (max-width: 540px) {
    padding: 0px 15px;
  }
`;

const LargePrice = styled('span')`
  font-size: 25px;
  font-family: ${Nunito};
  font-weight: 900;
  /* color: ${(props) => props.theme.textColor}; */
  color: white;
  text-transform: uppercase;
  text-align: center;
`;

const SmallPrice = styled('div')`
  font-size: 12px;
  font-family: ${Nunito};
  /* color: ${(props) => props.theme.textColor}; */
  color: white;
  text-transform: uppercase;
`;

const CurrencySymbol = styled('span')`
  font-size: 16px;
  font-weight: 900;
  vertical-align: top;
  line-height: 28px;
  color: white;
`;

const PriceContainer = styled('div')`
display: flex;
flex-direction: column;
align-items: center;
`;

const InfoCardContainer = styled('div')`
  display: flex;
  min-width: 333px;
  width: 333px;
  height: 205px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: ${BorderRadius};
  box-shadow: ${BoxShadow};
  position: relative;
  overflow: hidden;
  cursor: pointer;
  ${(props) => (props.programsLength === 1 ? 'margin: 0 auto;' : '')}

  &:hover {
    .featured-image {
      transform: scale(1.25);
    }
  }
`;
const OuterDetailsContainer = styled('div')`
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    p {
        color: white;
    }
    width: 100%
`;

const InnerDetailsContainer = styled('div')`
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
`;

const InfoDetailsContainer = styled('div')`
margin-right: 18px;
`;

const InfoSideDetailsContainer = styled('div')`
`;

const ImageCover = styled('img')`
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
  top: 0px;
  left: 0px;
  transition: ${TransitionGlobal};
  transform: scale(1);
`;

const ImageGradient = styled('div')`
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
  top: 0px;
  left: 0px;
  z-index: 5;
  background-image: linear-gradient(180deg, transparent, #444444);
`;

const ProgramsMessage = styled('div')`
  width: 100%;
  display: flex;
  padding: 30px;
  justify-content: center;
`;

const MorePrograms = ({
  resellerSlug,
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [slideDirection, setSlideDirection] = useState('forward');
  const [slideLocation, setSlideLocation] = useState(0);
  const programData = useSelector((state) => state.programs.data.programData);
  const morePrograms = useSelector((state) => state.programs.data.morePrograms);
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMorePrograms(resellerSlug));
  }, []);

  const slideContainerStyles = {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
  };

  const programsSlideAnimation = useSpring({ transform: `translate3d(${slideLocation}px,0,0)` });

  const filteredMorePrograms = morePrograms.filter((program) => program.programId !== programData.programId);

  const nextSlide = () => {
    setActiveSlide(activeSlide === filteredMorePrograms.length - 1 ? 0 : activeSlide + 1);
    setSlideDirection('forward');
    setSlideLocation(slideLocation - 363);
  };

  const prevSlide = () => {
    setActiveSlide(activeSlide === 0 ? filteredMorePrograms.length - 1 : activeSlide - 1);
    setSlideDirection('backwards');
    setSlideLocation(slideLocation + 363);
  };

  const handleReactLink = (path) => {
    dispatch(push(`/programs${path}`));
  };

  return (
    <MoreProgramsContainer>
      <div className='container' style={{ zIndex: 5 }}>
        <HeaderText textAlign='center'>More Programs</HeaderText>
        <PreviewSlider>
          {filteredMorePrograms.map((program) => {
            const featuredImage = optimizeImage(
              `https://s3.amazonaws.com/teambuildr-${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging' : 'production'}-assets/${program.headerMediaKey}`,
              {
                resize: {
                  width: 600, height: 400, fit: 'cover',
                },
              },
            );

            return (
              <animated.div style={{ ...slideContainerStyles, ...programsSlideAnimation }}>
                <InfoCardContainer programsLength={filteredMorePrograms.length} onClick={() => handleReactLink(program.programPath)} className='info-card'>
                  <ImageCover className='featured-image' src={featuredImage} />
                  <ImageGradient />
                  <OuterDetailsContainer>
                    <InnerDetailsContainer>
                      <InfoDetailsContainer className='info-details-container'>
                        {/* // Left side program details */}
                        <>
                          <Text
                            style={{ textAlign: 'left', overflowWrap: 'anywhere' }}
                            fontSize={23}
                            fontWeight='600'
                          >
                            {program.programName}
                          </Text>
                        </>
                      </InfoDetailsContainer>
                      <InfoSideDetailsContainer>
                        {/* // Right side program details */}
                        <PriceContainer>
                          {program.placeSymbolAfterCurrency
                            ? (
                              <div>
                                <div style={{ marginBottom: -6 }}>
                                  <LargePrice>{program.programPrice / 100}</LargePrice>
                                  <CurrencySymbol>
                                    {program.currencySymbol}
                                  </CurrencySymbol>
                                </div>
                                <SmallPrice>
                                  {program.billingFrequencyDescription}
                                </SmallPrice>
                              </div>
                            )
                            : (
                              <div>
                                <div style={{ marginBottom: -6 }}>
                                  <CurrencySymbol>
                                    {program.currencySymbol}
                                  </CurrencySymbol>
                                  <LargePrice>{program.programPrice / 100}</LargePrice>
                                </div>
                                <SmallPrice>
                                  {program.billingFrequencyDescription}
                                </SmallPrice>
                              </div>
                            )}

                        </PriceContainer>
                      </InfoSideDetailsContainer>
                    </InnerDetailsContainer>
                  </OuterDetailsContainer>
                </InfoCardContainer>
              </animated.div>
            );
          })}
          {filteredMorePrograms.length === 0 && (
            <ProgramsMessage>
              <Text fontSize={20}>This account does not have any other programs for sale.  </Text>
            </ProgramsMessage>
          )}
        </PreviewSlider>
      </div>
      {filteredMorePrograms.length > 1 && (
        <SliderNav>
          <Button
            className='prev-button'
            disabled={activeSlide === 0}
            icon='left-arrow'
            iconOnly
            onClick={prevSlide}
            primary
            noBorder
          />
          <Button
            className='next-button'
            disabled={activeSlide === filteredMorePrograms.length - 1}
            icon='right-arrow'
            iconOnly
            onClick={nextSlide}
            primary
            noBorder
          />
        </SliderNav>
      )}
    </MoreProgramsContainer>
  );
};

export default MorePrograms;
