import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useTheme } from 'emotion-theming';
import TableToExcel from '@linways/table-to-excel';
import IcomoonReact from 'icomoon-react';

import Text from '../../../../shared/components/Text/Text';
import Button from '../../../../shared/components/Button/Button';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  TransitionGlobal,
} from '../../../../shared/GlobalStyles';
import { setShowAllForDownload } from '../../ducks/reportingActions';

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;
const OptionsWrapper = styled('div')`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  padding: 30px;
`;

const Option = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 180px;
  height: 180px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  cursor: pointer;
  border-width: 1px;
  transition: ${TransitionGlobal};

  :hover {
    opacity: 0.7;
  }
`;

const EvaluationDownloadReport = ({
  closeModal,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowAllForDownload(true));
  }, []);

  const exportOverviewFile = () => {
    const tbOverview = document.getElementById('overviewTable');

    TableToExcel.convert(tbOverview, {
      name: 'evaluation-report-overview.xlsx',
      sheet: {
        name: 'Sheet 1',
      },
    });
  };

  const exportGroupAnalysisFile = () => {
    const tbGAnalysis = document.getElementById('groupAnalysisTable');

    TableToExcel.convert(tbGAnalysis, {
      name: 'evaluation-report-group-analysis.xlsx',
      sheet: {
        name: 'Sheet 1',
      },
    });
  };

  return (
    <>
      <NavigationWrapper>
        <Text
          className='close arrow-div'
          onClick={closeModal}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon='remove'
          />
        </Text>
      </NavigationWrapper>
      <Text
        fontSize='18px'
        textAlign='center'
      >
        Choose a table from the evaluation report to download as an excel file.
        <OptionsWrapper>
          <Option
            onClick={exportOverviewFile}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={35}
              icon='group'
            />
            <Text style={{
              fontSize: '13px',
              marginTop: '15px',
            }}
            >
              Download Group Averages
            </Text>
          </Option>
          <Option
            onClick={exportGroupAnalysisFile}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={35}
              icon='raw-data-report'
            />
            <Text style={{
              fontSize: '13px',
              marginTop: '15px',
            }}
            >
              Download Group Analaysis
            </Text>
          </Option>
        </OptionsWrapper>
      </Text>
      <Button
        cta='Close'
        className='modal-button'
        type='button'
        customColor={theme.colors.green}
        onClick={closeModal}
        fullWidth
        noBorder
        large
        square
        primary
        bottom
      />
    </>
  );
};

EvaluationDownloadReport.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default EvaluationDownloadReport;
