import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray } from 'formik';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import moment from 'moment';

import WorkoutFields from './WorkoutFields';

const HealthWrapper = styled('div')`
  margin-top: -8px;
  padding: 0px 3px;
`;

const HealthEntries = ({
  activeDate,
  athleteId,
  exerType,
  formikRef,
  index,
  namespace,
  sets,
  setList,
  workout,
  updateProgramWorkout,
  updateWorkout,
}) => {
  const accountCode = useSelector((state) => state.weightRoomView.data.currentUser.accountCode);
  const currentNonDateDay = useSelector((
    state,
  ) => state.weightRoomView[namespace].currentNonDateDay);
  const currentSelectedProgram = useSelector((
    state,
  ) => state.weightRoomView[namespace].currentSelectedProgram);
  const isProgramView = useSelector((state) => state.weightRoomView[namespace].isProgramView);

  // Cardio Render Conditions
  return (
    <Formik
      key={workout.assignedId || workout.saveDataId}
      ref={formikRef}
      initialValues={
      {
        accountCode,
        assignedDate: moment(activeDate).format('YYYY-MM-DD'),
        assignedId: workout.assignedId || workout.saveDataId,
        userId: athleteId,
        body: {
          setId: workout.tableData[0].setId,
          [workout.tableData[0].values[0].valueName]: workout.tableData[0].values[0].value,
        },
      }
    }
      onSubmit={(values, actions) => {
        if (isProgramView) {
          updateProgramWorkout(
            index,
            namespace,
            currentSelectedProgram.dayBasedProgramId,
            currentNonDateDay.trueDayNum,
            currentNonDateDay.relativeDisplayDayNum - 1,
            values,
          );
        } else {
          updateWorkout(index, namespace, values);
        }
        actions.setSubmitting(false);
      }}
      render={(props) => (
        <HealthWrapper className='health_wrapper'>
          <Form>
            <FieldArray
              name='body'
              render={() => (
                <>
                  <FieldArray
                    name='value'
                    render={() => (
                      <>
                        <WorkoutFields
                          exerType={exerType}
                          handleChange={props.handleChange}
                          handleSubmit={props.handleSubmit}
                          id='0'
                          labels={false}
                          name='value'
                          isHealth
                          sets={sets}
                          setList={setList}
                          title={workout.exercise.name}
                        />
                      </>
                    )}
                  />
                </>
              )}
            />
          </Form>
        </HealthWrapper>
      )}
    />
  );
};

HealthEntries.propTypes = {
  athleteId: PropTypes.number.isRequired,
  exerType: PropTypes.string.isRequired,
  formikRef: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  namespace: PropTypes.string.isRequired,
  sets: PropTypes.number.isRequired,
  setList: PropTypes.instanceOf(Array).isRequired,
  updateProgramWorkout: PropTypes.func.isRequired,
  updateWorkout: PropTypes.func.isRequired,
  workout: PropTypes.instanceOf(Object).isRequired,
};

export default HealthEntries;
