import React from 'react';
import styled from '@emotion/styled';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import LogRocket from 'logrocket';
import Logger from 'js-logger';

import Layout from '../../shared/components/Layout/Layout';
import ReportingSidebar from './components/ReportingSidebar';
import ReportingGrid from './components/ReportingGrid';
import ActiveReport from './components/ActiveReport';
import { BoxShadow } from '../../shared/GlobalStyles';

const PageWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  height: calc(100% - 49px);
  margin-top: 49px;
  cursor: default;

  .filter-button {
    position: absolute;
    bottom: 30px;
    align-self: center;
    display: none;
    z-index: 100000;
    box-shadow: ${BoxShadow};

    @media screen and (max-width: 540px) {
      display: flex;
      padding: 10px 18px;
    }
  }
  @media screen and (max-width: 540px) {
    justify-content: center;
  }
`;

const Reporting = () => {
  const activeReport = useSelector((state) => state.reporting.ui.activeReport);
  const currentUser = useSelector((state) => state.auth.data.currentUser);

  if (process.env.ENVIRONMENT === 'PRODUCTION' && (currentUser.userSettings && currentUser.userSettings.sessionLoggingEnabled === 1)) {
    LogRocket.init('v2xspp/tb-reporting');
    Logger.debug('Logrocket Enabled v1.2.00');
    LogRocket.identify(currentUser && currentUser.id, {
      name: currentUser && `${currentUser.first} ${currentUser.last}`,
      email: currentUser && currentUser.email,
      organizationId: currentUser && currentUser.organizationId,
      organizationAccessLevel: currentUser && currentUser.organizationAccessLevel,
      subscriptionId: currentUser && currentUser.organizationSubscriptionId,
      appVersion: '1.2.00',
    });
  }

  return (
    <Layout
      fullWidth
    >
      <Helmet>
        <title>Reporting | TeamBuildr</title>
      </Helmet>
      <PageWrapper>
        <ReportingSidebar />
        {activeReport === '' ? (
          <ReportingGrid />
        ) : (
          <ActiveReport />
        )}
      </PageWrapper>
    </Layout>
  );
};

export default Reporting;
