import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useRouteMatch, useParams } from 'react-router-dom';
import Logger from 'js-logger';
import FontPicker from 'font-picker-react';

import { fetchWorkoutPreview} from '../../ducks/programsActions';
import HeaderText from '../../../../shared/components/HeaderText/HeaderText';
import SubText from '../../../../shared/components/SubText/SubText';
import ProgramDetails from './ProgramDetails';
import WorkoutPreview from './WorkoutPreview';
import MorePrograms from './MorePrograms';
import ProgramModal from './ProgramModal';
import optimizeImage from '../../../../shared/utils/imageOptimizer';

const PageWrapper = styled('div')`
  #font-picker {
    position: fixed;
    top: -10000px;
    z-index: -1;
  }
`;

const FeatureContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 360px;
  background: #1d1d1d;
  background-size: cover;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  img {
    object-fit: cover;
    opacity: .1;
    width: 100%;
    height: 100%;
  }

  h1, span {
    ${(props) => (props.isLoadingProgram ? 'color: transparent' : 'color: white')};
  }

  span {
    margin-top: 15px;
  }
`;

const ProgramTitle = styled('div')`
  position: absolute;
  text-align: center;
`;

const ProgramPage = ({
  programData,
  isAuthenticatedv3,
  isLoadingProgram,
  isRegisteredInClient,
  isAuthenticated,
  currentUser,
}) => {
  const { resellerSlug, programSlug } = useParams();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const {
    accountLogoKey,
    accountColorPrimary,
    billingFrequency,
    billingFrequencyDescription,
    customFont,
    currencySymbol,
    headerMediaKey,
    numBillingCycles,
    programDescriptionLong,
    programDescriptionShort,
    programName,
    programPrice,
    resellerName,
    status,
    trialPeriod,
    placeSymbolAfterCurrency,
  } = programData;

  const headerImage = optimizeImage(
    `https://s3.amazonaws.com/teambuildr-${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging' : 'production'}-assets/${headerMediaKey}`,
    {
      resize: {
        with: 800, fit: 'cover',
      },
    },
  );

  Logger.debug('Params:', resellerSlug, programSlug);
  Logger.debug('Path:', path);
  Logger.debug(':', window.location.href);

  return (
    <PageWrapper>
      <FontPicker
        apiKey='AIzaSyDQgB01ojlqMbudd0krjI5GkJpeJQILjFA'
        activeFontFamily={customFont === null ? 'Open Sans' : customFont}
        onChange={(nextFont) => {
          console.log(nextFont);
        }}
      />
      <FeatureContainer>
        <img alt='background' src={headerImage} />
        <ProgramTitle>
          <HeaderText fontFamily='BLACKHAWK' className={customFont === null ? '' : 'apply-font'} fontSize={64}>{programName}</HeaderText>
          <SubText fontWeight={500}>{programDescriptionShort}</SubText>
        </ProgramTitle>
      </FeatureContainer>
      <div className='container'>
        <ProgramDetails
          accountColorPrimary={accountColorPrimary}
          accountLogoKey={accountLogoKey}
          billingFrequency={billingFrequency}
          billingFrequencyDescription={billingFrequencyDescription}
          currencySymbol={currencySymbol}
          numBillingCycles={numBillingCycles}
          programDescriptionLong={programDescriptionLong}
          programName={programName}
          programPrice={programPrice}
          resellerName={resellerName}
          trialPeriod={trialPeriod}
          isLoadingProgram={isLoadingProgram}
          isRegisteredInClient={isRegisteredInClient}
          isAuthenticated={isAuthenticated}
          isAuthenticatedv3={isAuthenticatedv3}
          status={status}
          placeSymbolAfterCurrency={placeSymbolAfterCurrency}
        />
      </div>
      <WorkoutPreview
        dispatch={dispatch}
        fetchWorkoutPreview={fetchWorkoutPreview}
        resellerSlug={resellerSlug}
        programSlug={programSlug}
      />
      <MorePrograms
        resellerSlug={resellerSlug}
      />
      <ProgramModal
        accountLogoKey={currentUser && currentUser.pic}
        resellerSlug={resellerSlug}
        programSlug={programSlug}
        isAuthenticatedv3={isAuthenticatedv3}
        userName={currentUser && `${currentUser.first} ${currentUser.last}`}
      />
    </PageWrapper>
  );
};

ProgramPage.propTypes = {
  programData: PropTypes.instanceOf(Object).isRequired,
  isAuthenticatedv3: PropTypes.bool.isRequired,
  isLoadingProgram: PropTypes.bool.isRequired,
  isRegisteredInClient: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
};

export default ProgramPage;
