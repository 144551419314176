import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import ResizeObserver from 'react-resize-observer';
import { animated, useTransition, useSpring } from 'react-spring';
import IcomoonReact from 'icomoon-react';
import * as Yup from 'yup';
import { Form } from 'formik';

import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../../shared/components/Text/Text';
import NoHoverButton from './NoHoverButton';
import FormHandler from '../../../../../shared/components/FormHandler/FormHandler';
import Spinner from '../../../../../shared/components/Spinner/Spinner';
import useReseller from '../../hooks/useReseller';
import optimizeImage from '../../../../../shared/utils/imageOptimizer';

import UpdateProgramSlide1 from './UpdateProgramSlide1';
import UpdateProgramSlide2 from './UpdateProgramSlide2';
import UpdateProgramSlide3 from './UpdateProgramSlide3';

import { TransitionGlobal } from '../../../../../shared/GlobalStyles';

const SlideContainer = styled('div')`
  display: block;
  height: auto;
  height: ${(props) => `${props.height}px`};
  position: relative;
  width: 100%;
  min-height: 184px;
  transition: ${TransitionGlobal};
  z-index: 1000;
`;

const SliderNav = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  width: 45px;
  margin: 0 auto;
  z-index: 10;
  position: relative;
`;

const ModalWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NavCircle = styled('div')`
  height: 10px;
  width: 10px;
  display: block;
  border: 1px solid #979797;
  border-radius: 100%;
  cursor: pointer;

  &.active {
    background-color: #d8d8d8;
    border: 1px solid #d8d8d8;
    cursor: default;
  }
`;

const SpinnerContainer = styled('div')`
  display: flex;
  height: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  justify-content: center;
  width: 100%;
  z-index: 0;
  left: 0;
  margin-top: 40px;
`;

const FieldError = styled('div')`
  margin: 10px 0px;
  font-size: 14px;
  color: #ff6600;
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const FolderTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  min-height: 65px;
  min-width: 250px;
  margin-top: -10px;

  .calendar-icon-text {
    min-width: 300px;
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 300;
  }
`;

const InnerFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232,232,232);
    margin-top: 5px;
    margin-bottom: 10px;
  }
`;

const TitleError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  width: 500px;
  display: flex;
  justify-content: center;
`;

const defaultSlideStyle = { position: 'absolute', width: '100%' };

const programSchema = Yup.object().shape({
  programName: Yup.string()
    .required('Name is required'),
  shortDescription: Yup.string()
    .required('Tagline is required'),
  longDescription: Yup.string()
    .required('Description is required'),
  price: Yup.string()
    .required('Required'),
  programUrl: Yup.string()
    .required('A program URL is required'),
  billingFrequency: Yup.string()
    .required('Required'),
  redirectUrl: Yup.string()
    .url('Must be a valid URL'),
  programType: Yup.string()
    .when('calendarId', {
      is: (calId) => calId,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }).nullable(),
  calendarOffsetDate: Yup.string()
    .when('programType', {
      is: (programType) => programType === 'PRE-BUILT',
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }).nullable(),
});

const UpdateProgramForm = ({
  onRequestClose,
  program,
  setCurrentRow,
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [prevSlide, setPrevSlide] = useState(0);
  const [slideDimensions, setSlideDimensions] = useState({
    height: 0,
    width: 0,
  });
  const slideRef = useRef();
  const [selectedDate, setSelectedDate] = useState(false);
  const [urlHandled, setUrlHandled] = useState(true);
  const [isMediaUploaded, setIsMediaUploaded] = useState(false);
  const [userProfilePic, setUserProfilePic] = useState(null);
  const [programType, setProgramType] = useState(null);
  const [page1Errors, setPage1Errors] = useState(false);
  const [page2Errors, setPage2Errors] = useState(false);
  const [page3Errors, setPage3Errors] = useState(false);
  const [clickedOffsetDate, setClickedOffsetDate] = useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState({});
  const [clickedCalendar, setClickedCalendar] = useState(false);
  const theme = useTheme();
  const {
    currentUser,
    handleUpdateProgram,
    calendars,
  } = useReseller();

  const uploadedMedia = useSelector((state) => state.reseller.data.uploadMedia);
  const isProgramUpdating = useSelector((state) => state.reseller.ui.isProgramUpdating);
  const updateProgramError = useSelector((state) => state.reseller.data.updateProgramError);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [flattenedCalendars, setFlattenedCalendars] = useState([]);
  const [calendarOptions, setCalendarOptions] = useState({});

  useEffect(() => {
    let newArray = [];
    calendars.forEach((calendar) => {
      newArray.push(calendar);
      if (calendar.childCalendars.length) {
        newArray = newArray.concat(calendar.childCalendars);
      }
    });
    if (!Object.keys(calendarOptions).length) {
      const newDictionary = {};
      newArray.forEach((calendar) => {
        newDictionary[calendar.id] = calendar;
      });
      setCalendarOptions(newDictionary);
    }
    setFlattenedCalendars(newArray);
  }, [calendars]);

  useEffect(() => {
    if (program.calendarId && calendars && calendars.length && !clickedCalendar) {
      if (calendarOptions && Object.keys(calendarOptions).length) {
        const cal = calendarOptions[program.calendarId] || {};
        setSelectedCalendar(cal);
      }
    }
  }, [calendarOptions]);

  useEffect(() => {
    if (program && program.mediaKey) {
      const resellerPic = optimizeImage(
        `https://s3.amazonaws.com/teambuildr-${process.env.ENVIRONMENT.toLowerCase() !== 'production' ? 'staging' : 'production'}-assets/${program.mediaKey}`,
        {
          resize: {
            width: 800,
            fit: 'contain',
          },
        },
      );
      setUserProfilePic(resellerPic);
    }
  }, [program]);

  const formAnimation = useSpring({
    opacity: 1,
    zIndex: 1,
  });

  const spinnerAnimation = useSpring({ opacity: 0, zIndex: 0 });

  const fromResolver = () => {
    if (prevSlide === 0 && activeSlide === 1) {
      return 'translate3d(25%, 0, 0)';
    } if (prevSlide === 1 && activeSlide === 2) {
      return 'translate3d(25%, 0, 0)';
    }
    return 'translate3d(-25%, 0, 0)';
  };

  const leaveResolver = () => {
    if (prevSlide === 2 && activeSlide === 1) {
      return 'translate3d(-25%, 0, 0)';
    } if (prevSlide === 1 && activeSlide === 2) {
      return 'translate3d(-25%, 0, 0)';
    } if (prevSlide === 0 && activeSlide === 1) {
      return 'translate3d(-25%, 0, 0)';
    }
    return 'translate3d(25%, 0, 0)';
  };

  const slideTransition = useTransition(activeSlide, null, {
    from: { opacity: 0, transform: fromResolver() },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: leaveResolver() },
  });

  const getInitialProgramTypeValue = () => {
    if (program.calendarId) {
      return program.calendarOffsetDate !== '' && program.calendarOffsetDate !== null ? 'PRE-BUILT' : 'ONGOING';
    }
  };

  return (
    <>
      <ModalWrapper>
        <NavigationWrapper>
          {(activeSlide === 1 || activeSlide === 2) && (
          <Text
            className='back arrow-div'
            onClick={() => {
              if (activeSlide === 1) {
                setActiveSlide(0);
              } else if (activeSlide === 2) {
                setActiveSlide(1);
              }
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon='left-arrow'
            />
          </Text>
          )}
          <Text
            className='close arrow-div'
            onClick={() => {
              onRequestClose();
              setCurrentRow(null);
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={13}
              icon='remove'
            />
          </Text>
        </NavigationWrapper>
        <FormHandler
          validationSchema={programSchema}
          initialValues={
            {
              programName: program.programName,
              shortDescription: program.descriptionShort,
              longDescription: program.descriptionLong,
              programUrl: program.urlSlug,
              billingFrequency: program.billingFrequency,
              price: program.price / 100,
              calendarOffsetDate: program.calendarOffsetDate,
              groups: program.groups,
              trialPeriod: program.trialPeriod,
              status: program.status,
              calendarId: program.calendarId,
              redirectUrl: program.redirectUrl,
              numBillingCycles: program.numBillingCycles,
              mondayStart: program.startOnDayOfWeek !== null,
              programType: getInitialProgramTypeValue(),
            }
          }
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              const newValues = {
                descriptionLong: values.longDescription,
                descriptionShort: values.shortDescription,
                programName: values.programName,
                status: values.status,
                mediaId: uploadedMedia ? uploadedMedia.id : 0,
                groups: values.groups.map((group) => group.id),
                calendarId: values.calendarId || 0,
                trialPeriod: values.trialPeriod ? values.trialPeriod.id || values.trialPeriod : 0,
                numBillingCycles: values.numBillingCycles ? values.numBillingCycles.id || values.numBillingCycles : 0,
                calendarOffsetDate: values.calendarOffsetDate || '',
                redirectUrl: values.redirectUrl || '',
                startOnDayOfWeek: values.mondayStart ? 1 : -1, // clear Monday starts by passing -1 here
              };

              // Make sure calendarOffsetDate gets cleared if the program type is ONGOING
              if (values.programType === 'ONGOING') {
                newValues.calendarOffsetDate = '';
              }

              handleUpdateProgram({
                programId: program.id, accountCode: currentUser.accountCode, newProgram: newValues,
              });
              setSubmitting(false);
            });
          }}
        >
          {(formProps) => (
            <InnerFormWrapper>
              <FolderTextWrapper>
                <Text className='calendar-icon-text'>Update Program</Text>
                {(Object.keys(formProps.errors).length && submitClicked) ? <TitleError className='error-text'>Some required fields are blank</TitleError> : null}
                {updateProgramError ? <TitleError className='error-text'>{updateProgramError}</TitleError> : null}
              </FolderTextWrapper>
              <div className='divider-line' />
              <Form
                style={{ width: '100%' }}
              >
                <animated.div style={formAnimation}>
                  {formProps.errors.exercise && formProps.touched.exercise ? (
                    <FieldError className='text-center'>{formProps.errors.exercise}</FieldError>
                  ) : null}
                  <SlideContainer
                    height={slideDimensions.height}
                  >
                    {/* Slider transition that mounts the active slide
                      based on the state object, activeSlide. */}
                    {slideTransition.map(({ item, props }) => {
                      let returnComponent;
                      if (item === 0) {
                        returnComponent = (
                          <animated.div ref={slideRef} style={{ ...defaultSlideStyle, ...props }}>
                            <ResizeObserver
                              onResize={(rect) => {
                                setSlideDimensions(rect);
                              }}
                            />
                            <UpdateProgramSlide1
                              formProps={formProps}
                              program={program}
                              urlHandled={urlHandled}
                              setUrlHandled={setUrlHandled}
                              isMediaUploaded={isMediaUploaded}
                              setIsMediaUploaded={setIsMediaUploaded}
                              userProfilePic={userProfilePic}
                              setUserProfilePic={setUserProfilePic}
                              page1Errors={page1Errors}
                            />
                          </animated.div>
                        );
                      } else if (item === 1) {
                        returnComponent = (
                          <animated.div ref={slideRef} style={{ ...defaultSlideStyle, ...props }}>
                            <ResizeObserver
                              onResize={(rect) => {
                                setSlideDimensions(rect);
                              }}
                            />
                            <UpdateProgramSlide2
                              formProps={formProps}
                              program={program}
                              programType={programType}
                              setProgramType={setProgramType}
                              page2Errors={page2Errors}
                              selectedDate={selectedDate}
                              setSelectedDate={setSelectedDate}
                              clickedOffsetDate={clickedOffsetDate}
                              setClickedOffsetDate={setClickedOffsetDate}
                              selectedCalendar={selectedCalendar}
                              setSelectedCalendar={setSelectedCalendar}
                              clickedCalendar={clickedCalendar}
                              setClickedCalendar={setClickedCalendar}
                              flattenedCalendars={flattenedCalendars}
                              calendarOptions={calendarOptions}
                            />
                          </animated.div>
                        );
                      } else if (item === 2) {
                        returnComponent = (
                          <animated.div ref={slideRef} style={{ ...defaultSlideStyle, ...props }}>
                            <ResizeObserver
                              onResize={(rect) => {
                                setSlideDimensions(rect);
                              }}
                            />
                            <UpdateProgramSlide3
                              formProps={formProps}
                              program={program}
                              page3Errors={page3Errors}
                            />
                          </animated.div>
                        );
                      }
                      return returnComponent;
                    })}
                  </SlideContainer>
                  <SliderNav>
                    <NavCircle
                      className={activeSlide === 0 ? 'active' : ''}
                      onClick={() => {
                        setPrevSlide(activeSlide);
                        setActiveSlide(0);
                      }}
                    />
                    <NavCircle
                      className={activeSlide === 1 ? 'active' : ''}
                      onClick={() => {
                        /*
                        If the user is on page 1, and they're trying to click
                        the circle to get to page 2, we need to validate the fields
                        on page 1 first.
                        */
                        if (activeSlide === 0) {
                          formProps.validateForm().then((errors) => {
                            const page1Fields = ['programName', 'programUrl', 'shortDescription', 'longDescription', 'redirectUrl'];
                            if (Object.keys(errors).some((item) => page1Fields.includes(item))) {
                              setPage1Errors(true);
                            } else {
                              setPrevSlide(activeSlide);
                              setActiveSlide(1);
                            }
                          });
                        } else {
                          setPrevSlide(activeSlide);
                          setActiveSlide(1);
                        }
                      }}
                    />
                    <NavCircle
                      className={activeSlide === 2 ? 'active' : ''}
                      onClick={() => {
                        /*
                        If the user is on page 1 or 2, and they're trying to click
                        the circle to get to page 3, we need to validate the fields
                        on page 1/2 first.
                        */
                        if (activeSlide === 0 || activeSlide === 1) {
                          formProps.validateForm().then((errors) => {
                            const page1Fields = ['programName', 'programUrl', 'shortDescription', 'longDescription', 'redirectUrl'];
                            const page2Fields = formProps.values.calendarId !== '' && formProps.values.calendarId !== undefined ? ['programType', 'calendarOffsetDate'] : [];
                            const fieldsToCheck = activeSlide === 0 ? page1Fields : page2Fields;
                            if (Object.keys(errors).some((item) => fieldsToCheck.includes(item))) {
                              activeSlide === 0 ? setPage1Errors(true) : setPage2Errors(true);
                            } else {
                              setPrevSlide(activeSlide);
                              setActiveSlide(2);
                            }
                          });
                        } else {
                          setPrevSlide(activeSlide);
                          setActiveSlide(2);
                        }
                      }}
                    />
                  </SliderNav>
                </animated.div>
                <animated.div style={spinnerAnimation}>
                  <SpinnerContainer>
                    <Spinner />
                  </SpinnerContainer>
                </animated.div>
                {(activeSlide === 2) && (
                <NoHoverButton
                  onClick={() => {
                  // Validate slide 3 fields before allowing form to be submitted
                    formProps.validateForm().then((errors) => {
                      const page3Fields = ['price', 'billingFrequency', 'agreement'];
                      if (Object.keys(errors).some((item) => page3Fields.includes(item))) {
                        setPage3Errors(true);
                      } else {
                        formProps.setSubmitting(true);
                        formProps.submitForm();
                        setSubmitClicked(true);
                      }
                    });
                  }}
                  cta={!isProgramUpdating ? 'Update Program' : 'Updating...'}
                  type='button'
                  fullWidth
                  className='modal-button2'
                  noBorder
                  disabled={isProgramUpdating}
                  large
                  square
                  primary
                  customColor={theme.colors.green}
                  bottom
                />
                )}
                {(activeSlide === 0 || activeSlide === 1)
              && (
              <NoHoverButton
                onClick={() => {
                  if (activeSlide === 0) {
                    // Validate slide 1 fields before moving to next page
                    formProps.validateForm().then((errors) => {
                      const page1Fields = ['programName', 'programUrl', 'shortDescription', 'longDescription', 'redirectUrl'];
                      if (Object.keys(errors).some((item) => page1Fields.includes(item))) {
                        setPage1Errors(true);
                      } else {
                        setPrevSlide(activeSlide);
                        setActiveSlide(1);
                      }
                    });
                  } else if (activeSlide === 1) {
                    // Validate slide 2 fields before moving to next page
                    formProps.validateForm().then((errors) => {
                      const page2Fields = formProps.values.calendarId !== '' && formProps.values.calendarId !== undefined ? ['programType', 'calendarOffsetDate'] : [];
                      if (Object.keys(errors).some((item) => page2Fields.includes(item))) {
                        setPage2Errors(true);
                      } else {
                        setPrevSlide(activeSlide);
                        setActiveSlide(2);
                      }
                    });
                  }
                }}
                cta='Next Step'
                type='button'
                fullWidth
                className='modal-button2'
                noBorder
                large
                square
                primary
                customColor={theme.colors.green}
                bottom
              />
              )}
              </Form>
            </InnerFormWrapper>
          )}
        </FormHandler>
      </ModalWrapper>
    </>
  );
};

export default UpdateProgramForm;
