// authored by Joshua Beedle
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  space,
  color,
  layout,
  flexbox,
  typography,
} from 'styled-system';
import IcomoonReact from 'icomoon-react';

import Label from './Label';
import Wrapper from './Wrapper';
import iconSet from '../../images/teambuildr-selection.json';

const Input = styled('input')`
  border: ${(props) => (props.error ? '1px solid #CC0404' : '1px solid rgb(204, 204, 204)')};
  border-radius: 4px;
  ${color}
  display: flex;
  ${flexbox}
  font-family: 'Nunito Sans';
  font-size: ${(props) => (props.fontSize)};
  font-weight: ${(props) => props.fontWeight || 600}; // Set default font-weight to 600
  color: ${(props) => props.textColor || 'inherit'}; // Set default color to inherit
  height: ${(props) => props.height};
  ${layout}
  padding: ${(props) => (props.error ? '0px 25px 0px 12px' : '0px 12px')};
  width: ${(props) => props.width};
  &::placeholder {
    color: ${(props) => props.placeholderColor || 'inherit'}; // Set default placeholder color to inherit
  }
  ${space}
  text-align: ${(props) => (props.centered && 'center')};
  ${typography}
`;

const ErrorText = styled('div')`
  color: #CC0404;
  font-family: 'Nunito Sans';
  font-size: 13px;
  font-weight: 700;
  margin-top: 5px;
`;

/**
 *
 * @param {Bool} centered bool to decide whether or not to center the text
 * @param {Bool} error bool to decide whether or not to display error messages and styling
 * @param {String} errorMsg custom message to display below the input when there is an error
 * @param {String} fontSize custom font size for the input
 * @param {String} height custom height for the wrapper component
 * @param {Bool} inputOnly bool to decide whether or not to remove the label
 * @param {String} label custom text for the label component
 * @param {String} name specifies the name of the <input> element
 * @param {Function} onChange specifies the onChange function that will occur
 * @param {String} placeholder custom text to display as the placeholder
 * @param {String} right specifies the right-side positioning of icon in input on error
 * @param {Object} styledPropsInput custom props for Input
 * @param {Object} styledPropsLabel custom props for Label
 * @param {Object} styledPropsWrapper custom props for Wrapper
 * @param {String} top specifies the top postioning of icon in input on error
 * @param {String} type handles what the input type is (ex. text, number, password)
 * @param {String} value the value to be shown in the <input> element
 * @param {String} width custom width for the wrapper component
 * @param {String} textColor custom color for text
 * @param {Number} fontWeight custom fontWeight for text
 * @param {String} placeholderColor custom color for placeholder text
 * @param {Function} onBlur can be used to handle blur events for formik (ie touched)
*
*/

const SharedInput = ({
  centered,
  error,
  errorMsg,
  fontSize,
  height,
  inputOnly,
  label,
  name,
  onChange,
  placeholder,
  right,
  styledPropsInput,
  styledPropsLabel,
  styledPropsWrapper,
  top,
  type,
  value,
  width,
  fontWeight,
  textColor,
  placeholderColor,
  onBlur,
}) => (
  <Wrapper {...styledPropsWrapper}>
    {!inputOnly && (
      <Label {...styledPropsLabel}>
        {label}
      </Label>
    )}
    <Input
      centered={centered}
      className={error ? 'error' : null}
      error={error}
      fontSize={fontSize}
      fontWeight={fontWeight}
      textColor={textColor}
      placeholderColor={placeholderColor}
      height={height}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      {...styledPropsInput}
      type={type}
      value={value}
      width={width}
      onBlur={onBlur}
    />
    {error && (
      <IcomoonReact
        color='#CC0404'
        icon='remove'
        iconSet={iconSet}
        size={20}
        style={{
          border: '1px solid #CC0404',
          borderRadius: '50%',
          right,
          padding: '1px',
          position: 'absolute',
          top,
        }}
      />
    )}
    {error && (
      <ErrorText>{errorMsg}</ErrorText>
    )}
  </Wrapper>
);

SharedInput.propTypes = {
  centered: PropTypes.bool,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  fontSize: PropTypes.string,
  height: PropTypes.string,
  inputOnly: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  right: PropTypes.string,
  styledPropsInput: PropTypes.instanceOf(Object),
  styledPropsLabel: PropTypes.instanceOf(Object),
  styledPropsWrapper: PropTypes.instanceOf(Object),
  top: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.string,
  fontWeight: PropTypes.number,
  textColor: PropTypes.string,
  placeholderColor: PropTypes.string,
  onBlur: PropTypes.func,
};

SharedInput.defaultProps = {
  centered: false,
  error: false,
  errorMsg: '',
  fontSize: '12px',
  fontWeight: 600,
  height: '',
  inputOnly: false,
  label: '',
  placeholder: '',
  right: '',
  styledPropsInput: {},
  styledPropsLabel: {},
  styledPropsWrapper: {},
  top: '',
  type: 'text',
  width: '',
  textColor: '#444444',
  placeholderColor: '#9E9E9E',
  onBlur: () => {},
};

export default SharedInput;
