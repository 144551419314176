import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useTheme } from 'emotion-theming';
import IcomoonReact from 'icomoon-react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import Text from '../../../../shared/components/Text/Text';
import Button from '../../../../shared/components/Button/Button';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

import { setShowAllForDownload } from '../../ducks/reportingActions';

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const OptOutNotesDownloadReport = ({
  closeModal,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [printingDocumentLoading, setPrintingDocumentLoading] = useState(false);

  useEffect(() => {
    dispatch(setShowAllForDownload(true));
  }, []);

  const handlePrint = async () => {
    const input = document.getElementById('printable-results-content');

    if (!input) {
      console.error('Element with id "printable-results-content" not found');
      return;
    }

    // Temporarily set the height of the body to ensure all content is visible
    document.body.style.height = 'auto';
    const originalOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    // Get the dimensions of the element
    const inputWidth = input.clientWidth;
    const inputHeight = input.clientHeight;

    // Set the PDF page dimensions
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    // Calculate the scale ratio
    const scale = pageWidth / inputWidth;

    const canvasHeight = pageHeight / scale;

    // Iterate over the content and create canvas chunks
    const totalPages = Math.ceil(inputHeight / canvasHeight);
    let yOffset = 0;

    for (let i = 0; i < totalPages; i += 1) {
      const canvas = await html2canvas(input, {
        scale: 2,
        x: 0,
        y: yOffset,
        width: inputWidth,
        height: canvasHeight,
        windowWidth: inputWidth,
        windowHeight: canvasHeight,
      });

      const imgData = canvas.toDataURL('image/png');
      const imgWidth = pageWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight);

      if (i < totalPages - 1) {
        pdf.addPage();
      }

      yOffset += canvasHeight;
    }

    // Restore the original height and overflow of the body
    document.body.style.height = '';
    document.body.style.overflow = originalOverflow;

    pdf.save('download.pdf');
    setPrintingDocumentLoading(false);
    closeModal();
  };

  const DownloadData = () => {
    setPrintingDocumentLoading(true);
    handlePrint();
  };

  return (
    <>
      <NavigationWrapper>
        <Text
          className='close arrow-div'
          onClick={() => {
            closeModal();
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon='remove'
          />
        </Text>
      </NavigationWrapper>
      <Text
        fontSize='18px'
        textAlign='center'
      >
        Would you like to download this opt out notes report as a PDF File?
      </Text>
      <Button
        cta='Download Report'
        className='modal-button'
        type='button'
        customColor={theme.colors.green}
        onClick={DownloadData}
        isLoading={printingDocumentLoading}
        fullWidth
        noBorder
        large
        square
        primary
        bottom
      />
    </>
  );
};

OptOutNotesDownloadReport.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default OptOutNotesDownloadReport;
