import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { useDispatch, useSelector } from 'react-redux';
import IcomoonReact from 'icomoon-react';

import Text from '../../../../shared/components/Text/Text';
import Button from '../../../../shared/components/Button/Button';
import { fetchMaxReport } from '../../ducks/reportingActions';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const MaxDownloadReport = ({
  closeModal,
}) => {
  const currentUser = useSelector(state => state.auth.data.currentUser);
  const sidebarFilter = useSelector(state => state.reporting.data.sidebarFilter);
  const maxReportForm = useSelector(state => state.reporting.data.maxReportForm);

  const theme = useTheme();
  const dispatch = useDispatch();
  const { accountCode } = currentUser;

  const fetchReport = (values) => {
    console.log(sidebarFilter);
    dispatch(fetchMaxReport(sidebarFilter, values, accountCode, 'downloadMaxReport'));
  };

  const DownloadData = () => {
    fetchReport(maxReportForm);
    closeModal();
  };

  return (
    <Fragment>
      <NavigationWrapper>
        <Text
          className='close arrow-div'
          onClick={() => {
            closeModal();
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon='remove'
          />
        </Text>
      </NavigationWrapper>
      <Text
        fontSize='18px'
        textAlign='center'
      >
        {'Would you like to download this max report as a CSV File?'}
      </Text>
      <Button
        cta='Download Report'
        className='modal-button'
        type='button'
        customColor={theme.colors.green}
        onClick={DownloadData}
        fullWidth
        noBorder
        large
        square
        primary
        bottom
      />
    </Fragment>
  );
};

MaxDownloadReport.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default MaxDownloadReport;
