import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import {
  fetchSubscribers,
  setResellerOverlay,
} from '../../../ducks/resellerActions';
import FilterDropdown from './filters/FilterDropdown';
import FilterDropdownSubscriberName from './filters/FilterDropdownSubscriberName';
import FilterDropdownSubscriberStatus from './filters/FilterDropdownSubscriberStatus';
import FilterDropdownSubscriberProgram from './filters/FilterDropdownSubscriberProgram';

import Text from '../../../../../shared/components/Text/Text';
import SubText from '../../../../../shared/components/SubText/SubText';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';

const Container = styled('div')`
  margin-bottom: 20px;
  .subtext {
    text-transform: none;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
  }
`;

const ButtonContainer = styled('div')`
  position: relative;
  z-index: 1000;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  background: #FFFFFF;
  border-radius: 20px;
  box-shadow: 0px 8px 16px rgba(48, 49, 51, 0.1);
  min-width: fit-content;
  max-width: 100%;
  width: max-content;
  min-height: 58px;
  padding: 12px 16px;
  @media only screen and (max-width : 768px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
`;

const FilterButton = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: none;
  width: 85px;
  gap: 5px;
  text-transform: none;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  cursor: pointer;
`;

const ComponentContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  @media only screen and (max-width : 768px) {
    gap: 0;
  }
`;

const FilterDetailContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const FilterDetail = styled('div')`
  display: flex;
  align-items: center;
  background: #E8E8E8;
  border-radius: 20px;
  width: max-content;
  margin: 0 12px;
  padding: 8px 16px;
  gap: 20px;
  cursor: pointer;
  .filter-details__text {
    font-size: 14px;
  }
  @media only screen and (max-width : 768px) {
    margin-bottom: 12px;
  }
`;

const ActiveFilters = styled('div')`
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border-radius: 20px;
  min-width: 111px;
  gap: 5px;
  @media only screen and (max-width : 768px) {
    padding: 6px 0 12px 0;
  }
`;

const FilterError = styled('div')`
  margin-top: 6px;
  margin-left: 16px;
  font-family: 'Nunito Sans';
  font-size: 14px;
  color: #ff6600;
`;

const ResellerSubscribersFilter = () => {
  const [filters, setFilters] = useState([]);
  const [nameValue, setNameValue] = useState('');
  const [stringValue, setStringValue] = useState('');
  const [statusValue, setStatusValue] = useState('');
  const [programValue, setProgramValue] = useState('');
  const [programLabel, setProgramLabel] = useState('');
  const inputRef = useRef(null);

  const [showDropdown, setShowDropdown] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState('');

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const resellerError = useSelector((state) => state.reseller.data.resellerError);
  const overlayState = useSelector((state) => state.reseller.data.activeOverlay);

  const dispatch = useDispatch();

  const subscribersDispatchAction = () => {
    dispatch(fetchSubscribers(currentUser.accountCode, {
      activeProgramId: programValue,
      subscriberName: nameValue,
      subscriptionStatus: statusValue,
    }));
  };

  const overlayDispatchAction = (boolean) => {
    dispatch(setResellerOverlay(boolean));
  };

  useEffect(() => {
    subscribersDispatchAction();
  }, [programValue, nameValue, statusValue]);

  const toggleFilter = () => {
    setShowDropdown((drop) => !drop);
  };

  const toggleFilterDropdown = (dropdown) => {
    setActiveDropdown((current) => (current ? '' : dropdown));
  };

  const removeFilter = (filterId) => {
    setFilters((current) => current.filter((filter) => filter.id !== filterId));
  };

  const handleCloseProgramBadge = () => {
    setProgramValue('');
    setProgramLabel('');
    removeFilter(3);
  };

  const handleCloseNameBadge = () => {
    setNameValue('');
    setStringValue('');
    removeFilter(1);
  };

  const handleCloseStatusBadge = () => {
    setStatusValue('');
    removeFilter(2);
  };

  useEffect(() => {
    if (!overlayState) {
      setShowDropdown(false);
      setActiveDropdown(false);
    }
  }, [overlayState]);

  const statusValues = [
    { value: 1, label: 'Active' },
    { value: 2, label: 'Payment Failed' },
    { value: 3, label: 'Cancelled' },
  ];

  // Applyng the label to the filter badge rather than the value and setting in filterOptions
  const statusFind = statusValues.find((status) => (
    status.value === statusValue ? status.label : null));
  const statusValueLabel = statusFind?.label;

  const filterOptions = [
    {
      id: 3,
      label: 'Subscription',
      text: `Subscription is ${programLabel || '...'}`,
      dropdown: 'Program',
    },
    {
      id: 1,
      label: 'Subscriber Name',
      text: `Name contains ${stringValue || '...'}`,
      dropdown: 'Name',
    },
    {
      id: 2,
      label: 'Subscriber Status',
      text: `Subscription Status: ${statusValue !== '' ? statusValueLabel : '...'}`,
      dropdown: 'Status',
    },
  ];

  // Using the id from filterOptions to create a new array each time a new filter is added
  const updateFilter = (filterId) => {
    const filterReference = filterOptions.find((filter) => filter.id === filterId);
    const newArray = [...filters];
    const mappedArray = newArray.map((filter) => {
      if (filter.id === filterId) {
        return filterReference;
      }
      return filter;
    });
    setFilters(mappedArray);
  };

  // This is needed to ensure that our values inside filterOptions can be dynamically updated
  // Name value doesn't need a useEffect due to stringValue updating - using it causes render issues
  useEffect(() => {
    updateFilter(2);
  }, [statusValue]);

  useEffect(() => {
    updateFilter(3);
  }, [programValue, programLabel]);

  return (
    <Container>
      <ButtonContainer>
        <div>
          { filters.length >= 3 ? null
            : (
              <FilterButton onClick={() => {
                toggleFilter();
                overlayDispatchAction(true);
                setActiveDropdown('');
              }}
              >
                <IcomoonReact iconSet={iconSet} size={10} icon='plus' />
                <SubText className='subtext'>Add Filter</SubText>
              </FilterButton>
            ) }
          { showDropdown
            ? (
              <FilterDropdown
                filters={filters}
                setFilters={setFilters}
                filterOptions={filterOptions}
                setShowDropdown={setShowDropdown}
                setActiveDropdown={setActiveDropdown}
              />
            ) : null }
        </div>
        <ComponentContainer>
          { filters.map((filter, index) => (
            <FilterDetailContainer key={filter.id}>
              <FilterDetail
                onClick={() => {
                  if (filter.id === 1) {
                    handleCloseNameBadge();
                    overlayDispatchAction(false);
                  }
                  if (filter.id === 2) {
                    handleCloseStatusBadge();
                    overlayDispatchAction(false);
                  }
                  if (filter.id === 3) {
                    handleCloseProgramBadge();
                    overlayDispatchAction(false);
                  }
                }}
              >
                <Text className='filter-details__text'>{filter.text}</Text>
                <IcomoonReact iconSet={iconSet} size={15} icon='remove' />
              </FilterDetail>
              { activeDropdown === 'Name' && filter.id === 1 ? (
                <FilterDropdownSubscriberName
                  inputRef={inputRef}
                  toggleFilterDropdown={toggleFilterDropdown}
                  updateFilter={updateFilter}
                  setNameValue={setNameValue}
                  stringValue={stringValue}
                  setStringValue={setStringValue}
                  overlayDispatchAction={overlayDispatchAction}
                />
              ) : null }
              { activeDropdown === 'Status' && filter.id === 2 ? (
                <FilterDropdownSubscriberStatus
                  setStatusValue={setStatusValue}
                  toggleFilterDropdown={toggleFilterDropdown}
                  updateFilter={updateFilter}
                  overlayDispatchAction={overlayDispatchAction}
                />
              ) : null }
              { activeDropdown === 'Program' && filter.id === 3 ? (
                <FilterDropdownSubscriberProgram
                  setProgramValue={setProgramValue}
                  setProgramLabel={setProgramLabel}
                  toggleFilterDropdown={toggleFilterDropdown}
                  updateFilter={updateFilter}
                  overlayDispatchAction={overlayDispatchAction}
                />
              ) : null }
              { filters.length > 1 && index !== filters.length - 1 ? (
                <SubText className='subtext'>and</SubText>
              ) : null }
            </FilterDetailContainer>
          ))}
        </ComponentContainer>
        { filters.length ? (
          <ActiveFilters>
            <IcomoonReact iconSet={iconSet} size={15} icon='filter' />
            <SubText className='subtext'>Active Filters</SubText>
          </ActiveFilters>
        ) : null }
      </ButtonContainer>
      <div>
        {resellerError ? (
          <FilterError>{resellerError}</FilterError>) : null}
      </div>
    </Container>
  );
};

export default ResellerSubscribersFilter;
