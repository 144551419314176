/* eslint-disable max-len */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';
import ResizeObserver from 'react-resize-observer';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';
import { deleteHabitForAccount, fetchSingleHabitForAccount, deleteCategoryForAccount } from '../../ducks/habitTrackingActions';
import { setModalOpen, setErrorModalOpen } from '../../../sports-science/ducks/sportsScienceActions';
import Spinner from '../../../../shared/components/Spinner/Spinner';

const Wrapper = styled('div')`
display: flex;
flex-direction: column;
border-radius: 24px;
background: #FFFFFF;
max-width: 452px;
`;

const Button = styled('button')`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
background: ${(props) => props.backgroundColor && props.backgroundColor};
padding: 8px 16px 8px 16px;
height: 51px;
border-radius: 4px;
border: none;
transition: all 250ms ease-in-out;
:hover {
  opacity: 0.7;
}
`;

const SetToInactiveButton = styled('button')`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
padding: 8px 16px 8px 16px;
height: 51px;
border-radius: 4px;
border: 1px solid #CC0404;
transition: all 250ms ease-in-out;
:hover {
  opacity: 0.7;
}
`;

const Header = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding: 40px 20px 40px 20px;
  text-align: center;
  gap: 20px;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  
`;

const IconCircle = styled('div')`
display: flex;
height: 52px;
width: 52px;
min-width: 52px;
border-radius: 50%;
justify-content: center;
align-items: center;
border: 1px solid #444444;
padding-bottom: 5px;
`;

const Body = styled('div')`
display: flex;
flex-direction: column;
padding: 20px;
gap: 15px;
width: 452px;
text-align: center;
`;

const IconHoverWrapper = styled('div')`
  cursor: pointer;
  transition: all 250ms ease-in-out;
  &:hover {
    opacity: 0.7;
  }
`;

const DeleteModalContent = ({ closeModal }) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const dispatch = useDispatch();
  const activeModal = useSelector((state) => state.sportsScience.ui.activeModal);
  const deleteModalData = useSelector((state) => state.habitTracking.data.deleteModalData);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;
  const isDeleteHabitLoading = useSelector((state) => state.habitTracking.ui.isDeleteHabitLoading);
  const isDeleteCategoryLoading = useSelector((state) => state.habitTracking.ui.isDeleteCategoryLoading);

  const handleDelete = () => {
    if (activeModal === 'habitTracking-delete-habit') {
      dispatch(deleteHabitForAccount(accountCode, deleteModalData?.id, {
        onSuccess: () => {
          closeModal();
        },
        onError: () => {
          dispatch(setErrorModalOpen(true, 'general-error', 'Could not delete habit'));
        },
      }));
    } else {
      dispatch(deleteCategoryForAccount(accountCode, deleteModalData?.id, {
        onSuccess: () => {
          closeModal();
        },
        onError: () => {
          dispatch(setErrorModalOpen(true, 'general-error', 'Could not delete category'));
        },
      }));
    }
  };

  const handleSetToInactive = () => {
    dispatch(fetchSingleHabitForAccount(accountCode, deleteModalData?.id, {
      onSuccess: () => {},
      onError: () => {
        dispatch(setModalOpen(false, ''));
        dispatch(setErrorModalOpen(true, 'general-error', 'could not fetch data for habit'));
      },
    }));
    dispatch(setModalOpen(true, 'habitTracking-edit-habit'));
  };

  return (
    <Wrapper>
      <ResizeObserver
        onResize={(rect) => {
          setContainerWidth(rect?.width);
        }}
      />

      <Header>
        <IconHoverWrapper>
          <IcomoonReact
            iconSet={iconSet}
            size={16}
            icon='remove'
            color='#444444'
            style={{ position: 'absolute', right: 20, top: 20 }}
            onClick={() => closeModal()}
          />
        </IconHoverWrapper>
        <IconCircle>
          <IcomoonReact
            iconSet={iconSet}
            size={28}
            icon='trashcan'
          />
        </IconCircle>
        {activeModal === 'habitTracking-delete-habit' ? (
          <Text fontWeight={400} fontSize='23px'>
            Delete Habit
            <span style={{ color: '#CC0404', marginLeft: '5px', fontWeight: 700 }}>{deleteModalData?.name}</span>
          </Text>
        ) : (
          <Text fontWeight={400} fontSize='23px'>
            Delete Category
            <span style={{ color: '#CC0404', marginLeft: '5px', fontWeight: 700 }}>{deleteModalData?.name}</span>
          </Text>
        )}
      </Header>
      <Body containerWidth={containerWidth}>
        {isDeleteHabitLoading || isDeleteCategoryLoading ? (
          <Spinner />
        ) : (
          <>
            {activeModal === 'habitTracking-delete-category' ? (
              <>
                <Text fontSize='16px' fontWeight={700} style={{ marginBottom: '10px' }}>
                  {`"${deleteModalData?.name}" will be permanently deleted and you will not be able to recover it.`}
                </Text>
                <Text fontSize='16px' fontWeight={700} style={{ marginBottom: '10px' }}>
                  {`Habits that are currently linked to the "${deleteModalData?.name}" category will stay active and continue to gather data.`}
                </Text>
                <Text fontSize='16px' fontWeight={700} style={{ marginBottom: '10px' }}>
                  We recommend reassigning the associated habits with a category in order to view group or individual data analysis.
                </Text>
              </>
            ) : (
              <>
                <Text fontSize='16px' fontWeight={700} style={{ marginBottom: '10px' }}>
                  Are you sure you want to delete this habit?
                </Text>
                <Text fontSize='16px' fontWeight={700} style={{ marginBottom: '10px' }}>
                  Permanently deleting a habit will remove all associated data connected to this habit.
                </Text>
                <Text fontSize='16px' fontWeight={700}>
                  If you want to keep data history, set habit to inactive as an alternative to permanently deleting the habit.
                </Text>
              </>
            )}
            {activeModal === 'habitTracking-delete-habit' && (
            <SetToInactiveButton onClick={() => handleSetToInactive()}>
              <Text fontSize='23px' fontWeight={700} color='#CC0404'>Set to inactive</Text>
            </SetToInactiveButton>
            )}
            <Button onClick={() => handleDelete()} backgroundColor='#CC0404'>
              <Text fontSize='23px' fontWeight={700} color='white'>DELETE</Text>
            </Button>
          </>
        )}
      </Body>
    </Wrapper>

  );
};

export default DeleteModalContent;

DeleteModalContent.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
