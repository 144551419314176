/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, {
  useState, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';
import Shimmer from 'react-shimmer-effect';
import { openPopupWidget } from 'react-calendly';
import {
  useLocation,
} from 'react-router-dom';

import { push } from 'connected-react-router';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import {
  setIsUpgradeModalShowing,
  fetchSubscriptionPackage,
  fetchSubscriptionStatus,
  setSelectedPackage,
  setIsSideBarExpandedGlobal,
} from '../../ducks/sportsScienceActions';
import corePackage from './corePackage';
import { dashboardTracker, subscriptionFlowTracker, subscriptionFlowTrackerDemo } from '../../../../shared/utils/amplitudeHelper';

const SideBarDiv = styled('div')`
  transition: all 0.3s ease;
  margin-top: 49px;
  @media screen and (max-width: 714px) {
    width: ${(props) => (props.isSideBarActive ? '100%' : '0%')} ;
  }
  overflow: hidden;
  height: 100%;
  z-index: 1;
  width: ${(props) => (props.isExpanded ? '300px' : '96px')};
`;

const SideBarHeader = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  display: flex;
  align-items: center;
  padding: ${(props) => (props.isExpanded ? '15px' : '25px')};
  margin-top: 20px;
  justify-content: ${(props) => (props.isExpanded ? 'space-between' : 'flex-end')};
  @media screen and (max-width: 768px) {
    justify-content: space-between;
  }
`;

const HeaderText = styled('div')`
  width: auto;
  transition: all 0.3s ease;
  font-size: ${(props) => (!props.isExpanded ? '12px' : '17px')};
  margin-right: ${(props) => (!props.isExpanded && '5px')};
  // opacity: ${(props) => (props.isExpanded ? 1 : 0)};
  @media screen and (max-width: 768px) {
    opacity: 1;
    font-size: ${(props) => (!props.isExpanded ? '16px' : '24px')};
  }
`;

const MenuTab = styled('div')`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${'' /* padding: 15px; */}
  padding: ${(props) => (props.isExpanded ? '15px' : '0px')};

  gap: 16px;
  height: ${(props) => (props.isExpanded ? '120px' : '80px')};
  width: ${(props) => (props.isExpanded ? '100%' : '80px')};  
  background: ${(props) => (
    props.isAddon
      ? (props.unlocked ? 'white' : 'linear-gradient(270deg, #6A82FB 0%, #FC5C7D 100%)')
      : !props.activeTab ? !props.unlocked ? 'linear-gradient(90deg, #667EEA 0%, #764BA2 100%)' : 'white' : 'white'
  )};
  border-radius: 8px;
  color: ${(props) => (!props.activeTab ? props.unlocked ? '#9E9E9E' : 'white' : '#9E9E9E')};
  position: relative;
  border: ${(props) => (props.activeTab ? '1px solid #0DCC8A' : props.unlocked ? '#9E9E9E' : 'white')};
  box-shadow: ${BoxShadow};
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const ShimmerMenuTab = styled('div')`
  margin-top: 15px;
  width: 100%;
  height: 120px;
  border-radius: 8px;
  box-shadow: ${BoxShadow};
`;

const TabTitle = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  // font-weight: ${(props) => (props.isExpanded ? '400' : '700')};
  font-size: 14px;
  // font-size: ${(props) => (props.isExpanded ? '14px' : '11px')};
  line-height: 20px;
  color: ${(props) => (props.activeTab ? 'black' : props.unlocked ? 'black' : 'white')};
  transition: all 0.3s ease;
  display: ${(props) => (props.isExpanded ? 'flex' : 'none')};
  // display: flex;
`;

const TabDescription = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 5px;
  display: ${(props) => (props.isExpanded ? 'flex' : 'none')};
`;

const TitleAndDescription = styled('div')`
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled('div')`
  margin-left: ${(props) => (props.isSideBarExpanded ? '0px' : '21px')};
  transition: all 0.3s ease;
`;

const ModalButtons = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 50px;
  padding: ${(props) => (props.isSideBarExpanded ? '15px' : '5px')};

`;

const SpacingWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  background: white;
`;

const LockContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.isExpanded ? '45px' : '20px')};
  width: ${(props) => (props.isExpanded ? '45px' : '20px')};
  background: #444444;
  position: absolute;
  right: ${(props) => (props.isExpanded ? '-10px' : '-5px')};
  top: -10px;
  border-radius: 50%;
  cursor: pointer;
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  transition: opacity 0.3s ease;
`;

const MenuAndButtons = styled('div')`
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 125px;
  overflow: auto;
  padding-bottom: 50px;
  flex: 1 auto;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: ${(props) => (props.isSideBarActive ? '100%' : '0%')} ; */
  }
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: ${(props) => (props.isExpanded ? 'hidden' : 'clip')};
  overflow-y: auto;
`;

const UpgradeButton = styled('div')`
  display: ${(props) => (props.isActive ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-radius: 10000px;
  height: 40px;
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  color: white;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => (props.isExpanded ? '12px' : '10px')};
  line-height: 20px;
  cursor: ${(props) => (props.isActive ? 'pointer' : 'default')};
  background: linear-gradient(90deg, rgba(247,183,51,1) 0%, rgba(252,74,26,1) 100%);
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`;

const BookModalButton = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10000px;
  height: 40px;
  width: 100%;
  margin-bottom: 5px;
  color: white;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => (props.isExpanded ? '12px' : '10px')};
  cursor: pointer;
  border-image: linear-gradient(45deg, purple, orange) 1;
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(90deg, rgba(247, 183, 51, 1), rgba(252, 74, 26, 1)) border-box;
  border: 3px solid transparent;
  color: #FF8533;

  &:hover {
    opacity: 0.7;
  }
`;

const ChevronContainer = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Chevron = styled('div')`
  transform: ${(props) => (props.isRotated ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Badge = styled.button`
  background: ${(props) => (props.isCore
    ? 'linear-gradient(#BBB2EF, #BBB2EF) padding-box, linear-gradient(to right, #764BA2 50%, #667EEA 100%) border-box'
    : 'linear-gradient(white, white) padding-box, linear-gradient(to left, #6A82FB 50%, #FC5C7D 100%) border-box')};

  border-radius: 50em;
  border: 1px solid transparent;
  position: absolute;
  left: 12px;
  top: -8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  color: #444;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Nunito Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 16.5px */
  padding: 2px 12px 0px 12px;
  display: ${(props) => (props.isExpanded ? 'block' : 'none')};
`;

const SideBar = ({
  isSideBarActive,
  setIsSideBarActive,
  flags,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const subscriptionStatus = useSelector((state) => state.sportsScience.data.subscriptionStatus);
  const isPackageLoading = useSelector((state) => state.sportsScience.ui.isPackageLoading);
  const addedPackage = useSelector((state) => state.sportsScience.data.addedPackage);
  const isSideBarExpanded = useSelector((state) => state.sportsScience.data.isSideBarExpanded);
  const [activeTab, setActiveTab] = useState('');
  const getPackages = () => [
    {
      title: 'Load Monitoring Dashboard',
      core: true,
      unlocked: false,
      sections: [
        {
          name: 'Monitoring',
          description: 'Generate insights based on athlete workload and self-reported questionnaire data.',
          icon: 'chart-line',
        },
        {
          name: 'Readiness',
          description: 'Determine athlete readiness by viewing data regarding sleep, fatigue, motivation, stress levels, and more.',
          icon: 'heart-rate',
        },
        {
          name: 'Recovery',
          description: 'See how your athletes are recovering from competition and training sessions.',
          icon: 'circuit',
        },
      ],

      videoUrl: 'https://www.youtube.com/watch?v=soAvDS3obS0',
      description: 'Compare athlete weight room load to questionnaire responses',
      icon: 'chart-line',
      activeTab: '/sports-science/load-monitoring-dashboard',

    },
    {
      title: 'Sport Coach Dashboard',
      core: true,
      unlocked: false,
      sections: [
        {
          name: 'Collaborate',
          description: 'Share individual athlete workout data with Sport Coaches so they can see how their athletes are progressing.',
          icon: 'fist-bump-full-together',
        },
        {
          name: 'Monitor Progress',
          description: 'Sport & Performance coaches can easily see when athletes hit a new max or personal best.',
          icon: 'clipboard',
        },
        {
          name: 'Team Trends',
          description: 'See how each athlete is developing compared to the rest of the team.',
          icon: 'group',
        },
      ],
      videoUrl: 'https://www.youtube.com/watch?v=0SAo4JLOY4Q',
      description: 'Share individual athlete workout data with Sport Coaches',
      icon: 'clipboard',
      activeTab: '/sports-science/sport-coach-dashboard',
    },
    ...(flags.habitTracking ? [{
      title: 'Habit Tracking',
      core: true,
      unlocked: false,
      sections: [
        {
          name: 'Collaborate',
          description: 'Share individual athlete workout data with Sport Coaches so they can see how their athletes are progressing.',
          icon: 'fist-bump-full-together',
        },
        {
          name: 'Monitor Progress',
          description: 'Sport & Performance coaches can easily see when athletes hit a new max or personal best.',
          icon: 'clipboard',
        },
        {
          name: 'Team Trends',
          description: 'See how each athlete is developing compared to the rest of the team.',
          icon: 'group',
        },
      ],
      videoUrl: 'https://www.youtube.com/watch?v=0SAo4JLOY4Q',
      description: 'Create, Assign, and Monitor daily and weekly habits for athletes',
      icon: 'checkmark',
      activeTab: '/sports-science/habit-tracking',
    }] : []),
    ...(flags.bodyHeatMaps ? [
      {
        title: 'Recovery Radar',
        core: true,
        unlocked: false,
        // UPDATE STILL NEEDED FOR SECTIONS
        sections: [
          {
            name: 'Collaborate',
            description: 'Share individual athlete workout data with Sport Coaches so they can see how their athletes are progressing.',
            icon: 'fist-bump-full-together',
          },
          {
            name: 'Monitor Progress',
            description: 'Sport & Performance coaches can easily see when athletes hit a new max or personal best.',
            icon: 'clipboard',
          },
          {
            name: 'Team Trends',
            description: 'See how each athlete is developing compared to the rest of the team.',
            icon: 'group',
          },
        ],
        // UPDATE STILL NEEDED FOR VIDEOURL
        videoUrl: 'https://www.youtube.com/watch?v=0SAo4JLOY4Q',
        description: 'Monitor athletes experiencing any pain or soreness',
        icon: 'target',
        activeTab: '/sports-science/recovery-radar',
      },
    ] : []),
    {
      title: 'Force Plate Dashboard',
      addOnId: 1,
      core: false,
      unlocked: false,
      sections: [
        {
          name: 'Integrated',
          description: 'Using imported data from our 2 partners - Hawkin Dynamic and VALD - our Force Plate Module rethinks how to display external data.',
          icon: 'force-plate',
        },
        {
          name: 'Summarize',
          description: 'This module takes the simple approach to viewing Force Plate data by reading fewer metrics and highlighting unique values and trends.',
          icon: 'file',
        },
        {
          name: 'Groups vs. Individual',
          description: 'Visualize force-velocity trends and profiles for an individual or a team, or position group - with trend analysis.',
          icon: 'conversation-group',
        },
      ],
      videoUrl: 'https://www.youtube.com/watch?v=2m08YQljx30',
      description: 'Monitor group and athlete data collected with force plates',
      icon: 'force-plate',
      activeTab: '/sports-science/force-plate-dashboard',
    },
  ];

  const [packages, setPackages] = useState(getPackages());

  useEffect(() => {
    setPackages(getPackages());
  }, [flags]);

  useEffect(() => {
    const { pathname } = location;

    // Defines a mapping of routes to their required subscription types.
    const allowedPackagePaths = {
      '/sports-science/wearables-dashboard': { requiredSubscription: 'core' },
      '/sports-science/load-monitoring-dashboard': { requiredSubscription: 'core' },
      '/sports-science/sport-coach-dashboard': { requiredSubscription: 'core' },
      '/sports-science/force-plate-dashboard': { requiredSubscription: 1 }, // the addon id
    };

    if (flags.habitTracking) {
      allowedPackagePaths['/sports-science/habit-tracking'] = { requiredSubscription: 'core' };
    }

    if (flags.bodyHeatMaps) {
      allowedPackagePaths['/sports-science/recovery-radar'] = { requiredSubscription: 'core' };
    }

    // Retrieves the required subscription type for the current pathname.
    const requiredSubscription = allowedPackagePaths[pathname]?.requiredSubscription;
    let hasRequiredSubscription = false;

    if (requiredSubscription === 'core') {
    // For core packages, checks if the user is subscribed to the core package.
      hasRequiredSubscription = subscriptionStatus?.packageSubscriptionStatus?.isSubscribed === true;
    } else if (typeof requiredSubscription === 'number') {
    // For add-on packages, checks if the user is subscribed to the required add-on package.
      hasRequiredSubscription = subscriptionStatus?.addOnsSubscriptionStatus.some((addon) => addon.addOnId === requiredSubscription && addon.isSubscribed);
    }

    if (hasRequiredSubscription) {
      setActiveTab(pathname);
    } else {
      // If the user does not have the required subscription, redirects to the wearables dashboard.
      dispatch(push('/sports-science/wearables-dashboard'));
    }
  }, [subscriptionStatus, currentUser]); // Dependencies: subscriptionStatus and currentUser to re-run the hook when these values change.

  useEffect(() => {
    if (Object.keys(currentUser).length) {
      dispatch(fetchSubscriptionStatus(currentUser.accountCode, 1));
    }
  }, [currentUser, addedPackage]);

  // Function to compare two arrays of packages
  function arePackagesEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].unlocked !== arr2[i].unlocked) {
        return false;
      }
    }
    return true;
  }

  const updatedPackages = useMemo(() => {
    if (!subscriptionStatus || !subscriptionStatus.packageSubscriptionStatus) {
      return packages;
    }

    const isCorePackageSubscribed = subscriptionStatus.packageSubscriptionStatus.isSubscribed;

    const result = packages.map((packageItem) => {
      let unlocked = false;
      if (packageItem.core && isCorePackageSubscribed) {
        unlocked = true;
      } else if (!packageItem.core) {
        const packageId = packageItem.addOnId;
        // Find the add-on subscription status by addOnId
        const addOnSubscription = subscriptionStatus.addOnsSubscriptionStatus.find((addOn) => addOn.addOnId === packageId);
        const isPackageSubscribed = addOnSubscription?.isSubscribed;
        unlocked = !!isPackageSubscribed;
      }

      return {
        ...packageItem,
        unlocked,
      };
    });

    return result;
  }, [subscriptionStatus, packages]);

  useEffect(() => {
    // Assuming arePackagesEqual is a deep comparison function
    if (!arePackagesEqual(updatedPackages, packages)) {
      setPackages(updatedPackages);
    }
  }, [updatedPackages]);

  // Tracks which module was active when user clicked upgrade
  const handleAmplitudeUpgrade = () => {
    let title;
    switch (activeTab) {
      case '/sports-science/load-monitoring-dashboard':
        title = 'Load Monitoring Dashboard';
        break;
      case '/sports-science/sport-coach-dashboard':
        title = 'Sport Coach Dashboard';
        break;
      case '/sports-science/force-plate-dashboard':
        title = 'Force Plate Dashboard';
        break;
      default:
        title = 'Wearables Dashboard';
    }

    subscriptionFlowTracker('Upgrade Sidebar Started', 'none', title);
  };

  // Tracks which module was active when user booked a demo
  const handleAmplitudeDemo = () => {
    let title;
    let core;
    let addOn;
    switch (activeTab) {
      case '/sports-science/load-monitoring-dashboard':
        addOn = false;
        core = true;
        title = 'Load Monitoring Dashboard';
        break;
      case '/sports-science/sport-coach-dashboard':
        addOn = false;
        core = true;
        title = 'Sport Coach Dashboard';
        break;
      case '/sports-science/force-plate-dashboard':
        addOn = true;
        core = false;
        title = 'Force Plate Dashboard';
        break;
      default:
        addOn = false;
        core = false;
        title = 'Wearables Dashboard';
    }

    const packageData = { title, core, addOn };

    subscriptionFlowTrackerDemo('Sidebar', packageData);
  };

  // Tracks which module/dashboard was opened
  const handleAmplitudeModule = (title) => {
    dashboardTracker(title, null, null);
  };

  // Tracks which module's modal was opened
  const handleAmplitudeModal = (title) => {
    subscriptionFlowTracker('Modal Viewed', title, 'none');
  };

  return (
    <SideBarDiv isSideBarActive={isSideBarActive} isExpanded={isSideBarExpanded}>
      <SpacingWrapper>
        <SideBarHeader isExpanded={isSideBarExpanded}>
          {!isSideBarExpanded ? (
            <div style={{
              width: 18,
            }}
            />
          ) : null}
          <HeaderText isExpanded={isSideBarExpanded}>
            {isSideBarExpanded ? 'Sport Science Hub' : 'Hub'}
          </HeaderText>
          <ChevronContainer onClick={() => {
            dispatch(setIsSideBarExpandedGlobal(!isSideBarExpanded));
          }}
          >
            <Chevron isRotated={isSideBarExpanded}>
              <IcomoonReact
                iconSet={iconSet}
                size={isSideBarExpanded ? 18 : 12}
                icon='altrightarrow'
                color='black'
              />
            </Chevron>
          </ChevronContainer>
        </SideBarHeader>
        <MenuAndButtons>
          {subscriptionStatus && !isPackageLoading ? (
            <MenuTab
              isExpanded={isSideBarExpanded}
              activeTab={activeTab === '/sports-science/wearables-dashboard'}
              unlocked
              onClick={() => {
                handleAmplitudeModule('Wearables Dashboard');
                dispatch(push('/sports-science/wearables-dashboard'));
                setIsSideBarActive(false);
              }}
            >
              <IconContainer
                activeTab={activeTab === '/sports-science/wearables-dashboard'}
                isSideBarExpanded={isSideBarExpanded}
              >
                <IcomoonReact
                  iconSet={iconSet}
                  size={40}
                  icon='watch-heart-rate'
                  color='#444444'
                />
              </IconContainer>
              <TitleAndDescription>
                <TabTitle
                  isExpanded={isSideBarExpanded}
                  unlocked
                  activeTab={activeTab === '/sports-science/wearables-dashboard'}
                >
                  Wearables Dashboard
                </TabTitle>
                <TabDescription isExpanded={isSideBarExpanded}>
                  Quickly review biometric data collected by athlete wearables
                </TabDescription>
              </TitleAndDescription>
            </MenuTab>
          ) : <Shimmer><ShimmerMenuTab /></Shimmer>}
          {subscriptionStatus && !isPackageLoading ? (
            packages.map((packageItem) => (
              <MenuTab
                key={packageItem.id}
                isExpanded={isSideBarExpanded}
                activeTab={activeTab === packageItem.activeTab}
                unlocked={packageItem.unlocked}
                isAddon={packageItem.core === false}
                onClick={() => {
                  if (!packageItem.unlocked) {
                    handleAmplitudeModal(packageItem.title);
                    dispatch(setIsUpgradeModalShowing(true));
                    dispatch(setSelectedPackage(packageItem));
                    dispatch(fetchSubscriptionPackage(currentUser.accountCode, 1, !packageItem.core ? packageItem.addOnId : null));
                  } else {
                    handleAmplitudeModule(packageItem.title);
                    dispatch(push(packageItem.activeTab));
                    setIsSideBarActive(false);
                  }
                }}
              >
                <IconContainer
                  activeTab={activeTab === packageItem.activeTab}
                  isSideBarExpanded={isSideBarExpanded}
                >
                  <LockContainer
                    isActive={!packageItem.unlocked}
                    isExpanded={isSideBarExpanded}
                  >
                    <IcomoonReact
                      iconSet={iconSet}
                      size={isSideBarExpanded ? 25 : 12}
                      icon={packageItem.core ? 'lock' : 'star'}
                      color='white'
                    />
                  </LockContainer>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={40}
                    icon={packageItem.icon}
                    color={activeTab === packageItem.activeTab || packageItem.unlocked ? '#444444' : 'white'}
                  />
                </IconContainer>
                <TitleAndDescription>
                  <TabTitle
                    isExpanded={isSideBarExpanded}
                    unlocked={packageItem.unlocked}
                    activeTab={activeTab === packageItem.activeTab}
                  >
                    {packageItem.title}
                  </TabTitle>
                  <TabDescription isExpanded={isSideBarExpanded}>
                    {packageItem.description}
                  </TabDescription>
                </TitleAndDescription>
                {!packageItem?.unlocked && (
                  packageItem.core ? (
                    <Badge
                      isCore
                      isExpanded={isSideBarExpanded}
                    >
                      CORE SUBSCRIPTION
                    </Badge>
                  ) : (
                    <Badge isExpanded={isSideBarExpanded}>
                      ADD ON
                    </Badge>
                  )
                )}
              </MenuTab>
            ))
          ) : <Shimmer><ShimmerMenuTab /></Shimmer>}
        </MenuAndButtons>
        <ModalButtons isSideBarExpanded={isSideBarExpanded}>
          <UpgradeButton
            isExpanded={isSideBarExpanded}
            isActive={!subscriptionStatus?.packageSubscriptionStatus?.isSubscribed}
            onClick={() => {
              if (currentUser?.userSettings?.billing === 0) { // billing access is disabled
                window.open(`${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/billing`);
              } else if (!subscriptionStatus?.isSubscribed) {
                handleAmplitudeUpgrade();
                dispatch(setIsUpgradeModalShowing(true));
                dispatch(setSelectedPackage(corePackage));
                dispatch(fetchSubscriptionPackage(currentUser.accountCode, 1));
              } else {
                dispatch(push('/sports-science/wearables-dashboard'));
                setIsSideBarActive(false);
              }
            }}
          >
            UPGRADE
          </UpgradeButton>
          <BookModalButton
            isExpanded={isSideBarExpanded}
            onClick={() => {
              handleAmplitudeDemo();
              openPopupWidget({
                url: 'https://calendly.com/teambuildr/15-minute-sports-science-hub',
              });
            }}
          >
            BOOK A DEMO
          </BookModalButton>
        </ModalButtons>
      </SpacingWrapper>
    </SideBarDiv>
  );
};

SideBar.propTypes = {
  isSideBarActive: PropTypes.bool.isRequired,
  setIsSideBarActive: PropTypes.func.isRequired,
};

export default withLDConsumer()(SideBar);
