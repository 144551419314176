/* eslint-disable max-len */
import React, { useRef, useEffect, useState } from 'react';
import { batch, useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import Checkbox from '@material-ui/core/Checkbox';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';
import Button from '../../../../shared/components/Button/Button';
import TBTooltip from '../../../../shared/components/TBTooltip/TBTooltip';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import { fetchHabitsForAccount } from '../../ducks/habitTrackingActions';

const FilterAndButtonContainer = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
`;

const Badge = styled('span')`
  position: absolute;
  top: -4px;
  right: -12px;
  border-radius: 100px;
  background-color: #444444;
  color: #FFFFFF;
  width: 20px;
  height: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: 'Nunito Sans';
  font-size: 11px;
  font-weight: bold;
`;

const FilterContainer = styled('div')`
  display: flex;
  position: absolute;
  top: 45px;
  background-color: #FFFFFF;
  z-index: 1;
  flex-direction: column;
  width: 280px;
  max-height: 600px;
  border-radius: 4px;
  padding: 16px;
  box-shadow: ${BoxShadow};
  gap: 10px;
`;
const ScrollContainer = styled('div')`
overflow-y: auto;
`;

const Filters = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
`;

const FilterOption = styled('div')`
  display: flex;
  align-items: center;
  margin-left: -10px;

  h3 {
    font-weight: normal;
  }
  .MuiCheckbox-root:hover,
  .MuiCheckbox-root:focus,
  .MuiCheckbox-root:active,
  .MuiCheckbox-root.Mui-checked:hover,
  .MuiCheckbox-root.Mui-checked:focus,
  .MuiCheckbox-root.Mui-checked:active {
    background-color: transparent !important;
  }
  
`;

const FilterBottom = styled('div')`
  display: flex;
  gap: 15px
`;
const SearchWrapper = styled('div')`
  display: flex;
  gap: 20px;
`;

const SearchAndIcon = styled('div')`
  display: flex;
  align-items: center;
  border: 1px solid #EEEEEE;
  border-radius: 50px;
  background: white;
  overflow: hidden;
  width: 100%;
`;

const SearchIconContainer = styled('div')`
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
  height: 40px;
`;

const SearchContainer = styled('input')`
  display: flex;
  border: none;
  background: none;
  padding-left: 5px;
  width: 100%;
  min-height: 40x;
`;

const LandingPageFilter = ({ isContentLoading }) => {
  const dispatch = useDispatch();
  const globalHabitFilters = useSelector((state) => state.habitTracking.data.globalHabitFilters);
  const reportableGroups = useSelector((state) => state.habitTracking.data.reportableGroups);
  const categoriesForAccount = useSelector((state) => state.habitTracking.data.categories);

  const getInitialStatusStateValue = () => {
    if (globalHabitFilters?.status === 2) {
      return ['Inactive'];
    }
    if (globalHabitFilters?.status === 1) {
      return ['Active'];
    }
    if (globalHabitFilters?.status === null || globalHabitFilters?.status === undefined) {
      return ['Active', 'Inactive'];
    }

    return [];
  };

  const [isFilterComponentOpen, setisFilterComponentOpen] = useState(false);
  const [groupFilters, setGroupFilters] = useState(globalHabitFilters.groupIds ? reportableGroups.filter((group) => globalHabitFilters.groupIds.includes(group.id)) : []);
  const [categoryFilters, setCategoryFilters] = useState(globalHabitFilters.categoryIds ? categoriesForAccount.filter((category) => globalHabitFilters.categoryIds.includes(category.id)) : []);
  const [statusFilter, setStatusFilter] = useState(getInitialStatusStateValue());
  const selectedFilters = [...groupFilters, ...categoryFilters, ...statusFilter];
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const groupOptions = reportableGroups;
  const searchedGroupOptions = groupOptions?.filter((group) => group?.name.toLowerCase().includes(searchTerm.toLowerCase()));
  const categoryOptions = categoriesForAccount;
  const isCategoriesLoading = useSelector((state) => state.habitTracking.ui.isCategoriesLoading);
  const searchedCategoryOptions = categoryOptions?.filter((category) => category?.name.toLowerCase().includes(searchTerm.toLowerCase()));
  const statusOptions = ['Active', 'Inactive'];
  const searchedStatusOptions = statusOptions?.filter((status) => status?.toLowerCase().includes(searchTerm.toLowerCase()));
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;
  // const habitsForAccount = useSelector((state) => state.habitTracking.data.habits);
  const containerRef = useRef(null);

  const resetFiltersToGlobalFilters = () => {
    setGroupFilters(globalHabitFilters.groupIds ? reportableGroups.filter((group) => globalHabitFilters.groupIds.includes(group.id)) : []);
    setCategoryFilters(globalHabitFilters.categoryIds ? categoriesForAccount.filter((category) => globalHabitFilters.categoryIds.includes(category.id)) : []);
    setStatusFilter(getInitialStatusStateValue());
  };

  /** always update filters to relfect global filters when they change */
  useEffect(() => {
    resetFiltersToGlobalFilters();
  }, [globalHabitFilters]);

  const handleFilter = () => {
    setisFilterComponentOpen(!isFilterComponentOpen);
  };

  const getStatus = () => {
    if (statusFilter.length === 2) {
      return null;
    }
    if (statusFilter.length === 1 && statusFilter[0] === 'Active') {
      return 1;
    }
    if (statusFilter.length === 1 && statusFilter[0] === 'Inactive') {
      return 2;
    }
    return null;
  };

  const handleUpdate = () => {
    batch(() => {
      setisFilterComponentOpen(false);
      /** set local state of status back to default if user unclicked both */
      if (!statusFilter.length) {
        setStatusFilter(['Active']);
      }
      const categoryIds = categoryFilters.map((category) => category.id)?.length ? categoryFilters.map((category) => category.id) : null;
      const groupIds = groupFilters.map((group) => group.id)?.length ? groupFilters.map((group) => group.id) : null;
      const status = getStatus();
      dispatch(fetchHabitsForAccount(accountCode, categoryIds, groupIds, status));
    });
  };

  const handleStatusChange = (status) => {
    if (statusFilter.includes(status)) {
      const newStatus = [...statusFilter];
      const index = newStatus.indexOf(status);
      if (index > -1) {
        newStatus.splice(index, 1);
        setStatusFilter(newStatus);
      }
    } else {
      const newStatus = [...statusFilter];
      newStatus.push(status);
      setStatusFilter(newStatus);
    }
  };

  const handleGroupChange = (group) => {
    const groupIndex = groupFilters.findIndex((filter) => filter.id === group.id);

    if (groupIndex > -1) {
      /** Group is already in the filters, remove it */
      const newGroupFilters = [...groupFilters];
      newGroupFilters.splice(groupIndex, 1);
      setGroupFilters(newGroupFilters);
    } else {
      /* Group is not in the filters, add it */
      setGroupFilters([...groupFilters, group]);
    }
  };

  const handleCategoryChange = (category) => {
    const categoryIndex = categoryFilters.findIndex((filter) => filter.id === category.id);

    if (categoryIndex > -1) {
      /** category is already in the filters, remove it */
      const newCategoryFilters = [...categoryFilters];
      newCategoryFilters.splice(categoryIndex, 1);
      setCategoryFilters(newCategoryFilters);
    } else {
      /** category is not in the filters, add it */
      setCategoryFilters([...categoryFilters, category]);
    }
  };

  const handleClear = () => {
    setCategoryFilters([]);
    setGroupFilters([]);
    setStatusFilter(['Active']);
  };
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      resetFiltersToGlobalFilters();
      setisFilterComponentOpen(false);
    }
  };

  useEffect(() => {
    if (isFilterComponentOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFilterComponentOpen]);

  return (
    <FilterAndButtonContainer>
      <IcomoonReact
        iconSet={iconSet}
        size={24}
        icon='filter'
        color='#9E9E9E'
        style={{ cursor: 'pointer' }}
        onClick={(isContentLoading || isCategoriesLoading) ? null : handleFilter}
      />
      {selectedFilters.length > 0 && (
        <Badge>{selectedFilters.length}</Badge>
      )}
      {isFilterComponentOpen && (
        <FilterContainer ref={containerRef}>
          <SearchWrapper>
            <SearchAndIcon id='search-container' onClick={() => setIsSearchFocused(true)} isFocused={isSearchFocused}>
              <SearchIconContainer id='search-icon-container'>
                <IcomoonReact
                  iconSet={iconSet}
                  size={20}
                  icon='magnifying-glass-alt'
                  color='#9E9E9E'
                />
              </SearchIconContainer>
              <SearchContainer
                placeholder='Search'
                id='search-container-input'
                value={searchTerm}
                onFocus={() => setIsSearchFocused(true)}
                onChange={(inputValue) => {
                  setSearchTerm(inputValue.target.value);
                }}
              />
            </SearchAndIcon>
          </SearchWrapper>
          <ScrollContainer>
            {!searchedGroupOptions?.length && !searchedCategoryOptions?.length && !searchedStatusOptions?.length && (
              <Text style={{ textAlign: 'center', color: 'grey' }}>No options</Text>
            )}
            {!!searchedStatusOptions?.length && (
            <Filters>
              Status
              {searchedStatusOptions.map(
                (option) => (
                  <FilterOption key={option}>
                    <Checkbox
                      disableRipple
                      color='default'
                      checked={statusFilter.includes(option)}
                      onChange={() => handleStatusChange(option)}
                    />
                    <TBTooltip
                      textToDisplay={option}
                      textToHover={option}
                      fontSize='16px'
                      textContainerPadding='0px'
                      textContainerMaxWidth='200px'
                    />
                  </FilterOption>
                ),
              )}
            </Filters>
            )}
            {!!searchedCategoryOptions?.length && (
            <Filters>
              Categories
              {searchedCategoryOptions.map(
                (option) => (
                  <FilterOption key={option?.id}>
                    <Checkbox
                      disableRipple
                      color='default'
                      checked={categoryFilters.some((filter) => filter.id === option.id)}
                      onChange={() => handleCategoryChange(option)}
                    />
                    <TBTooltip
                      textToDisplay={option?.name}
                      textToHover={option?.name}
                      fontSize='16px'
                      textContainerPadding='0px'
                      textContainerMaxWidth='200px'
                    />
                  </FilterOption>
                ),
              )}
            </Filters>
            )}
            {!!searchedGroupOptions?.length && (
            <Filters>
              Groups
              {searchedGroupOptions?.map(
                (option) => (
                  <FilterOption key={option?.id}>
                    <Checkbox
                      disableRipple
                      color='default'
                      checked={groupFilters.some((filter) => filter.id === option.id)}
                      onChange={() => handleGroupChange(option)}
                    />
                    <TBTooltip
                      textToDisplay={option?.name}
                      textToHover={option?.name}
                      fontSize='16px'
                      textContainerPadding='0px'
                      textContainerMaxWidth='200px'
                    />
                  </FilterOption>
                ),
              )}
            </Filters>
            )}
          </ScrollContainer>
          <FilterBottom>
            <Button
              fullWidth
              cta='Clear'
              rounded
              onClick={handleClear}
            />
            <Button
              customColor='black'
              primary
              customTextColor='#FFFFFF'
              fullWidth
              cta='Update'
              rounded
              onClick={handleUpdate}
            />
          </FilterBottom>
        </FilterContainer>
      )}
    </FilterAndButtonContainer>
  );
};

export default LandingPageFilter;

LandingPageFilter.propTypes = {
  isContentLoading: PropTypes.bool,
};
LandingPageFilter.defaultProps = {
  isContentLoading: false,
};
