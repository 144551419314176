/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { clearReport, setActiveReport } from '../ducks/reportingActions';
import Button from '../../../shared/components/Button/Button';
import useMaxReportModal from './hooks/useMaxReportModal';
import { reportInteractionTracker } from '../../../shared/utils/amplitudeHelper';

const ActiveSidebarContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px;
  button {
    background: transparent;
    margin-bottom: 15px;
    svg {
      path {
        fill: rgb(68, 68, 68);
      }
    }
  }

  @media screen and (max-width: 540px) {
    flex-direction: row;
    padding: 0px;

    button {
      margin-bottom: 0px;
      margin-left: 16px;
      &.download {
        margin-right: 16px;
      }
    }
  }
`;

const TopButtonContainer = styled('div')`
  display: flex;
  justify-self: flex-start;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 540px) {
    width: 50%;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .arrow-button {
    transform: rotate(-90deg);
  }
  @media screen and (min-width: 768px) {
    svg {
      height: 22px;
      width: 22px;
    }
  }

`;

const ActiveReportSidebar = ({
  edit,
}) => {
  const {
    handleSetIsModalOpen,
  } = useMaxReportModal();

  const dispatch = useDispatch();

  const activeReport = useSelector((state) => state.reporting.ui.activeReport);

  const handleClearReport = () => {
    dispatch(clearReport());
  };

  const handleFilterClick = () => {
    if (activeReport === 'max') {
      handleSetIsModalOpen(true, 'main');
    }
    if (activeReport === 'questionnaire') {
      handleSetIsModalOpen(true, 'questionnaire');
    }
    if (activeReport === 'workout_results') {
      handleSetIsModalOpen(true, 'workoutResultsReport');
    }
    if (activeReport === 'opt_out_notes') {
      handleSetIsModalOpen(true, 'optOutNotesReport');
    }

    // Amplitude tracking
    reportInteractionTracker('Edit Clicked', activeReport);
  };

  const handleBackClick = () => {
    // Since there is no modal for the activity report selection,
    // simply clear the report if the filter icon is clicked
    dispatch(setActiveReport(''));
    handleClearReport();
  };

  const handleDownloadClick = () => {
    handleSetIsModalOpen(
      true,
      activeReport === 'activity' ? 'downloadActivityReport'
        : activeReport === 'questionnaire' ? 'downloadQuestionnaireReport'
          : activeReport === 'evaluation' ? 'downloadEvaluationReport'
            : activeReport === 'opt_out_notes' ? 'downloadOptOutNotesReport'
              : activeReport === 'workout_results' ? 'downloadResultsReport'
                : 'downloadMaxReport',
    );

    // Amplitude tracker.
    reportInteractionTracker('Download Clicked', activeReport);
  };

  return (
    <ActiveSidebarContainer>
      <TopButtonContainer>
        <Button
          className='arrow-button'
          icon='alt-up-arrow'
          iconOnly
          square
          onClick={handleBackClick}
        />
        {edit ? (
          <Button
            icon='pencil'
            iconOnly
            square
            onClick={handleFilterClick}
          />
        ) : null}
        <Button
          className='download'
          icon='download'
          iconOnly
          square
          onClick={handleDownloadClick}
        />
      </TopButtonContainer>
    </ActiveSidebarContainer>
  );
};

export default ActiveReportSidebar;
