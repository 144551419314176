import React from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import {
  setDuplicateModalShowing,
  setSaveDashboardModal,
  setShareDashboardModal,
  setTriOptionModal,
} from '../../ducks/sportCoachDashboardActions';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const ModalContainer = styled('div')`
  height: 65%;
  min-width: 300px;
  z-index: 1004;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  gap: 30px;
  max-width: 1400px;
  position: absolute; 
  left: 50%;
  top: 50%;
  right: 50%;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  max-height: 650px;
  overflow: auto;
  @media only screen and (max-width : 768px) {
    flex-direction: column;
  }
`;

const InteriorContainer = styled('div')`
  background: white;
  box-shadow: ${BoxShadow};
  border-radius: 24px;
  height: 80%;
  width: 30%;
  min-width: 275px;
  max-height: 350px;
  position: relative;
  cursor: pointer;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width : 768px) {
    margin-top: 20px;
  }
  padding: 40px;
`;

const MainText = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 23px;;
  color: #444444;
  margin-top: 30px;
`;

const SubText = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 16px;;
  color: #9E9E9E;
  margin-top: 10px;
`;

const TriOptionModal = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const template = useSelector((state) => state.sportCoachDashboard.data.template);
  const triOptionModalShowing = useSelector(
    (state) => state.sportCoachDashboard.ui.triOptionModalShowing,
  );

  const isOwner = (currentUser?.id === template?.createdBy) || !template;

  const dispatch = useDispatch();

  const handleDuplicateClick = () => {
    // KPI tracking
    dashboardTracker('Sport Coach Dashboard', 'Dashboard Options Menu', 'Duplicate dashboard clicked');
    batch(() => {
      dispatch(setTriOptionModal(false));
      dispatch(setDuplicateModalShowing(true));
    });
  };

  const handleSaveClick = () => {
    // KPI tracking
    dashboardTracker('Sport Coach Dashboard', 'Dashboard Options Menu', 'Save dashboard clicked');
    batch(() => {
      dispatch(setTriOptionModal(false));
      dispatch(setSaveDashboardModal(true));
    });
  };

  const handleShareClick = () => {
    // KPI tracking
    dashboardTracker('Sport Coach Dashboard', 'Dashboard Options Menu', 'Share dashboard clicked');
    batch(() => {
      dispatch(setTriOptionModal(false));
      dispatch(setShareDashboardModal(true));
    });
  };

  return (
    <ModalContainer
      onClick={() => dispatch(setTriOptionModal(false))}
      isActive={triOptionModalShowing}
    >
      {isOwner ? (
        <>
          {template && (
          <InteriorContainer onClick={handleDuplicateClick}>
            <IcomoonReact
              iconSet={iconSet}
              size={65}
              icon='copy'
              color='#444444'
            />
            <MainText>
              Duplicate Dashboard
            </MainText>
            <SubText>
              Create a copy of this dashboard to edit, save and share.
            </SubText>
          </InteriorContainer>
          )}
          <InteriorContainer onClick={handleSaveClick}>
            <IcomoonReact
              iconSet={iconSet}
              size={65}
              icon='save'
              color='#444444'
            />
            <MainText>
              Save Dashboard
            </MainText>
            <SubText>
              Save the exercises selected in this dashboard
              to allow any coaches with a TeamBuildr account in your organization to access.
            </SubText>
          </InteriorContainer>
          <InteriorContainer onClick={handleShareClick}>
            <IcomoonReact
              iconSet={iconSet}
              size={65}
              icon='share'
              color='#444444'
            />
            <MainText>
              Share Dashboard
            </MainText>
            <SubText>
              Send a custom ‘View Only’ version of this dashboard
              to coaches and stakeholders. This link will expire in 30 days.
            </SubText>
          </InteriorContainer>
        </>
      ) : (
        <InteriorContainer onClick={handleDuplicateClick}>
          <IcomoonReact
            iconSet={iconSet}
            size={65}
            icon='copy'
            color='#444444'
          />
          <MainText>
            Duplicate Dashboard
          </MainText>
          <SubText>
            Create a copy of this dashboard to edit, save and share.
          </SubText>
        </InteriorContainer>
      )}
    </ModalContainer>
  );
};

TriOptionModal.propTypes = {

};

export default TriOptionModal;
