import { track } from '@amplitude/analytics-browser';

// Utility function to format event names that are snakecase.
const snakeToTitleCase = (snakeStr) => snakeStr
  .split('_')
  .map((word) => {
    // Only capitalize the first letter if the rest of the word is lowercase
    if (word === word.toLowerCase() || word === word.toUpperCase()) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    return word;
  })
  .join(' ');

// Global general KPI trackers
export const navigationTracker = (page) => {
  track({
    event_type: 'Navigation - Page Viewed',
    event_properties: {
      nav_item_clicked: page,
      page_title: page,
    },
  });
};

export const navigationTrackerAthlete = (page) => {
  track({
    event_type: 'Athlete Navigation - Page Viewed',
    event_properties: {
      nav_item_clicked: page,
      page_title: page,
    },
  });
};

// Sport Science Hub KPI Trackers
export const dashboardTracker = (dashboard, feature, description) => {
  if (feature) {
    track({
      event_type: 'Feature Engagement - Feature Clicked',
      event_properties: {
        dashboard_name: dashboard,
        feature_name: feature,
        description,
      },
    });
  } else {
    track({
      event_type: 'Feature Engagement - Dashboard Viewed',
      event_properties: {
        module_name: dashboard,
      },
    });
  }
};

export const subscriptionFlowTracker = (action, modal, activeModule) => {
  track({
    event_type: `Subscription Flow - ${action}`,
    event_properties: {
      modal_name: modal,
      active_module: activeModule,
    },
  });
};

export const subscriptionFlowTrackerDemo = (action, packageData) => {
  track({
    event_type: `Subscription Flow - Demo Clicked ${action}`,
    event_properties: {
      modal_name: action === 'modal' ? packageData.title : 'none',
      active_module: action === 'modal' ? 'none' : packageData.title,
      is_core: packageData.core,
      is_add_on: packageData.addOn || !packageData.core,
    },
  });
};

// Reporting KPI Trackers
export const reportInteractionTracker = (action, report, error = '', filterLength = 0, filterType = '') => {
  track({
    event_type: `Report Interaction - ${action}`,
    event_properties: {
      report_name: `${snakeToTitleCase(report)} Report`,
      filter_length: filterLength,
      filter_type: filterType,
      error,
    },
  });
};

export const reportingFilterTracker = (action, filterType, error) => {
  track({
    event_type: `Report Filter - ${action}`,
    event_properties: {
      filter_type: snakeToTitleCase(filterType),
      error,
    },
  });
};
