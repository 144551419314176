/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Form } from 'formik';
import Text from '../../../../shared/components/Text/Text';
import SubText from '../../../../shared/components/SubText/SubText';
// import RecentlyUsedReports from './RecentlyUsedReports';
import allQuestionsQuestionnaireView from '../../../../shared/images/All Questions Questionnaire View 1.png';
import singleQuestionnaireView from '../../../../shared/images/Single Questionnaire View 1.png';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  padding-bottom: 40px;
`;

const InnerFolderFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  .formik-form {
    width: 100%;
  }
`;

const TextBoxOuterWrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: ${(props) => (props.containerWidth < 620 && 'wrap')};
  justify-content: space-between;
  gap: 10px;
`;

const TextBoxContainer = styled('div')`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  box-shadow: 0px 8px 16px rgba(48, 49, 51, 0.1);
  border-radius: 10px;
  background: #FFFFFF;
  width: ${(props) => (props.containerWidth < 620 ? '100%' : '50%')};
  min-height: ${(props) => (props.containerWidth > 820 ? '400px' : props.containerWidth > 620 && props.containerWidth < 820 ? '300px' : '')};
  min-width: 150px;
  padding: 15px;
  .photo {
    height: 75%;
    width: ${(props) => (props.containerWidth < 620 ? '120px' : '90%')};
    object-fit: contain;
  }
`;

const FormPage1 = ({
  formProps,
  setActivePage,
  containerWidth,
}) => {
  const onClickTextBox = (selectionType) => {
    if (selectionType === 'selectIndividualQuestions') {
      formProps.setFieldValue('selectionType', 'selectIndividualQuestions');
    }
    if (selectionType === 'selectAllQuestions') {
      formProps.setFieldValue('selectionType', 'selectAllQuestions');
    }
    if (selectionType === 'previouslySavedReport') {
      formProps.setFieldValue('selectionType', 'previouslySavedReport');
    }
    setActivePage(2);
  };

  return (
    <>
      <InnerFolderFormWrapper>
        <Form className='formik-form'>
          <div style={{ marginBottom: 30, marginTop: 5 }}>
            <SubText
              fontWeight='900'
              letterSpacing='normal'
            >
              How would you like to select the questions for this report?
            </SubText>
          </div>
          {/* <TitleWrapper>
            <Text fontSize={containerWidth < 820 ? 16 : 18} fontWeight={400}>How would you like to select the questions for this report?</Text>
          </TitleWrapper> */}
          <TextBoxOuterWrapper containerWidth={containerWidth}>
            <TextBoxContainer
              containerWidth={containerWidth}
              onClick={() => {
                const selectionType = 'selectAllQuestions';
                onClickTextBox(selectionType);
                dashboardTracker('Load Monitoring', 'Select Questionnaire', 'Select questionnaire option');
              }}
            >
              <img src={allQuestionsQuestionnaireView} alt='questionnaire documents' className='photo' />
              <Text fontSize={containerWidth < 820 ? '18px' : '20px'} fontWeight={900}>
                Select Questionnaire
              </Text>
            </TextBoxContainer>
            <TextBoxContainer
              containerWidth={containerWidth}
              onClick={() => {
                const selectionType = 'selectIndividualQuestions';
                onClickTextBox(selectionType);
                dashboardTracker('Load Monitoring', 'Select Individual Questions', 'Select individual questions option');
              }}
            >
              <img src={singleQuestionnaireView} alt='single document' className='photo' />
              <Text fontSize={containerWidth < 820 ? '18px' : '20px'} fontWeight={900}>
                Select Individual Questions
              </Text>
            </TextBoxContainer>
            {/* <TextBoxContainer
              containerWidth={containerWidth}
              onClick={() => {
                const selectionType = 'previouslySavedReport';
                onClickTextBox(selectionType);
                console.log('use previously saved report');
              }}
            >
              <img src={previouslySavedView} alt='saved document' className='photo' />
              <Text fontSize={containerWidth < 820 ? '18px' : '20px'} fontWeight={900}>
                Use Previously Saved Report
              </Text>
            </TextBoxContainer> */}
          </TextBoxOuterWrapper>
          {/* <RecentlyUsedReports containerWidth={containerWidth} formProps={formProps} setActivePage={setActivePage} /> */}
        </Form>
      </InnerFolderFormWrapper>
    </>
  );
};

FormPage1.propTypes = {
  formProps: PropTypes.instanceOf(Object).isRequired,
  setActivePage: PropTypes.func.isRequired,
};

export default FormPage1;
