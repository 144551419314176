import {
  SET_RECOVERY_RADAR_VIEW,
  FETCH_REGION_STATUS_START,
  FETCH_REGION_STATUS_FULFILLED,
  FETCH_REGION_STATUS_REJECTED,
  SET_REGION_STATUS_START,
  SET_REGION_STATUS_FULFILLED,
  SET_REGION_STATUS_REJECTED,
} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';

export const setRecoveryRadarView = (view) => (
  (dispatch) => {
    dispatch({
      payload: view,
      type: SET_RECOVERY_RADAR_VIEW,
    });
  }
);

export const fetchRegionStatusToggle = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_REGION_STATUS_START });
    axiosAuthed.get(`/accounts/${accountCode}/sport-science/settings`)
      .then((response) => {
        dispatch({
          payload: response.data.heatMapTagsEnabled,
          type: FETCH_REGION_STATUS_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          payload: err,
          type: FETCH_REGION_STATUS_REJECTED,
        });
      });
  }
);

export const setRegionStatusToggle = (accountCode, body) => (
  (dispatch) => {
    dispatch({ type: SET_REGION_STATUS_START });
    axiosAuthed.put(`/accounts/${accountCode}/sport-science/settings`, body)
      .then((response) => {
        dispatch({
          payload: response.data.heatMapTagsEnabled,
          type: SET_REGION_STATUS_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          payload: err,
          type: SET_REGION_STATUS_REJECTED,
        });
      });
  }
);
