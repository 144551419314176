/* eslint-disable max-len */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from 'emotion-theming';
import Shimmer from 'react-shimmer-effect';
import styled from '@emotion/styled';
import Text from '../../../../shared/components/Text/Text';
import { setActiveReduxModal } from '../../ducks/wearablesDashboardActions';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const ConnectedAthletesCard = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const isAthleteParticipationLoading = useSelector((state) => state.wearablesDashboard.ui.isAthleteParticipationLoading);
  const AthletesConnected = useSelector((state) => state.wearablesDashboard.data.athleteParticipation?.active);
  const AthletesNotSharing = useSelector((state) => state.wearablesDashboard.data.athleteParticipation?.notSharing);
  // const AthletesNotConnected = useSelector((state) => state.wearablesDashboard.data.athleteParticipation?.notConnected);

  const handleConnectedAthletesClick = () => {
    if (AthletesConnected?.count > 0) {
      // KPI tracking
      dashboardTracker('Wearables Dashboard', 'Connected Athletes', 'Clicked "X Athletes Connected"');
      dispatch(setActiveReduxModal('athletes-connected'));
    }
  };

  const handleAthletesNotSharingClick = () => {
    if (AthletesNotSharing?.count > 0) {
      // KPI tracking
      dashboardTracker('Wearables Dashboard', 'Connected Athletes', 'Clicked "X Athletes Connected Not Sharing"');
      dispatch(setActiveReduxModal('athletes-not-sharing'));
    }
  };

  // const handleAthletesNotConnectedClick = () => {
  //   if (AthletesNotConnected?.count > 0) {
  //     dispatch(setActiveReduxModal('athletes-not-connected'));
  //   }
  // };

  const Container = styled('div')`
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    align-items: center;
    // padding: 19px 30px 30px 21px;
    padding: 30px;
    gap: 15px;
    min-width: 245px;
    max-width: 253px;
    // height: 142px;
    background-color: ${theme.colors.background[0]};
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1);
    border-radius: 24px;
    margin-top: 30px;
    margin-bottom: 34px;
  `;

  const ShimmerLoadingBlock = styled('div')`
    min-width: 245px;
    max-width: 253px;
    height: 142px;
    border-radius: 24px;
    margin-top: 30px;
    margin-bottom: 34px;
  `;

  const Row = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  width: 203px;
  height: 47px;
  background-color: ${theme.colors.background[0]};
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1);
  border-radius: 12px;
  text-align: center;
`;

  const HyperlinkColumn = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

  const TextContainer = styled('div')`
    width: 125px;
    height: 34px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 127%;
    display: flex;
    align-items: center;
    text-align: center;
`;

  const Link = styled('a')`
  padding-top: 10px;
`;

  return (
    <>
      {isAthleteParticipationLoading ? (
        <Shimmer>
          <ShimmerLoadingBlock />
        </Shimmer>
      ) : (
        <Container>
          <Row onClick={() => handleConnectedAthletesClick()} style={{ cursor: AthletesConnected?.count > 0 ? 'pointer' : 'default' }}>
            <Text fontSize={24} fontWeight={700}>{AthletesConnected?.count || 0}</Text>
            <Text fontSize={13} fontWeight={700}>Athletes Connected</Text>
          </Row>
          <Row onClick={() => handleAthletesNotSharingClick()} style={{ cursor: AthletesNotSharing?.count > 0 ? 'pointer' : 'default' }}>
            <Text fontSize={24} fontWeight={700}>{AthletesNotSharing?.count || 0}</Text>
            <TextContainer>
              <Text fontSize={13} fontWeight={700}>Athletes Connected Not Sharing</Text>
            </TextContainer>
          </Row>
          <HyperlinkColumn>
            <Text fontSize={11} fontWeight={400}>To gather more data, ask your athletes to connect their personal wearables device via the TeamBuildr app.</Text>
            <Link href='https://support.teambuildr.com/article/ifp1a347r9-what-devices-compatible-with-team-buildr' target='_blank' rel='noreferrer'>
              <Text fontSize={11} fontWeight={400}>
                Which devices are compatible?
                {/* <span style={{ fontSize: '11px', fontWeight: 400, color: 'rgb(53, 113, 242)' }}> See here.</span> */}
              </Text>
              <Text fontSize={11} fontWeight={400} color='rgb(53, 113, 242)'>See here.</Text>
            </Link>
          </HyperlinkColumn>
          {/* <Row onClick={() => handleAthletesNotConnectedClick()} style={{ cursor: AthletesNotConnected?.count > 0 ? 'pointer' : 'default' }}>
            <Text fontSize={24} fontWeight={700}>{AthletesNotConnected?.count || 0}</Text>
            <TextContainer>
              <Text fontSize={13} fontWeight={700}>Athletes Not Connected</Text>
            </TextContainer>
          </Row> */}
        </Container>
      )}
    </>
  );
};

export default ConnectedAthletesCard;
